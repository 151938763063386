import { Button, Form, Input, Statistic } from 'antd';

import { useTranslate } from '@/utils/hooks/useTranslate';
import styles from '../index.less';

const { Countdown } = Statistic;
type Props = {
  onFinishStep3: (value: any) => void;
  currentPhone: any;
  loading: boolean;
};

const Step3 = (props: Props) => {
  const { t } = useTranslate();
  return (
    <div className={styles.loginWrap}>
      <h1>Tạo mật khẩu mới</h1>
      <Form onFinish={props.onFinishStep3} layout="vertical">
        <Form.Item
          label={t('password')}
          name="password"
          rules={[
            {
              required: true,
              message: t('error.require', {
                field: t('password'),
              }),
            },
            {
              min: 8,
              max: 99,
              message: t('error.password', {
                field: t('password'),
              }),
            },
          ]}
        >
          <Input.Password placeholder={t('password')} />
        </Form.Item>
        <Form.Item
          name="re_password"
          label={t('enter_password')}
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: t('error.require', {
                field: t('enter_password'),
              }),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t('password_no_match')));
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          // loading={loading}
          className={styles.btnSubmit}
        >
          Xác nhận
        </Button>
      </Form>
    </div>
  );
};

export default Step3;
