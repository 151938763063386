import { LeftOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Col, Form, message, Row, UploadFile } from 'antd';
import React, { useState } from 'react';
import { history, Link, useIntl, useParams } from 'umi';
import styles from './index.less';

import { useTranslate } from '@/utils/hooks/useTranslate';
import PersonalInfo from './Components/PersonalInfo';
import { useRequest, useToggle } from 'ahooks';
import Dialog from './Components/Dialog';
import { onSubmitValue } from './service';
import { getAllRole } from '../AdminEditAccount/service';
import { UploadProps } from 'antd/es/upload/interface';

export default () => {
  const { formatMessage } = useIntl();

  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const [dataRole, setDataRole] = useState<any[]>([]);

  useRequest(getAllRole, {
    onSuccess(res: any) {
      if (res?.data?.get_list_role?.data) {
        setDataRole(res?.data?.get_list_role?.data);
      }
    },
  });

  const [avatarLink, setAvatarLink] = React.useState<any>(null);

  const onChange: UploadProps['onChange'] = ({
    file,
    fileList: newFileList,
    event,
  }) => {
    if (file.response) {
      setAvatarLink(file.response.path);
    }
    setFileList(newFileList);
  };

  const requestCreateUser = useRequest(onSubmitValue, {
    manual: true,
    onSuccess(data: any) {
      console.log(data);
      if (data.errors) {
        message.error('Tài khoản đã tồn tại');
      } else {
        history.push('/employee');
        message.success('Thêm mới nhân viên thành công');
      }
    },
  });

  const { t } = useTranslate();
  const [openDialog, setOpenDialog] = useToggle(false);
  const [idSelected, setIdSelected] = React.useState<number | string | null>(
    null,
  );
  const handleDelete = () => {
    setOpenDialog.set(true);
  };

  const onFinish = (values: any) => {
    const { donateOfDay, email, fullName, password, phoneNumber, status } =
      values;

    const data = {
      query: `
      mutation {
        create_staff(
          input: {
            name: "${fullName}"
            phone: "${phoneNumber}"
            email: "${email}"
            password: "${password}"
            status: ${status === undefined ? true : status}
          }
        ) {
          data {
            email
            phone
            password
          }
          message
        }
      }
    `,
    };
    requestCreateUser.run(data);
  };
  const onFinishFailed = (errorInfo: any) => {};

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item className={styles.breacrumbItem}>
          <Link to="/employee" className={styles.previousEditLink}>
            <LeftOutlined />
            <div>{formatMessage({ id: 'employee_new_account' })}</div>
          </Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className={styles.tableComponent}>
        <Form
          name="basic"
          className={styles.itemForm}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Row>
            <Col span={12}>
              <div className={styles.detailAdm}>
                <PersonalInfo />
              </div>
            </Col>
          </Row>
          <Row
            align="middle"
            justify="center"
            className={styles.submitButtonGroup}
          >
            <Col>
              <Button
                danger
                onClick={handleDelete}
                className={styles.addButton}
              >
                {t('general_cancel')}
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                className={styles.addButton}
              >
                {t('general_save')}
              </Button>
            </Col>
          </Row>
        </Form>
      </div>

      {openDialog && (
        <Dialog
          open={openDialog}
          setOpen={(b) => {
            setOpenDialog.set(b);
          }}
          itemEdit={idSelected}
        />
      )}
    </>
  );
};
