import { useTranslate } from '@/utils/hooks/useTranslate';
import { Form, Input, Switch } from 'antd';
import { useIntl } from 'umi';
import styles from '../index.less';

const PersonalInfo = () => {
  const { t } = useTranslate();
  const { formatMessage } = useIntl();

  return (
    <div className={styles.formGeneric}>
      <Form.Item
        label={formatMessage({ id: 'fullname' })}
        name="fullName"
        rules={[
          {
            required: true,
            message: t('error.require', {
              field: t('fullname'),
            }),
          },
        ]}
      >
        <Input placeholder={formatMessage({ id: 'fullname' })} />
      </Form.Item>
      <Form.Item
        label={formatMessage({ id: 'const_column_phone_number' })}
        name="phoneNumber"
        rules={[
          {
            required: true,
            message: t('error.require', {
              field: t('const_column_phone_number'),
            }),
          },
          {
            pattern: /(84|0[3|5|7|8|9])+([0-9]{8})\b/,
            message: t('error.patern', {
              field: t('const_column_phone_number'),
            }),
          },
        ]}
      >
        <Input
          placeholder={formatMessage({
            id: 'const_column_phone_number',
          })}
        />
      </Form.Item>
      <Form.Item
        label={formatMessage({ id: 'email' })}
        name="email"
        rules={[
          {
            type: 'email',
            message: t('error.email', {
              field: t('email'),
            }),
          },
          {
            required: true,
            message: t('error.require', {
              field: t('email'),
            }),
          },
        ]}
      >
        <Input placeholder={formatMessage({ id: 'email' })} />
      </Form.Item>
      <Form.Item
        label={formatMessage({ id: 'password' })}
        name="password"
        rules={[
          {
            required: true,
            message: t('error.require', {
              field: t('password'),
            }),
          },
          {
            min: 8,
            max: 99,
            message: t('error.password', {
              field: t('password'),
            }),
          },
        ]}
      >
        <Input.Password placeholder={formatMessage({ id: 'password' })} />
      </Form.Item>
      <Form.Item
        name="re_password"
        label={formatMessage({ id: 'enter_password' })}
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: true,
            message: t('error.require', {
              field: t('enter_password'),
            }),
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error(formatMessage({ id: 'password_no_match' })),
              );
            },
          }),
        ]}
      >
        <Input.Password placeholder={formatMessage({ id: 'enter_password' })} />
      </Form.Item>

      <Form.Item
        label={formatMessage({ id: 'status' })}
        valuePropName="checked"
        name="status"
      >
        <Switch defaultChecked={true} />
      </Form.Item>
    </div>
  );
};

export default PersonalInfo;
