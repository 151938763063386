import { Button, Form } from 'antd';
import { useEffect, useState } from 'react';

import { useTranslate } from '@/utils/hooks/useTranslate';
import { useHistory } from 'umi';
import styles from '../index.less';
import OtpInput from 'react18-input-otp';
type Props = {
  onFinishStep2: (value: any) => void;
  resendOTP: () => void;
  currentPhone: any;
  loading: boolean;
  isVNDialcode: boolean;
};

const Step2 = (props: Props) => {
  const { t } = useTranslate();
  const [otp, setOtp] = useState('');
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(30);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const resendOTP = () => {
    setSeconds(30);
    setMinutes(1);
    props.resendOTP();
  };

  return (
    <div className={styles.loginWrap}>
      <div id="recaptcha-container"></div>
      <h1>Xác thực OTP</h1>
      <p style={{ marginBottom: 24 }}>
        Nhập mã xác thực được gửi tới số điện thoại {props.currentPhone}
      </p>
      <Form onFinish={props.onFinishStep2} layout="vertical">
        <Form.Item
          name="otp"
          className={styles.otpInput}
          rules={[
            {
              required: true,
              message: t('error.require', {
                field: t('OTP'),
              }),
            },
            {
              pattern: /[0-9]/,
              message: t('error', {
                field: 'OTP phải là số',
              }),
            },
          ]}
        >
          {/* @ts-ignore */}
          <OtpInput
            value={otp}
            onChange={(val: any) => setOtp(val)}
            numInputs={props.isVNDialcode ? 4 : 6}
            separator={<span>-</span>}
            className={styles.otp}
            isInputNum={true}
          />
        </Form.Item>
        <div className={styles.otpContainer}>
          {seconds > 0 || minutes > 0 ? (
            <p>
              Gửi lại OTP: {minutes < 10 ? `0${minutes}` : minutes}:
              {seconds < 10 ? `0${seconds}` : seconds}
            </p>
          ) : (
            <p>Bạn không nhận được OTP?</p>
          )}
          {!(seconds > 0 || minutes > 0) && (
            <div className={styles.resendOTP} onClick={resendOTP}>
              Gửi lại OTP
            </div>
          )}
        </div>
        <Button
          type="primary"
          htmlType="submit"
          loading={props.loading}
          className={styles.btnSubmit}
          disabled={props.isVNDialcode ? otp.length !== 4 : otp.length !== 6}
        >
          Tiếp tục
        </Button>
      </Form>
    </div>
  );
};

export default Step2;
