import { message, Typography } from 'antd';
import React from 'react';

import { auth } from '@/utils/firebaseConfig';
import { useTranslate } from '@/utils/hooks/useTranslate';
import { useRequest } from 'ahooks';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { useHistory } from 'umi';
import { checkLogin, resetPassword } from './service';
import Step1 from './Step/Step1';
import Step2 from './Step/Step2';
import Step3 from './Step/Step3';
import { getPhoneOtpVoice, verifyPhoneOTPVoice } from '../Register/service';

const { Text, Link } = Typography;

const Register: React.FC = () => {
  const { t } = useTranslate();

  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [dialCode, setDialCode] = React.useState('+84');
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();

  const [recaptchaToken, setRecaptchaToken] = React.useState('');
  const [step, setStep] = React.useState<1 | 2 | 3 | 4 | 5>(1);
  const getOTPVoice = useRequest(getPhoneOtpVoice, {
    manual: true,
    onSuccess: () => {
      setLoading(false);
      setStep(2);
    },
    onError: () => {
      setLoading(false);
      message.error('Bạn đã quá số lần gửi OTP');
    },
  });
  const verifyOTPVoice = useRequest(verifyPhoneOTPVoice, {
    manual: true,
    onSuccess: (res) => {
      console.log(res);
      if (res.data?.verify_phone_by_otp?.status) {
        message.success('Thành công');
        setStep(3);
      } else {
        message.error('Bạn đã nhập sai OTP');
      }
      setLoading(false);
    },
  });
  const sentOTPPhone = () => {
    if (dialCode === '+84') {
      getOTPVoice.run({
        calling_code: dialCode,
        phone_number: phoneNumber,
      });
    } else {
      generateCaptcha();
    }
  };

  const requestResetPass = useRequest(resetPassword, {
    manual: true,
    onSuccess(data, params) {
      console.log('🚀 ~ file: index.tsx:34 ~ onSuccess ~ data', data);
      if (data.data && !data.data.error) {
        message.success('Đổi mật khẩu thành công');
        history.push('/login');
      } else {
        message.error('Có lỗi xảy ra, vui lòng thử lại');
      }
    },
  });

  const sendOTP = () => {
    //@ts-ignore
    const appVerifier = window.recaptchaVerifier;

    signInWithPhoneNumber(auth, phoneNumber, appVerifier)
      .then((confirmationResult) => {
        //@ts-ignore
        window.confirmationResult = confirmationResult;
        //@ts-ignore
        setRecaptchaToken(confirmationResult.recaptchaToken);
      })
      .catch((error) => {})
      .finally(() => {
        setStep(2);
        setLoading(false);
      });
  };

  const generateCaptcha = () => {
    //@ts-ignore
    window.recaptchaVerifier = new RecaptchaVerifier(
      'recaptcha-container',
      {
        size: 'invisible',
        callback: (response: any) => {},
      },
      auth,
    );
    //@ts-ignore
    sendOTP();
  };

  const requestCheckPhone = useRequest(checkLogin, {
    manual: true,
    onSuccess(data, params) {
      if (data?.data?.check_ident_to_signup?.status) {
        message.error('Số điện thoại chưa được đăng ký');
        setLoading(false);
      } else {
        sentOTPPhone();
      }
    },
  });

  const onFinishStep1 = async (value: any) => {
    setLoading(true);
    setPhoneNumber(value.phone_number);
    await requestCheckPhone.runAsync(value.phone_number);
  };

  const onFinishStep2 = async (value: any) => {
    setLoading(true);

    if (dialCode === '+84') {
      verifyOTPVoice.run({
        phone_number: phoneNumber,
        otpCode: value.otp,
      });
    } else {
      //@ts-ignore
      if (!window.confirmationResult) {
        message.error('Có lỗi xảy ra, vui lòng thử lại !');
        setStep(1);
        return;
      }
      //@ts-ignore
      window.confirmationResult
        .confirm(value.otp)
        .then((result: any) => {
          message.success('Thành công');
          setStep(3);
        })
        .catch((error: any) => {
          message.error('Bạn đã nhập sai OTP');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const onFinishStep3 = async (value: any) => {
    setLoading(true);
    await requestResetPass.runAsync({
      phone_number: phoneNumber,
      password: value.password,
    });
    setLoading(false);
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <Step1
            dialCode={dialCode}
            setDialCode={setDialCode}
            onFinishStep1={onFinishStep1}
            loading={loading}
          />
        );
        break;
      case 2:
        return (
          <Step2
            isVNDialcode={dialCode === '+84'}
            onFinishStep2={onFinishStep2}
            resendOTP={sentOTPPhone}
            currentPhone={phoneNumber}
            loading={loading}
          />
        );
        break;
      case 3:
        return (
          <Step3
            onFinishStep3={onFinishStep3}
            currentPhone={phoneNumber}
            loading={loading}
          />
        );
        break;

      default:
        return (
          <Step1
            dialCode={dialCode}
            setDialCode={setDialCode}
            onFinishStep1={onFinishStep1}
            loading={loading}
          />
        );
        break;
    }
  };

  return (
    <>
      <div id="recaptcha-container"></div>
      {renderStep()}
    </>
  );
};

export default Register;
