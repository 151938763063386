import { Button, Form, Input, Typography } from 'antd';
import React from 'react';
import { useLogin } from './service';

import styles from './index.less';
import { useTranslate } from '@/utils/hooks/useTranslate';
import { useAuth } from '@/utils/hooks/useAuth';

const { Text, Link } = Typography;
const Login: React.FC = () => {
  const { t } = useTranslate();
  const { loading, run } = useLogin();

  const onFinish = (values: any) => {
    run(values);
  };
  const { onRegister } = useAuth();

  return (
    <div className={styles.loginWrap}>
      <h1>Locamos Business</h1>
      <Form onFinish={onFinish} layout="vertical">
        <Form.Item
          className={styles.formItem}
          label={t('user_or_phone_number')}
          name="phone_number"
          rules={[
            {
              required: true,
              message: t('error.require', {
                field: t('user_or_phone_number'),
              }),
            },
          ]}
        >
          <Input type="text" placeholder={t('user_or_phone_number')} />
        </Form.Item>

        <Form.Item
          label={t('password')}
          name="password"
          rules={[
            {
              required: true,
              message: t('error.require', {
                field: t('password'),
              }),
            },
          ]}
        >
          <Input.Password placeholder={t('password')} />
        </Form.Item>
        <a href="/forgot-password" className={styles.links}>
          <Text underline>Quên mật khẩu</Text>
        </a>
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          className={styles.btnSubmit}
        >
          {t('login')}
        </Button>
        <div className={styles.register}>
          <h2>Chưa có tài khoản?</h2>
          <div
            className={styles.links}
            onClick={() => {
              onRegister();
            }}
          >
            <span>Đăng ký</span>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default Login;
