import { useTranslate } from '@/utils/hooks/useTranslate';

import { useIntl, useRequest } from 'umi';
import { ENVIRONMENTS } from '@/utils/constant';
import Upload, { RcFile, UploadFile, UploadProps } from 'antd/lib/upload';
import styles from '../index.less';
import { useState } from 'react';
import { Button, Col, Form, Input, Row, Select, Skeleton } from 'antd';
import { API_PATH } from '@/utils/apis';
import { LeftOutlined } from '@ant-design/icons';
import MapReaflat from '@/components/MapReaflat';
import { getListOrgs } from '@/pages/UpdateBusiness/service';

type Props = {
  onFinishStep4_5: (value: any) => void;
  backSreen: () => void;
  loading: boolean;
};
const Step4_5 = (props: Props) => {
  const { formatMessage } = useIntl();
  const { t } = useTranslate();
  const [pickerPosition, setPickerPosition] = useState<{
    lat?: number;
    lng?: number;
    customName?: string;
  } | null>(null);

  const [fileList, setFileList] = useState<{
    bill_template: UploadFile[];
    enterprise_image: UploadFile[];
    qr_transfer: UploadFile[];
  }>({
    bill_template: [],
    enterprise_image: [],
    qr_transfer: [],
  });
  const requestOrgs = useRequest(getListOrgs);
  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const [form] = Form.useForm();
  const onSelectPos = (pos: any) => {
    const provinceSelected = pos?.name?.split(',').reverse()[1]?.trim();
    const province = requestOrgs.data?.get_all_orgs?.find(
      (item: any) => item.name === provinceSelected,
    );
    form.setFieldValue('province_id', province?.id);

    setPickerPosition({
      lat: pos?.lat,
      lng: pos?.long,
      customName: pos?.name,
    });

    form.setFieldsValue({
      address: pos?.name,
      lat: pos?.lat,
      long: pos?.long,
    });
  };
  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  return (
    <div className={styles.loginWrap}>
      <h1>Thông tin cửa hàng</h1>
      <Form
        form={form}
        onKeyDown={(e) => {
          if (e.key === 'Enter') e.preventDefault();
        }}
        onFinish={(value) => {
          props.onFinishStep4_5({
            ...value,
            lat: pickerPosition?.lat,
            long: pickerPosition?.lng,
            address: pickerPosition?.customName,
            enterprise_image: fileList.enterprise_image[0]?.response?.path,
            bill_template: fileList.bill_template[0]?.response?.path,
            qr_transfer: fileList.qr_transfer[0]?.response?.path,
          });
        }}
      >
        <Form.Item label="Khu vực" name="province_id">
          <Select
            placeholder="Chọn khu vực"
            options={requestOrgs.data?.get_all_orgs?.map((item: any) => ({
              label: item.name,
              value: item.id,
            }))}
          />
        </Form.Item>
        <Form.Item
          label="Địa chỉ"
          name="address"
          rules={[
            {
              required: true,
              message: 'Vui lòng chọn địa điểm trên bản đồ',
            },
          ]}
        >
          <Input disabled />
        </Form.Item>

        <Form.Item
          style={{
            textAlign: 'center',
          }}
        >
          <MapReaflat
            setPos={onSelectPos}
            listPosition={[]}
            loading={false}
            disable={false}
          />

          <Row
            gutter={16}
            style={{
              marginTop: '10px',
            }}
          >
            <Col span={12} className={styles.dialogFormItem}>
              <Form.Item
                label="Lat"
                name="lat"
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng chọn',
                  },
                ]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={12} className={styles.dialogFormItem}>
              <Form.Item
                label="Lng"
                name="long"
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng chọn',
                  },
                ]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>

        <p>{t('enterprise_image')}</p>
        <Form.Item
          name="enterprise_image"
          getValueFromEvent={normFile}
          rules={[
            {
              required: true,
              message: 'Vui lòng chọn ảnh',
            },
          ]}
        >
          <Upload
            accept=".png,.jpg,.jpeg"
            action={
              ENVIRONMENTS.API_URL +
              `${
                ENVIRONMENTS.DEV_ENV ? API_PATH.UPLOAD_DEV : API_PATH.UPLOADV2
              }`
            }
            onPreview={onPreview}
            fileList={fileList.enterprise_image}
            listType="picture-card"
            onChange={(val: any) => {
              setFileList((state: any) => ({
                ...state,
                enterprise_image: val.fileList,
              }));
            }}
            onRemove={() => {
              setFileList((state: any) => ({
                ...state,
                enterprise_image: [],
              }));
            }}
          >
            {fileList.enterprise_image.length < 1 && 'Tải lên'}
          </Upload>
        </Form.Item>
        <Row justify="space-between">
          <Col span={12}>
            <p>{t('bill_template')}</p>
            <Form.Item
              name="bill_template"
              getValueFromEvent={normFile}
              rules={[
                {
                  required: true,
                  message: 'Vui lòng chọn ảnh',
                },
              ]}
            >
              <Upload
                accept=".png,.jpg,.jpeg"
                action={
                  ENVIRONMENTS.API_URL +
                  `${
                    ENVIRONMENTS.DEV_ENV
                      ? API_PATH.UPLOAD_DEV
                      : API_PATH.UPLOADV2
                  }`
                }
                onPreview={onPreview}
                fileList={fileList.bill_template}
                listType="picture-card"
                onChange={(val: any) => {
                  setFileList((state: any) => ({
                    ...state,
                    bill_template: val.fileList,
                  }));
                }}
                onRemove={() => {
                  setFileList((state: any) => ({
                    ...state,
                    bill_template: [],
                  }));
                }}
              >
                {fileList.bill_template.length < 1 && 'Tải lên'}
              </Upload>
            </Form.Item>
          </Col>
          <Col span={12}>
            <p>{t('qr_transfer')}</p>
            <Form.Item
              name="qr_transfer"
              getValueFromEvent={normFile}
              rules={[
                {
                  required: true,
                  message: 'Vui lòng chọn ảnh',
                },
              ]}
            >
              <Upload
                accept=".png,.jpg,.jpeg"
                action={
                  ENVIRONMENTS.API_URL +
                  `${
                    ENVIRONMENTS.DEV_ENV
                      ? API_PATH.UPLOAD_DEV
                      : API_PATH.UPLOADV2
                  }`
                }
                onPreview={onPreview}
                fileList={fileList.qr_transfer}
                listType="picture-card"
                onChange={(val: any) => {
                  setFileList((state: any) => ({
                    ...state,
                    qr_transfer: val.fileList,
                  }));
                }}
                onRemove={() => {
                  setFileList((state: any) => ({
                    ...state,
                    qr_transfer: [],
                  }));
                }}
              >
                {fileList.qr_transfer.length < 1 && 'Tải lên'}
              </Upload>
            </Form.Item>
          </Col>
        </Row>
        <Row justify="center">
          <Button
            type="primary"
            icon={<LeftOutlined />}
            ghost
            onClick={() => props.backSreen()}
            style={{
              margin: '0 15px',
            }}
          >
            Quay lại
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={props.loading}
            // className={styles.btnSubmit}
            style={{
              margin: '0 15px',
            }}
          >
            Xác nhận
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default Step4_5;
