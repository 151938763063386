import { API_PATH, privateRequest, request } from '@/utils/apis';
import moment from 'moment';
export enum ORDER_STATUS {
  PENDING = 'PENDING',
  VERIFIED = 'VERIFIED',
  CANCELED = 'CANCELED',
  DENIED = 'DENIED',
}
export const getListOrder = (
  {
    current,
    pageSize,
    status,
  }: { current: number; pageSize: number; status?: string },
  formData: {
    search?: string;
    toDate?: string[];
  },
) => {
  const params: any = {
    page: current,
    limit: pageSize,
  };
  if (!!formData.search) {
    params.order_id = formData.search;
  }
  if (!!status) {
    params.status = status;
  }
  if (Array.isArray(formData.toDate)) {
    params.date_from = moment(formData.toDate[0]).format('YYYY-MM-DD');
    params.date_to = moment(formData.toDate[1]).format('YYYY-MM-DD');
  }
  return privateRequest(request.get, API_PATH.ORDER, {
    params,
  }).then((response) => {
    return {
      list: response?.data?.list?.map((item: any, index: number) => ({
        ...item,
        stt: (current - 1) * pageSize + index + 1,
      })),
      total: response?.meta?.total_order,
    };
  });
};

export const getDetailProductService = ({
  current,
  pageSize,
  id,
}: {
  current: number;
  pageSize: number;
  id: string;
}) => {
  const params: any = {
    page: current,
    limit: pageSize,
  };
  return privateRequest(request.get, API_PATH.DETAIL_ORDER(id), {
    params,
  }).then((response) => {
    return {
      list: response?.data?.order_details?.map((item: any, index: number) => ({
        ...item,
        stt: (current - 1) * pageSize + (index + 1),
      })),
      total: response?.total,
      data: response?.data,
    };
  });
};
