import { useAntdTable } from 'ahooks';
import { Form, Input, Table, DatePicker } from 'antd';
import React from 'react';
import { StatisticAliasService } from '../service';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import styles from '../index.less';
import { formatNumber } from '@/utils/apis/common';
const StatisticAlias = () => {
  const [form] = Form.useForm();
  const { tableProps, search } = useAntdTable(StatisticAliasService, {
    form,
  });
  const columns: ColumnsType<any> = [
    {
      title: 'STT',
      dataIndex: 'stt',
    },
    {
      title: 'Thời gian sử dụng',
      dataIndex: 'used_time',
      render: (value) => <>{moment(value).format('HH:mm:ss DD/MM/YYYY')}</>,
    },
    {
      title: 'User ID',
      dataIndex: 'user_id',
    },
    {
      title: 'Tên người dùng',
      dataIndex: 'user_name',
    },
    {
      title: 'Mã Alias',
      dataIndex: 'alias_code',
    },
    {
      title: 'Giá trị voucher',
      dataIndex: 'value',
      render: (value) => <>{formatNumber(value)}đ</>,
    },
  ];
  const { submit } = search;
  const searchForm = (
    <div className={styles.searchContainer}>
      <Form form={form} className={styles.searchForm}>
        <Form.Item name="search" className={styles.searchItem}>
          <Input.Search
            placeholder="Tìm kiếm userId, mã alias"
            allowClear
            onSearch={submit}
          />
        </Form.Item>
        <Form.Item name="date" className={styles.searchItem}>
          {/* @ts-ignore */}
          <DatePicker.RangePicker
            format="DD/MM/YYYY"
            onChange={submit}
            className="w-100"
            picker="date"
            placeholder={['Từ ngày', 'Đến ngày']}
          />
        </Form.Item>
      </Form>
    </div>
  );
  return (
    <div>
      {searchForm}
      <div className={styles.tableComponent}>
        <Table columns={columns} {...tableProps} rowKey={(item) => item.stt} />
      </div>
    </div>
  );
};

export default StatisticAlias;
