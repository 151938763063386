import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { getAuth } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: 'AIzaSyD8-o10wI1uKMustZ-mLSNxnrSYezj_Kcw',
  authDomain: 'smooth-pen-362407.firebaseapp.com',
  projectId: 'smooth-pen-362407',
  storageBucket: 'smooth-pen-362407.appspot.com',
  messagingSenderId: '615674978299',
  appId: '1:615674978299:web:4e6e2a252b6b9f3996f55e',
  measurementId: 'G-7E8EML90ZX',
};
firebase.initializeApp(firebaseConfig);
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
export { firebase, auth };
