import { formatTime } from './../../utils/formatTime';
import { privateRequest, request, API_PATH } from '@/utils/apis';
import { ENVIRONMENTS } from '@/utils/constant';

interface Result {
  total: number;
  list: any[];
}

export const getTableData = (
  { current, pageSize }: { current: number; pageSize: number },
  formData: {
    fullName: string;
  },
): Promise<Result> => {
  if (formData.fullName === undefined) {
    formData.fullName = '';
  }

  // `page=${current}&pageSize=${pageSize}`

  const data = {
    query: `
    mutation {
      get_customer_history(
        input: { 
        search_text: "${formData.fullName}"
        page_index: ${current ? current - 1 : 0}
        page_size:  ${pageSize || 10} }
      ) {
        total
        historys {
          name
          phone
          checkin_count
          get_point_count
          accrual_spending
          temporary_spending
          total_get_point
        }
      }
    }
    `,
  };

  return privateRequest(request.post, API_PATH.default, { data }).then(
    (res: any) => {
      const result = res?.data?.get_customer_history?.historys.map(
        (e: any, index: any) => ({
          ...e,
          stt: index + 1,
        }),
      );
      return {
        total: res?.data?.get_customer_history?.total,
        list: result,
      };
    },
  );
};

export const deleteAdmin = (id: any) => {
  const query = `
  mutation {
    delete_admin(input: { id: "${id}" })
  }

  `;
  return privateRequest(request.post, API_PATH.default, {
    data: {
      query,
    },
  });
};

export const switchStatusAdmin = (id: any) => {
  const query = `
    mutation {
      switch_status_admin(id: "${id}")
    }
  `;
  return privateRequest(request.post, API_PATH.default, {
    data: {
      query,
    },
  });
};
