import { API_PATH, privateRequest, request } from '@/utils/apis';
import { IUpdateStoreInfo } from '../Register/service';
export enum TYPE_ENTERPRISE {
  INDIVIDUAL = 'INDIVIDUAL',
  BUSINESS_HOUSEHOLD = 'BUSINESS_HOUSEHOLD',
  COMPANY = 'COMPANY',
}
export interface IUpdateStore extends IUpdateStoreInfo {
  logo: string;
  description: string;
}
export interface IUpdateBrand {
  type_of_enterprise?: TYPE_ENTERPRISE;
  brand_name: string;
  email: string;
  phone: string;
  owner_name: string;
  address: string;
  identity_front: string;
  identity_backside: string;
  license_image: string;
  tax_code: string;
}

export interface IChangePassword {
  old_password: string;
  new_password: string;
}

export const getDetailStore = () => {
  return privateRequest(request.get, API_PATH.DETAIL_STORE);
};

export const updateStoreService = (input: IUpdateStore) => {
  return privateRequest(request.post, API_PATH.UPDATE_STORE, { data: input });
};

export const getDetailBrand = () => {
  return privateRequest(request.get, API_PATH.DETAIL_BRAND);
};

export const updateDetailBrand = (input: IUpdateBrand) => {
  return privateRequest(request.post, API_PATH.DETAIL_BRAND, { data: input });
};

export const changePasswordService = (input: IChangePassword) => {
  return privateRequest(request.post, API_PATH.CHANGE_PASSWORD, {
    data: input,
  });
};

export const getOTPEmailService = (sending: string) => {
  return privateRequest(request.post, API_PATH.SEND_OTP, {
    data: {
      sending,
    },
  });
};

export const otpVerifyService = (data: {
  sending: string;
  otp: string;
  sessionInfo?: string;
}) => {
  return privateRequest(request.post, API_PATH.VERIFY_OTP, {
    data,
  });
};

export const createPinCodeService = (data: {
  otp_code: string;
  pin_code: string;
  sending?: string;
}) => {
  return privateRequest(request.post, API_PATH.CREATE_PIN, { data });
};
export const checkPinCodeService = () => {
  return privateRequest(request.get, API_PATH.CHECK_PIN);
};
export const changePinCodeService = (data: {
  current_pin_code: string;
  pin_code: string;
  pin_code_confirmation: string;
}) => {
  return privateRequest(request.put, API_PATH.CHANGE_PIN, {
    data: {
      ...data,
      otp_code: '123@abcd',
    },
  });
};
