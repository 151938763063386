import { Button, Image, Table, TableColumnsType } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import ModalRefuse from './ModalRefuse';
import ModalConfirm from './ModalConfirm';
import styles from './index.less';
import { useAntdTable } from 'ahooks';
import { getDetailProductService } from '../service';
import { BlankImage, ENVIRONMENTS } from '@/utils/constant';
import { formatNumber } from '@/utils/apis/common';

interface ExpandedDataType {
  key: React.Key;
  date: string;
  name: string;
  upgradeNum: string;
}

function Expanded({ id }: { id: string }) {
  const [showDetail, setShowDetail] = useState<boolean>(true);
  const [modalConfirm, setModalConfirm] = useState<boolean>(false);
  const [modalRefuse, setModalRefuse] = useState<boolean>(false);
  const { tableProps, data } = useAntdTable(getDetailProductService, {
    defaultParams: [
      {
        id: id,
        current: 1,
        pageSize: 4,
      },
    ],
  });
  const detailIncome = data?.data;

  const columns: TableColumnsType<any> = [
    { title: 'STT', dataIndex: 'stt', key: 'stt' },
    {
      title: 'Hình ảnh',
      dataIndex: ['product', 'images'],
      key: 'image',
      render: (value: string, record: any) => (
        <Image
          src={
            ENVIRONMENTS.BUCKET_NAME + '/' + record?.product?.images?.[0]?.link
          }
          alt="product"
          fallback={BlankImage}
          width={80}
          height={80}
        />
      ),
    },
    { title: 'Tên sản phẩm', dataIndex: 'product_name', key: 'product_name' },
    {
      title: 'Số lượng',
      dataIndex: 'product_quantity',
      key: 'product_quantity',
    },
    {
      title: 'Đơn giá(VNĐ)',
      dataIndex: 'price_per_product',
      key: 'price_per_product',
      render: (value: any) => <>{formatNumber(value)}</>,
    },
    {
      title: 'Giảm giá',
      render: (value: any, record: any) => (
        <>
          <p>
            Giảm giá:{' '}
            {formatNumber(
              record?.orders_discount_event[0]?.discount_event?.voucher_type
                ?.value,
            )}{' '}
            VND
          </p>
          <p>Số voucher: {record?.orders_discount_event?.quantity}</p>
        </>
      ),
    },
    {
      title: 'Thành tiền (VND)',
      dataIndex: 'total_price',
      key: 'total_price',
      render: (value: any) => <>{formatNumber(value)}</>,
    },
  ];

  const handleRefuse = () => {
    setModalRefuse(true);
  };

  const handleSubmit = () => {
    setModalConfirm(true);
  };

  return (
    <>
      <Table columns={columns} {...tableProps} scroll={{ x: 1000 }} />
      <div className={styles.showDetail}>
        <div
          className={styles.showDetail_btn}
          onClick={() => setShowDetail(!showDetail)}
        >
          {showDetail ? 'Đóng chi tiết doanh thu' : 'Chi tiết doanh thu'}{' '}
          {showDetail ? <DownOutlined /> : <UpOutlined />}
        </div>

        {showDetail && (
          <>
            <div className={styles.showDetail_item}>
              <div className={styles.showDetail_desc}>Tổng cộng:</div>
              <div className={styles.showDetail_val}>
                {formatNumber(detailIncome?.total_origin)} VND
              </div>
            </div>
            <div className={styles.showDetail_item}>
              <div className={styles.showDetail_desc}>Giảm giá sản phẩm:</div>
              <div className={styles.showDetail_val}>
                {formatNumber(detailIncome?.total_discount_product)} VND
              </div>
            </div>
            <div className={styles.showDetail_item}>
              <div
                className={styles.showDetail_desc}
                style={{ color: '#3B82F6' }}
              >
                Tổng số voucher áp dụng cho sản phẩm:
              </div>
              <div
                className={styles.showDetail_val}
                style={{ color: '#3B82F6' }}
              >
                {detailIncome?.total_product_voucher} D-Voucher
              </div>
            </div>
            <div className={styles.showDetail_item}>
              <div className={styles.showDetail_desc}>Giảm giá đơn hàng:</div>
              <div className={styles.showDetail_val}>
                {formatNumber(detailIncome?.total_discount_bill)} VND
              </div>
            </div>
            <div className={styles.showDetail_item}>
              <div
                className={styles.showDetail_desc}
                style={{ color: '#3B82F6' }}
              >
                Tổng số voucher áp dụng cho đơn hàng:
              </div>
              <div
                className={styles.showDetail_val}
                style={{ color: '#3B82F6' }}
              >
                {detailIncome?.total_bill_voucher} D-Voucher
              </div>
            </div>
            <div className={styles.showDetail_item}>
              <div className={styles.showDetail_desc}>
                Tổng giá trị đơn hàng:
              </div>
              <div
                className={styles.showDetail_val}
                style={{ color: '#3B82F6' }}
              >
                {formatNumber(detailIncome?.last_price)} VND
              </div>
            </div>
            <div className={styles.showDetail_action}>
              <Button onClick={handleRefuse} type="primary" danger>
                Từ chối
              </Button>
              <Button onClick={handleSubmit} type="primary">
                Xác nhận
              </Button>
            </div>
          </>
        )}
      </div>

      <ModalRefuse isOpen={modalRefuse} setIsOpen={setModalRefuse} />
      <ModalConfirm isOpen={modalConfirm} setIsOpen={setModalConfirm} id={id} />
    </>
  );
}

export default Expanded;
