import React, { memo, useCallback, useEffect, useState } from 'react';
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Table,
  Upload,
  UploadFile,
} from 'antd';
import styles from './index.less';
import dayjs from 'dayjs';
import DatePicker from '@/components/CustomPicker/DatePicker';
import {
  LoadingOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { useRequest } from 'ahooks';
import axios from 'axios';
import { ENVIRONMENTS, GIFT_POLICY } from '@/utils/constant';
import { API_PATH } from '@/utils/apis';
import UserGift from '../FormUser';
import Puzzle from '../FormPuzzle';
import {
  LocationDropGift,
  Status,
  TypeGift,
  getAllEventGift,
} from '../../service';
import FormCollection from '../FormPuzzle/FormCollection';
import UploadImage from '@/components/UploadImage';
import FormNFT from '../FormPuzzle/FormNFT';
import { useHistory } from 'umi';
import { RcFile, UploadProps } from 'antd/lib/upload';
import moment from 'moment';
import { FormInstance } from 'antd/es/form/Form';

interface IProps {
  getData: any;
  setData: any;
  onChange?: any;
  form: FormInstance<any>;
  UserDetail?: any;
  fileList: any;
  setFileList: React.Dispatch<React.SetStateAction<any>>;
  fileListCrop: any;
  setfileListCrop: React.Dispatch<React.SetStateAction<any>>;
}

export default (props: IProps) => {
  const {
    form,
    UserDetail = [],
    getData = [],
    setData,
    onChange,
    fileList,
    fileListCrop,
    setFileList,
    setfileListCrop,
  } = props;
  // const [fileList, setFileList] = useState<any[]>([]);
  // const [fileListCrop, setfileListCrop] = useState<any[]>([]);
  const [numOfPuzzle, setNumOfPuzzle] = useState<number>(1);

  const history = useHistory();
  const onEdit = form.getFieldValue('isEdit');

  const propsInput = {
    disabled:
      !history.location.pathname.includes('/create-gift-building') &&
      (getData?.gift_status === Status.DUYET ||
        getData?.gift_status === Status.CHO_DUYET ||
        getData?.type == TypeGift.GIFT_LOCABONUS)
        ? true
        : !onEdit,
  };
  const [events, setEvents] = useState([]);
  const isEditPuzzle = form.getFieldValue('isEditPuzzle');
  const requestSearchEvent = useRequest(getAllEventGift, {
    onSuccess: (res: any) => {
      if (!!getData?.event_id) {
        setData(
          'event',
          res?.data?.find((item: any) => item.ref_id === getData?.event_id),
        );
      }
      setEvents(res.data);
    },
    onError: (err) => {
      console.log(err);
    },
    debounceWait: 1000,
  });

  const handleChangeFile: UploadProps['onChange'] = (val) => {
    setfileListCrop(val.fileList);
    if (val?.file?.status === 'done') {
      const defaultPuzzle = (image: string, index: number) => ({
        name: `Mảnh ${index + 1}`,
        id_nft: 0,
        address: '',
        address_type: LocationDropGift.OTHER,
        image,
      });
      if (window.location.pathname.includes('create-gift-building')) {
        const puz = val?.file?.response?.cropping_url?.map((item: any) => ({
          image: item,
        }));
        form.setFieldsValue({
          puzzle_gifts: val?.file?.response?.cropping_url?.map(
            (item: any, index: number) => defaultPuzzle(item, index),
          ),
        });
        setData(`puzzle_gifts`, puz);
      } else {
        setData(
          `puzzle_gifts`,
          getData?.puzzle_gifts?.map((item: any, index: number) => ({
            ...item,
            image: val?.file?.response?.cropping_url?.[index],
          })),
        );
        form.setFieldsValue({
          puzzle_gifts: val?.file?.response?.cropping_url?.map(
            (item: any, index: number) => ({
              ...getData?.puzzle_gifts[index],
              image: item,
            }),
          ),
        });
      }
    }
  };

  return (
    <>
      <div className={styles.titleSub}>Thông tin quà tặng</div>
      <Form.Item name="set_all_user" hidden={true}></Form.Item>
      <Row gutter={[16, 16]} className={styles.backgroundColor}>
        <Col span="24" style={{ borderColor: 'red' }}>
          <Row gutter={[16, 16]}>
            <Col span={12} lg={12} sm={12} xs={24}>
              <Col span={24}>
                <Form.Item
                  name="name"
                  label="Tên quà tặng"
                  rules={[
                    { required: true, message: 'Vui lòng nhập tên quà tặng' },
                  ]}
                >
                  {/* <Input onChange={(e) => setData('name', e.target.value)}/> */}
                  <Input placeholder="Nhập tên quà tặng" />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  name="limit"
                  label="Giới hạn phần quà"
                  normalize={(value) => {
                    const isNumber = /^\d+$/.test(value);
                    if (isNumber) {
                      return value;
                    } else {
                      return null;
                    }
                  }}
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (Number(value) === 0) {
                          return Promise.reject(
                            new Error('Giới hạn phần quà không được bằng 0'),
                          );
                        }
                        if (!value) {
                          return Promise.reject(
                            new Error('Vui lòng nhập giới hạn phần quà'),
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input {...propsInput} placeholder="Giới hạn phần quà" />
                </Form.Item>
              </Col>

              {(getData.type === TypeGift.GIFT_LOCABONUS ||
                getData.type === TypeGift.GIFT_LOCABONUS_QR_BIZ) && (
                <Col span={24}>
                  <Form.Item
                    name="locabonus"
                    label="Trị giá quà (Locabonus)"
                    normalize={(value) => {
                      const isNumber = /^\d+$/.test(value);
                      if (isNumber) {
                        return value;
                      } else {
                        return null;
                      }
                    }}
                    rules={[
                      // { required: true, message: 'Vui lòng nhập trị giá quà' },
                      {
                        validator(_, value) {
                          if (!!value) {
                            if (Number(value) === 0) {
                              return Promise.reject('Giá trị phải lớn hơn 0');
                            }
                            return Promise.resolve();
                          }
                          return Promise.reject('Vui lòng nhập trị giá quà');
                        },
                      },
                    ]}
                  >
                    <Input {...propsInput} placeholder="Nhập số Locabonus" />
                  </Form.Item>
                </Col>
              )}
              {(getData.type === TypeGift.GIFT_NFT ||
                getData.type === TypeGift.GIFT_COLLECTION) && (
                <>
                  <Col span={24}>
                    <Form.Item
                      name="url_detail_gift"
                      label="Url chi tiết quà tặng"
                      rules={[
                        { required: true, message: 'Vui lòng nhập Url' },
                        {
                          message: 'Không đúng định dạng URL',
                          validator: (_, value) => {
                            var res = value.match(
                              /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
                            );
                            if (res == null && value) {
                              return Promise.reject('Some message here');
                            } else {
                              return Promise.resolve();
                            }
                          },
                        },
                      ]}
                      initialValue={`https://gift-info.locamos.com/?gift_id={id}`}
                    >
                      {/* <Input onChange={(e) => setData('name', e.target.value)}/> */}
                      <Input placeholder="Nhập url nhận quà tặng" />
                    </Form.Item>
                  </Col>
                </>
              )}

              <Col span={24}>
                <Form.Item
                  name="limit_time"
                  label="Khoảng thời gian giữa mỗi lần nhặt mảnh ghép (s)"
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng nhập khoảng thời gian',
                    },
                  ]}
                >
                  <Input placeholder="Nhập khoảng thời gian" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="Sự kiện" name="event_id">
                  <Select
                    allowClear
                    placeholder="Chọn sự kiện"
                    options={events.map((item: any) => ({
                      label: item.name,
                      value: item.ref_id,
                      ...item,
                    }))}
                    showSearch
                    filterOption={false}
                    onSearch={(val) => requestSearchEvent.run(val)}
                    notFoundContent={
                      requestSearchEvent.loading ? (
                        <Spin size="small" />
                      ) : (
                        'Không có dữ liệu'
                      )
                    }
                    onSelect={(val, option) => {
                      // form.resetFields(['time_end']);
                      setData('event', option);
                    }}
                  />
                </Form.Item>
              </Col>
            </Col>
            <Col span={12} lg={12} sm={12} xs={24}>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item
                    name="type"
                    label="Loại quà"
                    rules={[
                      { required: true, message: 'Vui lòng nhập loại quà' },
                    ]}
                  >
                    <Select
                      {...propsInput}
                      onChange={(data) => {
                        setData('type', data);
                        const gift = {
                          id_nft: 0,
                          address: '',
                          name: '',
                          image: '',
                          address_type: LocationDropGift.OTHER,
                        };
                        form.setFieldsValue({
                          puzzle_gifts: [gift],
                        });

                        setData('puzzle_gifts', [gift]);
                      }}
                      options={[
                        { value: TypeGift.GIFT_NFT, label: TypeGift.GIFT_NFT },
                        {
                          value: TypeGift.GIFT_COLLECTION,
                          label: TypeGift.GIFT_COLLECTION,
                        },
                        {
                          value: TypeGift.GIFT_LOCABONUS,
                          label: TypeGift.GIFT_LOCABONUS,
                        },

                        {
                          value: TypeGift.GIFT_LOCABONUS_QR_BIZ,
                          label: TypeGift.GIFT_LOCABONUS_QR_BIZ,
                        },
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="time_end"
                    label="Thời gian kết thúc tặng quà"
                    className={styles.datePicker}
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!!value) {
                            const start_event = moment(
                              getData?.event?.start_time,
                            );
                            const end_event = moment(getData?.event?.end_time);
                            if (!!getFieldValue('event_id')) {
                              if (value > end_event || value < start_event) {
                                return Promise.reject(
                                  'Thời gian phải nằm trong sự kiện',
                                );
                              }
                            }
                            return Promise.resolve();
                          } else {
                            return Promise.reject(
                              'Vui lòng nhập thời gian kết thúc quà tặng',
                            );
                          }
                        },
                      }),
                    ]}
                  >
                    {/* @ts-ignore */}
                    <DatePicker
                      format="HH:mm DD/MM/YYYY"
                      showTime
                      disabledDate={(currentDate) => currentDate < dayjs()}
                      // onChange={(date, dateString) => {
                      //   setData('time_end', dayjs(dateString).format());
                      // }}
                      showNow={false}
                    />
                  </Form.Item>
                </Col>

                {(getData.type === TypeGift.GIFT_NFT ||
                  getData.type === TypeGift.GIFT_COLLECTION) && (
                  <>
                    <Col span={24}>
                      <Form.Item
                        name="type_address_receive"
                        label="Nơi nhận vật phẩm"
                        rules={[{ required: true, message: 'Vui lòng chọn' }]}
                        initialValue="home"
                      >
                        <Select
                          onChange={(data) => {
                            setData('type_address_receive', data);
                          }}
                          options={[
                            { value: 'home', label: 'Tại nhà' },
                            {
                              value: 'detail',
                              label: 'Địa chỉ cụ thể',
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    {getData.type_address_receive === 'detail' && (
                      <>
                        <Col span={24}>
                          <Form.Item
                            name="address_detail"
                            label="Địa chỉ"
                            rules={[
                              {
                                required: true,
                                message: 'Vui lòng nhập địa chỉ',
                              },
                            ]}
                          >
                            <Input placeholder="Nhập địa chỉ" />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            name="address_latitude"
                            label="Lat"
                            rules={[
                              {
                                required: true,
                                message: 'Vui lòng nhập',
                              },
                              {
                                message: 'latitude trong khoảng -90 đến 90',
                                validator: (_, value) => {
                                  if (
                                    Number(value) <= 90 &&
                                    Number(value) >= -90
                                  ) {
                                    return Promise.resolve();
                                  } else {
                                    return Promise.reject('Some message here');
                                  }
                                },
                              },
                            ]}
                          >
                            <Input placeholder="Latitude" />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            name="address_longitude"
                            label="Long"
                            rules={[
                              {
                                required: true,
                                message: 'Vui lòng nhập',
                              },
                              {
                                message: 'Longitude trong khoảng -180 đến 180',
                                validator: (_, value) => {
                                  if (
                                    Number(value) <= 180 &&
                                    Number(value) >= -180
                                  ) {
                                    return Promise.resolve();
                                  } else {
                                    return Promise.reject('Some message here');
                                  }
                                },
                              },
                            ]}
                          >
                            <Input placeholder="Longitude" />
                          </Form.Item>
                        </Col>
                      </>
                    )}
                  </>
                )}
              </Row>

              <UploadImage
                propsUpload={{ disabled: !onEdit }}
                label="Ảnh quà tặng"
                fileList={fileList}
                setFileList={setFileList}
                name="image"
                compressor={true}
              />

              <Form.Item
                name="status"
                valuePropName="checked"
                label="Trạng thái"
              >
                <Switch onChange={(checked) => setData('status', checked)} />
              </Form.Item>
              <Form.Item
                name="qr_require"
                valuePropName="checked"
                label="QR require"
              >
                <Switch
                  onChange={(checked) => setData('qr_require', checked)}
                />
              </Form.Item>
              {(getData.type === TypeGift.GIFT_COLLECTION ||
                getData.type == TypeGift.GIFT_NFT) && (
                <Form.Item
                  name="transferable"
                  valuePropName="checked"
                  label="Có thể chuyển nhượng"
                >
                  <Switch
                    onChange={(checked) => setData('transferable', checked)}
                  />
                </Form.Item>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      {(getData.type === TypeGift.GIFT_COLLECTION ||
        getData.type === TypeGift.GIFT_NFT) && (
        <>
          <div className={styles.titleSub}>Chính sách nhận quà</div>
          <Row gutter={[16, 16]} className={styles.backgroundColor}>
            <Col span={24}>
              <Row gutter={[16, 16]}>
                <Col span={12} lg={12} sm={12} xs={24}>
                  <Form.Item
                    label="Phân loại (Mẫu mã/Màu sắc), Quy cách nhận"
                    initialValue={GIFT_POLICY.receive_item}
                    name="receive_item"
                  >
                    <Input placeholder="Phân loại, quy cách nhận" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={12} lg={12} sm={12} xs={24}>
                  <Form.Item
                    label="Điều kiện nhận vật phẩm"
                    initialValue={GIFT_POLICY.conditions_receiving}
                    name="conditions_receiving"
                  >
                    <Input.TextArea
                      rows={4}
                      placeholder="Điều kiện nhận vật phẩm"
                    />
                  </Form.Item>
                </Col>
                <Col span={12} lg={12} sm={12} xs={24}>
                  <Form.Item
                    label="Các bước đổi vật phẩm"
                    initialValue={GIFT_POLICY.steps_exchange}
                    name="steps_exchange"
                  >
                    <Input.TextArea
                      rows={4}
                      placeholder="Các bước đổi vật phẩm"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      )}
      <div className={styles.titleSub}>Mảnh ghép</div>
      {(getData.type === TypeGift.GIFT_COLLECTION ||
        getData.type === TypeGift.GIFT_NFT) && (
        <>
          <div className={styles.backgroundColor}>
            <Row gutter={[16, 16]}>
              <Col span={12} lg={12} sm={12} xs={24}>
                <Form.Item
                  label="Số lượng mảnh ghép"
                  name="quantity"
                  initialValue={1}
                  rules={[
                    {
                      validator(rule, value) {
                        if (!value) {
                          return Promise.reject('Vui lòng nhập');
                        } else {
                          if (value !== 1 && (value > 12 || value % 2 !== 0)) {
                            return Promise.reject(
                              'Số mảnh phải là số chẵn nhỏ hơn 12',
                            );
                          }
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <InputNumber
                    {...propsInput}
                    min={0}
                    style={{
                      width: '50%',
                    }}
                    onChange={(val) => {
                      setfileListCrop([]);
                      setNumOfPuzzle(val ?? 0);
                      const puz = Array.from(new Array(val), (_, i) => ({
                        id_nft: 0,
                        address: '',
                        address_type: LocationDropGift.OTHER,
                        name: '',
                        image: '',
                      }));
                      form.setFieldsValue({
                        puzzle_gifts: puz,
                      });
                      setData(`puzzle_gifts`, puz);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12} lg={12} sm={12} xs={24}>
                {form.getFieldValue('quantity') > 1 && (
                  <UploadImage
                    resize={true}
                    label="Ảnh muốn cắt"
                    name="url_cropping_image"
                    required={false}
                    fileList={fileListCrop}
                    setFileList={setfileListCrop}
                    propsUpload={{
                      disabled:
                        !form.getFieldValue('quantity') ||
                        (form.getFieldValue('quantity') !== 1 &&
                          form.getFieldValue('quantity') % 2 !== 0) ||
                        form.getFieldValue('quantity') > 12
                          ? true
                          : propsInput.disabled,
                      accept: '.png,.jpg,.jpeg',
                      style: {
                        width: '300px',
                        height: '200px',
                      },
                      listType: 'picture-card',
                      action: ENVIRONMENTS.API_URL + API_PATH.RESIZE_IMAGE,
                      data: {
                        quantity: form.getFieldValue('quantity'),
                      },
                      onChange: handleChangeFile,
                    }}
                  />
                )}
              </Col>
            </Row>
          </div>
        </>
      )}
      <Form.List
        {...propsInput}
        name="puzzle_gifts"
        rules={[
          {
            validator: async (_, names) => {
              if (names.length > 1 && names.length % 2 !== 0) {
                return Promise.reject(
                  new Error('Số lượng mảnh ghép phải là số chẵn'),
                );
              }
            },
          },
        ]}
        initialValue={[
          {
            id_nft: 0,
            address: '',
            address_type: LocationDropGift.OTHER,
            name: '',
            image: '',
          },
        ]}
      >
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map((field, index) => (
              <div key={index}>
                {getData.type === TypeGift.GIFT_NFT ? (
                  <FormNFT
                    form={form}
                    propsInput={{
                      disabled:
                        getData?.gift_status === Status.DUYET ? true : !onEdit,
                    }}
                    index={index}
                    field={field}
                    getData={getData}
                    clearable={index === fields.length - 1 && fields.length > 1}
                    isEditPuzzle={isEditPuzzle}
                    onClear={() => remove(field.name)}
                  />
                ) : (
                  <FormCollection
                    setData={setData}
                    propsInput={{
                      disabled:
                        getData?.gift_status === Status.DUYET ? true : !onEdit,
                    }}
                    getData={getData}
                    index={index}
                    field={field}
                    form={form}
                    isEditPuzzle={isEditPuzzle}
                    clearable={index === fields.length - 1 && fields.length > 1}
                    onClear={() => remove(field.name)}
                  />
                )}
              </div>
            ))}
            {/* {(getData.type === TypeGift.GIFT_COLLECTION ||
              getData.type === TypeGift.GIFT_NFT) && (
              <Form.Item>
                <Button
                  className={styles.addListkeyBtn}
                  type="primary"
                  ghost
                  onClick={() =>
                    add({
                      id_nft: 0,
                      address: '',
                      address_type: LocationDropGift.OTHER,
                      name: '',
                      image: '',
                    })
                  }
                  icon={<PlusOutlined />}
                  {...propsInput}
                >
                  Thêm mảnh
                </Button>

                <Form.ErrorList errors={errors} />
              </Form.Item>
            )} */}
          </>
        )}
      </Form.List>
    </>
  );
};
