import { API_PATH, privateRequest, request } from '@/utils/apis';
export enum ProductStatus {
  PENDING = 'PENDING',
  VERIFIED = 'VERIFIED',
  DENIED = 'DENIED',
}
export const PRODUCT_STATUS = [
  {
    value: ProductStatus.PENDING,
    label: 'Chờ duyệt',
    color: 'warning',
  },
  {
    value: ProductStatus.VERIFIED,
    label: 'Đã duyệt',
    color: 'green',
  },
  {
    value: ProductStatus.DENIED,
    label: 'Đã từ chối',
    color: 'red',
  },
];
export const getListProduct = (
  {
    current,
    pageSize,
  }: {
    current: number;
    pageSize: number;
  },
  formData: {
    status?: string;
    search: string;
  },
) => {
  const params: any = {
    page: current,
    limit: pageSize,
  };
  if (!!formData.status) {
    params.status = formData.status;
  }
  if (!!formData.search) {
    params.search = formData.search;
  }
  return privateRequest(request.get, API_PATH.LIST_PRODUCT, { params }).then(
    (response) => {
      return {
        list: response?.data.map((item: any, index: number) => ({
          ...item,
          stt: (current - 1) * pageSize + index + 1,
        })),
        total: response?.total,
      };
    },
  );
};

export const getProductCategory = () => {
  return privateRequest(request.get, API_PATH.PRODUCT_CATEGORY);
};

export const addProductCategory = (name: string) => {
  return privateRequest(
    request.post,
    API_PATH.PRODUCT_CATEGORY + '/' + `${name}`,
  );
};

export const editProductCategory = (id: number, name: string) => {
  return privateRequest(
    request.patch,
    API_PATH.PRODUCT_CATEGORY + '/' + `${id}`,
    { data: { name, category_id: id } },
  );
};
export const deleteProductCategory = (id: number) => {
  return privateRequest(
    request.delete,
    API_PATH.PRODUCT_CATEGORY + '/' + `${id}`,
  );
};

export const getProductProperty = () => {
  return privateRequest(request.get, API_PATH.PRODUCT_PROPERTY);
};

export const addProductProperty = (name: string) => {
  return privateRequest(
    request.post,
    API_PATH.PRODUCT_PROPERTY + '/' + `${name}`,
  );
};

export const editProductProperty = (id: number, name: string) => {
  return privateRequest(
    request.patch,
    API_PATH.PRODUCT_PROPERTY + '/' + `${id}`,
    { data: { name, product_prop_id: id } },
  );
};
export const deleteProductProperty = (id: number) => {
  return privateRequest(
    request.delete,
    API_PATH.PRODUCT_PROPERTY + '/' + `${id}`,
  );
};

export const getBundleProduct = () => {
  return privateRequest(request.get, API_PATH.CREATE_BUNDLE_PRODUCT);
};

export const addBundleProduct = (name: string, maxium_property: number) => {
  return privateRequest(request.post, API_PATH.CREATE_BUNDLE_PRODUCT, {
    data: { name, maxium_property },
  });
};

export const editBundleProduct = (
  id: number,
  input: { name: string; maxium_property: number },
) => {
  return privateRequest(
    request.patch,
    API_PATH.CREATE_BUNDLE_PRODUCT + '/' + id,
    {
      data: input,
    },
  );
};
export const deleteBundleProduct = (id: number) => {
  return privateRequest(
    request.delete,
    API_PATH.CREATE_BUNDLE_PRODUCT + '/' + `${id}`,
  );
};

export const createProductService = (input: any) => {
  return privateRequest(request.post, API_PATH.LIST_PRODUCT, {
    data: input,
  });
};

export const updateProductService = (input: any, id: number) => {
  return privateRequest(request.put, API_PATH.LIST_PRODUCT + `/${id}`, {
    data: input,
  });
};

export const detailProductService = (id: number) => {
  return privateRequest(request.get, API_PATH.DETAIL_PRODUCT(id));
};

export const getBundleUpdateProduct = (id: number) => {
  return privateRequest(request.get, API_PATH.UPDATE_BUNDLE_PRODUCT(id));
};

export const getCategoryUpdateProduct = (id: number) => {
  return privateRequest(request.get, API_PATH.UPDATE_CATEGORY_PRODUCT(id));
};

export const createBundleUpdateProduct = (input: any, id: number) => {
  return privateRequest(
    request.post,
    API_PATH.CREATE_BUNDLE_PRODUCT_UPDATE(id),
    {
      data: input,
    },
  );
};

export const updateBundleUpdateProduct = (input: any, id: number) => {
  return privateRequest(
    request.patch,
    API_PATH.CREATE_BUNDLE_PRODUCT_UPDATE(id),
    {
      data: input,
    },
  );
};
export const deleteBundleUpdateProduct = (id: number) => {
  return privateRequest(
    request.delete,
    API_PATH.CREATE_BUNDLE_PRODUCT_UPDATE(id),
  );
};
