import React from 'react';
import styles from './index.less';
import {
  Breadcrumb,
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  Table,
  Select,
} from 'antd';
import { useIntl } from 'umi';
import {
  LOCAVOUCHER_STATUS,
  TYPE_OF_ACCOUNT,
  TYPE_OF_TRANSACTION,
} from '@/utils/constant';
import { useAntdTable } from 'ahooks';
import { getListTransactionVoucher } from './service';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { LocaVoucherStatus } from '@/utils/enum';
const LocaVoucherTransaction = () => {
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();
  const { tableProps, data, search } = useAntdTable(getListTransactionVoucher, {
    form,
  });

  const columns: ColumnsType<any> = [
    {
      title: 'STT',
      dataIndex: 'stt',
    },
    {
      title: 'Thời gian giao dịch',
      dataIndex: 'created_at',
      render: (value) => <>{moment(value).format('HH:mm:ss DD/MM/YYYY')}</>,
    },
    {
      title: 'Mã giao dịch',
      dataIndex: 'transaction_code',
    },
    {
      title: 'Loại giao dịch',
      dataIndex: 'type_of_transaction',
      render: (value) => {
        return (
          <>{TYPE_OF_TRANSACTION.find((item) => item.value === value)?.name}</>
        );
      },
    },
    {
      title: 'Loại tài khoản',
      dataIndex: 'type_of_account',
      render: (value) => {
        return (
          <>{TYPE_OF_ACCOUNT.find((item) => item.value === value)?.name}</>
        );
      },
    },
    {
      title: 'User ID',
      dataIndex: 'user_id',
    },
    {
      title: 'Khách hàng',
      dataIndex: 'customer_name',
    },
    {
      title: 'Loại Voucher',
      dataIndex: 'type_of_voucher',
    },
    {
      title: 'Số lượng',
      dataIndex: 'voucher_amount',
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      render: (value) => {
        return (
          <>{LOCAVOUCHER_STATUS.find((item) => item.value === value)?.name}</>
        );
      },
    },
  ];
  const { submit } = search;
  const searchForm = (
    <div className={styles.searchContainer}>
      <Form form={form} className={styles.searchForm} layout="inline">
        <Form.Item name="searchText" className={styles.searchItem}>
          <Input.Search
            placeholder={formatMessage({ id: 'form_search_loca_voucher' })}
            allowClear
            onSearch={submit}
          />
        </Form.Item>
        <Form.Item name="typeAccount" className={styles.searchItem}>
          <Select
            onChange={submit}
            allowClear
            placeholder="Loại tài khoản"
            options={TYPE_OF_ACCOUNT.map((item) => ({
              label: item.name,
              value: item.value,
            }))}
          />
        </Form.Item>
        <Form.Item name="status" className={styles.searchItem}>
          <Select
            onChange={submit}
            allowClear
            placeholder="Trạng thái"
            options={LOCAVOUCHER_STATUS.map((item) => ({
              label: item.name,
              value: item.value,
            }))}
          />
        </Form.Item>
        <Form.Item name="toDate" className={styles.searchItem}>
          <DatePicker.RangePicker
            allowClear
            format="DD/MM/YYYY"
            placeholder={['Từ ngày', 'Đến ngày']}
            onChange={submit}
          />
        </Form.Item>
      </Form>
      <Button type="primary">
        {formatMessage({ id: 'general_trans_loca_voucher' })}
      </Button>
    </div>
  );
  return (
    <div>
      <div className={styles.balance}>
        <Card className={styles.cardBalance}>
          <p className={styles.cardTitle}>Số dư D - Locavoucher</p>
          <p className={styles.amount}>{data?.data?.d_voucher_balance}</p>
        </Card>
        <Card className={styles.cardBalance}>
          <p className={styles.cardTitle}>Số dư C - Locavoucher</p>
          <p className={styles.amount}>{data?.data?.c_voucher_balance}</p>
        </Card>
      </div>
      <Breadcrumb className={styles.breadcrumb}>
        <Breadcrumb.Item>Lịch sử giao dịch LocaVoucher</Breadcrumb.Item>
      </Breadcrumb>
      {searchForm}
      <div className={styles.tableComponent}>
        <Table
          {...tableProps}
          columns={columns}
          rowKey={'id'}
          scroll={{ x: 1000 }}
        />
      </div>
    </div>
  );
};

export default LocaVoucherTransaction;
