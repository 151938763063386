import { formatTime } from './../../utils/formatTime';
import { privateRequest, request, API_PATH } from '@/utils/apis';
import { ENVIRONMENTS } from '@/utils/constant';
import moment from 'moment';

interface Result {
  total: number;
  list: any[];
}

export const getTableData = (
  { current, pageSize }: { current: number; pageSize: number },
  formData: any,
): Promise<Result> => {
  if (formData.name === undefined) {
    formData.name = '';
  }
  const fromDate = Array.isArray(formData.toDate)
    ? moment(formData.toDate[0]).format('YYYY-MM-DD')
    : '';
  const toDate = Array.isArray(formData.toDate)
    ? moment(formData.toDate[1]).format('YYYY-MM-DD')
    : '';

  const data = {
    query: `

mutation {
  give_locabonus_history(filter: { 
    page_size:  ${pageSize || 10}
    page_index: ${current ? current - 1 : 0}
     ${formData.status ? 'type:' + formData.status : ''} 
    start_time: "${fromDate}"
    end_time: "${toDate}"
    name: "${formData.name}"
  
  }) {
    total
    total_customer_amt
    total_enterprise_amt
    turns
    data {
      created_at
      staff_name
      receive_customer
      customer_amt
      enterprise_amt
      type
      rating
      bill_path
      spending
    }
  }
}
      
    `,
  };

  return privateRequest(request.post, API_PATH.default, { data }).then(
    (res: any) => {
      // console.log(res?.data?.get_list_admin.data);

      const result = res?.data?.give_locabonus_history.data.map(
        (e: any, index: any) => ({
          ...e,
          stt: (current ? current - 1 : 0) * pageSize + (index + 1),
          staff_name: e.staff_name,
          created_at: e.created_at,
        }),
      );
      return {
        total: res?.data?.give_locabonus_history?.total,
        total_customer_amt:
          res?.data?.give_locabonus_history?.total_customer_amt,
        total_enterprise_amt:
          res?.data?.give_locabonus_history?.total_enterprise_amt,
        turns: res?.data?.give_locabonus_history?.turns,
        list: result,
      };
    },
  );
};

export const deleteAdmin = (id: any) => {
  const query = `
  mutation {
    delete_admin(input: { id: "${id}" })
  }

  `;
  return privateRequest(request.post, API_PATH.default, {
    data: {
      query,
    },
  });
};

export const switchStatusAdmin = (id: any) => {
  const query = `
    mutation {
      switch_status_admin(id: "${id}")
    }
  `;
  return privateRequest(request.post, API_PATH.default, {
    data: {
      query,
    },
  });
};
