import React, { useEffect, useCallback } from 'react';
import {
  Form,
  Input,
  Switch,
  Space,
  message,
  Col,
  Row,
  Button,
  Tag,
} from 'antd';
import styles from './index.less';
import { useRequest } from 'ahooks';
import ModalReject from '../ModalReject';
import ModalAccept from '../ModalAccept';
import FormGift from '../FormGift';
import { useParams } from 'umi';
import {
  useGetGiftDetail,
  Status,
  OptionStatus,
  ICreateGift,
  requestUpdateGift,
} from '../../service';
import dayjs from 'dayjs';
import { history } from 'umi';
import { checkURL } from '@/utils/apis/common';

// const { Option } = Select;
export enum TypeGift {
  BONUS = 1,
  VOUCHER = 2,
}
export default () => {
  const [form] = Form.useForm();
  const param: any = useParams();
  const { UserDetail, loading } = useGetGiftDetail(param?.id);
  const [dataForm, setDataForm] = React.useState<ICreateGift>();
  const [fileList, setFileList] = React.useState<any[]>([]);
  const [fileListCrop, setfileListCrop] = React.useState<any[]>([]);
  // console.log('🚀 ~ file: index.tsx:22 ~ UserDetail:', UserDetail);

  useEffect(() => {
    form.setFieldsValue(UserDetail);
    form.setFieldValue('time_end', dayjs(UserDetail?.time_end));
    form.setFieldValue('status', UserDetail?.status == 'ACTIVE' ? true : false);
    form.setFieldValue('quantity', UserDetail?.puzzle_gifts?.length);

    setFileList([
      {
        uid: '-1',
        name: 'image.png',
        status: 'done',
        url: UserDetail?.image,
      },
    ]);
    if (
      !!UserDetail?.url_cropping_image &&
      checkURL(UserDetail?.url_cropping_image)
    ) {
      setfileListCrop([
        {
          uid: '-1',
          name: 'image.png',
          status: 'done',
          url: UserDetail?.url_cropping_image,
        },
      ]);
    } else {
      setfileListCrop([
        {
          uid: '-1',
          name: 'image.png',
          status: 'error',
        },
      ]);
    }
    setDataForm(UserDetail);
  }, [UserDetail]);
  const [isEdit, setIsEdit] = React.useState(false);

  const onFinish = (values: any) => {
    const formatData: ICreateGift = {
      ...values,
      image: values.image?.[0]?.response?.url
        ? `${values.image?.[0]?.response?.url}${values.image?.[0]?.response?.path}`
        : values.image,
      puzzle_gifts: values?.puzzle_gifts?.map((item: any) => ({
        ...item,
        image: item.image?.[0]?.response?.url
          ? `${item.image?.[0]?.response?.url}${item.image?.[0]?.response?.path}`
          : item.image,
      })),
      time_end: dayjs(values.time_end).format(),
      status: values.status ? 'ACTIVE' : 'NOT_ACTIVE',
      url_cropping_image:
        values.url_cropping_image?.[0]?.response?.url ??
        values.url_cropping_image,
    };

    onCreateGift.run(formatData);
  };
  const onCreateGift = useRequest(
    (payload: ICreateGift) => {
      return requestUpdateGift(payload, param?.id);
    },
    {
      manual: true,
      onSuccess: () => {
        message.success('Thành công');
        history.push('/gift-building');
      },
      onError: (err: any) => {
        message.error(err.data.message);
      },
    },
  );
  const onEdit = () => {
    setIsEdit(!isEdit);
    form.setFieldValue('isEdit', !isEdit);
  };
  const getDataForm = (name: string, value: any) => {
    setDataForm((prev: any) => ({ ...prev, [name]: value }));
  };

  const convertStatus = (statusCode: string) => {
    const data: any = OptionStatus.filter((status) => {
      if (status.value == statusCode) {
        return true;
      } else {
        return false;
      }
    });
    return data[0] || {};
  };

  const Conten = useCallback(() => {
    if (!!dataForm) {
      return (
        <>
          <FormGift
            fileList={fileList}
            fileListCrop={fileListCrop}
            setFileList={setFileList}
            setfileListCrop={setfileListCrop}
            getData={dataForm}
            setData={getDataForm}
            form={form}
            UserDetail={UserDetail}
            onChange={setDataForm}
          />
        </>
      );
    } else {
      return <></>;
    }
  }, [dataForm, fileList, fileListCrop]);

  const ActionModel = useCallback(() => {
    if (!!dataForm && dataForm.gift_status == Status.DUYET) {
      return (
        <Space>
          <Button onClick={onEdit}>Sửa</Button>
        </Space>
      );
    } else if (!!dataForm && dataForm.gift_status == Status.TU_CHOI) {
      return <Space></Space>;
    } else {
      return (
        <Space>
          {/* <ModalAccept id={param?.id} onReload={() => { history.push('/gift-building') }}>Duyệt</ModalAccept> */}
          {/* <ModalReject id={param?.id} onReload={() => { history.push('/gift-building') }}>Từ chối</ModalReject> */}
          <Button onClick={onEdit}>Sửa</Button>
        </Space>
      );
    }
  }, [dataForm]);
  const giftStatus = convertStatus(UserDetail?.gift_status);
  return (
    <div className={styles.infoGift}>
      <Row justify="space-between">
        <Col>
          <p className={styles.giftSummary}>ID: {UserDetail?.id}</p>
          <p className={styles.giftSummary}>
            Trạng thái:{' '}
            {UserDetail?.gift_status == Status.TU_CHOI ? (
              <>
                <Tag color={giftStatus?.color}>{giftStatus?.label}</Tag>- Lý do:{' '}
                {UserDetail?.refusal_reason}
              </>
            ) : (
              <Tag color={giftStatus?.color}>{giftStatus?.label}</Tag>
            )}
          </p>
          <p className={styles.giftSummary}>
            Người tạo:{' '}
            {UserDetail?.business_id ? (
              <>
                DN {UserDetail?.business_id} - {UserDetail?.business_name} -{' '}
                {UserDetail?.business_mobile}
              </>
            ) : (
              <>{UserDetail?.created?.email}</>
            )}
          </p>
        </Col>
        <Col>
          {isEdit ? (
            <Space>
              <Button onClick={onEdit} type="primary" danger ghost>
                Huỷ
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  form.submit();
                }}
              >
                Lưu
              </Button>
            </Space>
          ) : (
            <ActionModel />
          )}
        </Col>
      </Row>
      <Form
        onFinish={onFinish}
        form={form}
        autoComplete="off"
        className={styles.formSearch}
        layout="vertical"
        initialValues={{
          typeGift: TypeGift.BONUS,
          isEdit: isEdit,
          isEditPuzzle: false,
        }}
        disabled={!isEdit}
      >
        <FormGift
          fileList={fileList}
          fileListCrop={fileListCrop}
          setFileList={setFileList}
          setfileListCrop={setfileListCrop}
          getData={dataForm}
          setData={getDataForm}
          form={form}
          UserDetail={UserDetail}
          onChange={setDataForm}
        />
      </Form>
    </div>
  );
};
