import React from 'react';
import styles from './index.less';
import {
  Breadcrumb,
  Button,
  Form,
  Image,
  Input,
  Row,
  Select,
  Switch,
  Tag,
  Tooltip,
  Table,
} from 'antd';
import {
  CheckOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  EditOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { useAntdTable } from 'ahooks';
import { PRODUCT_STATUS, getListProduct } from './service';
import { ColumnsType } from 'antd/lib/table';
import { formatNumber } from '@/utils/apis/common';
import { BlankImage, ENVIRONMENTS } from '@/utils/constant';
import { history } from 'umi';
export const renderProductStatus = (status: string) => {
  switch (status) {
    case 'VERIFIED':
      return (
        <Tag color="green" icon={<CheckOutlined />}>
          Đã duyệt
        </Tag>
      );
    case 'PENDING':
      return (
        <Tag color="warning" icon={<ClockCircleOutlined />}>
          Chờ duyệt
        </Tag>
      );
    case 'DENIED':
      return (
        <>
          <Tag color="red" icon={<CloseOutlined />}>
            Từ chối
          </Tag>
        </>
      );
    default:
      return (
        <Tag color="gray" icon={<ClockCircleOutlined />}>
          Chưa xác thực
        </Tag>
      );
  }
};
const Product = () => {
  const [form] = Form.useForm();
  const { tableProps, search } = useAntdTable(getListProduct, {
    form,
  });
  const columns: ColumnsType<any> = [
    {
      title: 'STT',
      dataIndex: 'stt',
    },
    {
      title: 'Mã sản phẩm',
      dataIndex: 'sku_code',
    },
    {
      title: 'Tên sản phẩm',
      dataIndex: 'name',
    },
    {
      title: 'Ảnh sản phẩm',
      dataIndex: 'images',
      render(value) {
        return (
          <Image
            src={ENVIRONMENTS.BUCKET_NAME + '/' + value?.[0]?.link}
            fallback={BlankImage}
            width={80}
            height={80}
          />
        );
      },
    },
    {
      title: 'Nhóm sản phẩm',
      dataIndex: ['category', 'name'],
    },
    {
      title: 'Giá bán',
      dataIndex: 'price',
      render: (value) => <>{formatNumber(value)}đ</>,
    },
    {
      title: 'Trạng thái',
      dataIndex: 'verify_status',
      render: (value) => <>{renderProductStatus(value)}</>,
    },
    {
      title: 'Hành động',
      align: 'center',
      width: '10%',

      render: (value, record) => (
        <>
          <Row justify="space-around">
            <Tooltip title="Chỉnh sửa">
              <EditOutlined
                className="primary-color cursor-pointer"
                onClick={() => history.push(`/products/${record?.id}`)}
              />
            </Tooltip>
            <Tooltip title="Trạng thái">
              <Switch />
            </Tooltip>
          </Row>
        </>
      ),
    },
  ];
  const { submit } = search;
  const searchForm = (
    <div className={styles.searchContainer}>
      <Form form={form} className={styles.searchForm}>
        <Form.Item name="search" className={styles.searchItem}>
          <Input.Search
            placeholder="Tìm kiếm theo tên sản phẩm, mã SP"
            allowClear
            onSearch={submit}
          />
        </Form.Item>
        <Form.Item name="status" className={styles.searchItem}>
          <Select
            placeholder="Trạng thái xác thực"
            allowClear
            options={PRODUCT_STATUS}
            onChange={submit}
          />
        </Form.Item>
      </Form>
      <Button href="/create-product" icon={<PlusOutlined />}>
        Tạo sản phẩm
      </Button>
    </div>
  );
  return (
    <>
      <div className={styles.header}>
        <Breadcrumb className={styles.breadcrumb}>
          <Breadcrumb.Item>Danh sách sản phẩm</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      {searchForm}
      <div className={styles.tableComponent}>
        <Table
          {...tableProps}
          columns={columns}
          rowKey={(item) => item.id}
          scroll={{ x: 1000 }}
        />
      </div>
    </>
  );
};

export default Product;
