import { formatInputNumber } from '@/utils/apis/common';
import { DeleteOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Form,
  FormInstance,
  FormListFieldData,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Switch,
} from 'antd';
import React, { useEffect, useState } from 'react';
import DrawerBundleProduct from './DrawerBundleProduct';
interface IProps {
  bundleProduct: any;
  form: FormInstance<any>;
  field: FormListFieldData;
  remove: (name: number | number[]) => void;
}
const InputBundleProduct = ({ bundleProduct, field, remove, form }: IProps) => {
  const [isOpenBundleProduct, setIsOpenBundleProduct] = useState(false);
  const [selectedBundleProduct, setSelectedBundleProduct] = useState<any>();
  const onChooseBundleProduct = (id: number) => {
    setSelectedBundleProduct(
      bundleProduct?.data?.find((item: any) => item.id === id),
    );
    const currentFormvalue = form.getFieldsValue();
    const updateForm = currentFormvalue?.bundles_products?.map(
      (item: any, index: number) => {
        if (index === field.name) {
          return {
            ...item,
            bundled_products_default_id: id,
          };
        }
        return item;
      },
    );
    form.setFieldsValue({
      ...currentFormvalue,
      bundles_products: updateForm,
    });
    setIsOpenBundleProduct(false);
  };
  const bundle = form.getFieldValue('bundles_products');
  useEffect(() => {
    if (bundle?.length > 0) {
      setSelectedBundleProduct(
        bundleProduct?.data?.find(
          (item: any) =>
            item.id === bundle?.[field.name]?.bundled_products_default_id,
        ),
      );
      // onChooseBundleProduct(bundle?.[field.name]?.bundled_products_default_id);
    }
  }, [bundle, bundleProduct]);
  return (
    <Row key={field.key} justify="space-between">
      <Col span={5}>
        <Form.Item
          {...field}
          name={[field.name, 'bundled_products_default_id']}
          rules={[{ required: true, message: 'Vui lòng chọn' }]}
        >
          <Select
            placeholder={'Nhóm sản phẩm bán kèm'}
            onClick={() => setIsOpenBundleProduct(true)}
            open={false}
            options={bundleProduct?.data?.map((item: any) => ({
              value: item?.id,
              label: item?.name,
            }))}
          />
        </Form.Item>
      </Col>
      <Col span={5}>
        <Form.Item
          {...field}
          rules={[{ required: true, message: 'Vui lòng nhập' }]}
          name={[field.name, 'maxium_pick']}
        >
          <InputNumber
            style={{
              width: '100%',
            }}
            placeholder="Luợt chọn tối đa"
          />
        </Form.Item>
      </Col>
      {!selectedBundleProduct ||
      selectedBundleProduct?.maxium_property === 1 ? (
        <>
          <Col span={5}>
            <Form.Item
              {...field}
              name={[field.name, 'name']}
              rules={[{ required: true, message: 'Vui lòng nhập' }]}
            >
              <Input placeholder="Tên sản phẩm đi kèm" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              {...field}
              name={[field.name, 'price']}
              rules={[{ required: true, message: 'Vui lòng nhập' }]}
            >
              <InputNumber
                style={{
                  width: '100%',
                }}
                placeholder="Giá bán"
                step={1000}
                formatter={formatInputNumber}
              />
            </Form.Item>
          </Col>
        </>
      ) : (
        <Col span={10}>
          {Array(selectedBundleProduct?.maxium_property)
            .fill(0)
            .map((item, index: number) => (
              <Row justify="space-around" key={index}>
                <Col span={12}>
                  <Form.Item
                    {...field}
                    name={[field.name, index, 'name']}
                    rules={[{ required: true, message: 'Vui lòng nhập' }]}
                  >
                    <Input placeholder="Tên sản phẩm đi kèm" />
                  </Form.Item>
                </Col>
                <Col span={9}>
                  <Form.Item
                    {...field}
                    name={[field.name, index, 'price']}
                    rules={[{ required: true, message: 'Vui lòng nhập' }]}
                  >
                    <InputNumber
                      style={{
                        width: '100%',
                      }}
                      placeholder="Giá bán"
                      step={1000}
                      formatter={formatInputNumber}
                    />
                  </Form.Item>
                </Col>
              </Row>
            ))}
        </Col>
      )}

      <Col span={3}>
        <Row align="middle">
          <Form.Item
            {...field}
            name={[field.name, 'active']}
            valuePropName="checked"
          >
            <Switch
              defaultChecked={true}
              style={{
                marginTop: '5px',
                marginRight: '10px',
              }}
            />
          </Form.Item>

          <Button
            type="text"
            ghost
            icon={<DeleteOutlined className="red-color" />}
            onClick={() => remove(field.name)}
          ></Button>
        </Row>
      </Col>
      <DrawerBundleProduct
        bundleProduct={bundleProduct}
        onChooseBundleProduct={onChooseBundleProduct}
        open={isOpenBundleProduct}
        setOpen={setIsOpenBundleProduct}
      />
    </Row>
  );
};

export default InputBundleProduct;
