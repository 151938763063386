import { Button, Form, Input, Select, Space, Tabs } from 'antd';

import { useTranslate } from '@/utils/hooks/useTranslate';
import { useHistory, useRequest } from 'umi';
import styles from '../index.less';
import { Dispatch } from 'react';
import { paternPhone } from '@/utils/patern';
import { getListCountry } from '@/pages/Register/service';
type Props = {
  onFinishStep1: (value: any) => void;
  loading: boolean;
  setDialCode: Dispatch<React.SetStateAction<string>>;
  dialCode: string;
};

const Step1 = (props: Props) => {
  const { dialCode, setDialCode } = props;
  const { t } = useTranslate();
  const history = useHistory();
  const nation = useRequest(getListCountry);
  const onFinish = (val: any) => {
    if (dialCode === '+84' && val.phone_number.charAt(0) === '0') {
      val.phone_number = val.phone_number.substring(1, val.phone_number.length);
    } else {
      if (val.phone_number?.includes(dialCode)) {
        val.phone_number = val.phone_number?.replace(dialCode, '');
      }
      if (val.phone_number?.includes(dialCode.replace('+', ''))) {
        val.phone_number = val.phone_number?.replace(
          dialCode.replace('+', ''),
          '',
        );
      }
    }
    // console.log({ phone_number: dialCode + val.phone_number });

    props.onFinishStep1({
      phone_number: dialCode + val.phone_number,
    });
  };
  return (
    <div className={styles.loginWrap}>
      <h1>Quên mật khẩu</h1>
      <Form onFinish={onFinish} layout="vertical">
        <Form.Item
          label={t('phone_number')}
          name="phone_number"
          rules={[
            {
              required: true,
              message: t('error.require', {
                field: t('phone_number'),
              }),
            },

            {
              pattern: dialCode === '+84' ? paternPhone : /^.[0-9]{0,15}$/,

              message: t('error.patern', {
                field: t('const_column_phone_number'),
              }),
            },
          ]}
        >
          <Space.Compact
            style={{
              width: '100%',
            }}
          >
            <Select
              showSearch
              defaultValue="+84"
              onChange={(val) => setDialCode(val)}
              style={{
                width: '110px',
              }}
              options={nation.data?.get_list_country
                ?.filter((item: any) => item?.code !== 'CAN')
                ?.map((item: any, i: number) => ({
                  label: (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                        paddingTop: '1px',
                      }}
                    >
                      <div
                        style={{
                          marginTop: '2px',
                        }}
                        dangerouslySetInnerHTML={{
                          __html: item?.svg,
                        }}
                      ></div>
                      <div>{item?.calling_code}</div>
                    </div>
                  ),
                  value: item?.calling_code,
                  key: i,
                }))}
            ></Select>
            <Input type="text" placeholder={t('phone_number')} />
          </Space.Compact>
        </Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          loading={props.loading}
          className={styles.btnSubmit}
        >
          Tiếp theo
        </Button>
      </Form>
      <div className={styles.register}>
        <h2>Đã có tài khoản?</h2>
        <div
          className={styles.links}
          onClick={() => {
            history.push('/login');
          }}
        >
          <span>Đăng Nhập</span>
        </div>
      </div>
    </div>
  );
};

export default Step1;
