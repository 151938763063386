import { API_PATH, privateRequest, request } from '@/utils/apis';
import moment from 'moment';

export enum PACKAGE_BUY_TYPE {
  LOCABONUS = 'LOCABONUS',
  VNPAY = 'VNPAY',
}

export const getBuyPackageHistory = (
  {
    current,
    pageSize,
  }: {
    current: number;
    pageSize: number;
  },
  formData: {
    toDate?: string[];
    buy_type?: PACKAGE_BUY_TYPE;
  },
): Promise<any> => {
  const fromDate =
    Array.isArray(formData.toDate) && moment(formData.toDate[0]).isValid()
      ? moment(formData.toDate[0]).format('YYYY-MM-DD')
      : '';
  const toDate =
    Array.isArray(formData.toDate) && moment(formData.toDate[1]).isValid()
      ? moment(formData.toDate[1]).format('YYYY-MM-DD')
      : '';
  let query = `?page_size=${pageSize}&page_index=${current - 1}`;
  if (fromDate && toDate) {
    query += `&start_time=${fromDate}&end_time=${toDate}`;
  }
  if (formData.buy_type) {
    query += `&buy_type=${formData.buy_type}`;
  }

  return privateRequest(request.get, API_PATH.BUY_PACKAGE_HISTORY + query).then(
    (res) => {
      // console.log(res);
      const result = res?.data?.map((item: any, index: number) => ({
        ...item,
        stt: (current ? current - 1 : 0) * pageSize + (index + 1),
      }));
      return {
        list: result,
        total: res?.total,
        turn_remaining: res?.turn_remaining,
        main_package: res?.main_package,
        balance: res?.balance,
      };
    },
  );
};
