import React, { useState } from 'react';
import {
  Button,
  Drawer,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Table,
  Tooltip,
  message,
} from 'antd';
import styles from '../index.less';
import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import { useParams, useRequest } from 'umi';
import {
  addBundleProduct,
  createBundleUpdateProduct,
  deleteBundleProduct,
  deleteBundleUpdateProduct,
  editBundleProduct,
  updateBundleUpdateProduct,
} from '../service';

const DrawerBundleProduct = ({
  open,
  setOpen,
  bundleProduct,
  onChooseBundleProduct,
}: {
  open: boolean;
  setOpen: any;
  bundleProduct: any;
  onChooseBundleProduct: (id: number) => void;
}) => {
  const [selectedRowKey, setSelectedRowKey] = useState(0);
  const [isEditBundleProduct, setIsEditBundleProduct] = useState(false);
  const [isAddBundleProduct, setIsAddBundleProduct] = useState(false);
  const [idEditBundleProduct, setIdEditBundleProduct] = useState(0);
  const [form] = Form.useForm();
  const params: {
    id: string;
  } = useParams();
  const isUpdateProduct = Number.isInteger(+params.id);

  const onClose = () => {
    setOpen(false);
  };
  const handleSelect = (key: any) => {
    setSelectedRowKey(key);
  };
  const columns: ColumnsType<any> = [
    {
      width: '5%',
    },
    {
      dataIndex: 'name',
      className: styles.columnName,
    },
    {
      dataIndex: 'maxium_property',
      className: styles.columnProps,
    },
    {
      dataIndex: 'value',
      className: styles.columnAction,
      render: (value, record) => (
        <>
          <Row justify="space-around">
            <Tooltip title="Chỉnh sửa">
              <EditOutlined
                className="primary-color cursor-pointer"
                onClick={() => {
                  setIsEditBundleProduct(true);
                  setIsAddBundleProduct(false);
                  form.setFieldValue('name', record?.name);
                  form.setFieldValue(
                    'maxium_property',
                    record?.maxium_property,
                  );
                  setIdEditBundleProduct(record?.id);
                }}
              />
            </Tooltip>
            <Tooltip title="Xoá">
              <DeleteOutlined
                className="red-color cursor-pointer"
                onClick={() =>
                  isUpdateProduct
                    ? deleteBundleUpdate.run(record?.id)
                    : deleteBundle.run(record?.id)
                }
              />
            </Tooltip>
            <Tooltip title="Chọn">
              <Radio
                checked={selectedRowKey === record.id}
                onChange={() => handleSelect(record.id)}
              />
            </Tooltip>
          </Row>
        </>
      ),
    },
  ];
  const closeAddBundleProduct = () => {
    form.resetFields();
    setIsAddBundleProduct(false);
  };
  const closeEditBundleProduct = () => {
    form.resetFields();
    setIsEditBundleProduct(false);
  };
  const addBundle = useRequest(addBundleProduct, {
    manual: true,
    onError: (err) => {
      console.log(err);
      closeAddBundleProduct();
    },
    onSuccess: (res) => {
      message.success('Thêm nhóm thành công');
      closeAddBundleProduct();
      bundleProduct.refresh();
    },
  });
  const addBunbleUpdate = useRequest(createBundleUpdateProduct, {
    manual: true,
    onError: (err) => {
      console.log(err);
      closeAddBundleProduct();
    },
    onSuccess: (res) => {
      message.success('Thêm nhóm thành công');
      closeAddBundleProduct();
      bundleProduct.refresh();
    },
  });
  const editBundle = useRequest(editBundleProduct, {
    manual: true,
    onError: (err) => {
      console.log(err);
      closeEditBundleProduct();
    },
    onSuccess: (res) => {
      message.success('Sửa nhóm thành công');
      closeEditBundleProduct();
      bundleProduct.refresh();
    },
  });
  const editBundleUpdate = useRequest(updateBundleUpdateProduct, {
    manual: true,
    onError: (err) => {
      console.log(err);
      closeEditBundleProduct();
    },
    onSuccess: (res) => {
      message.success('Sửa nhóm thành công');
      closeEditBundleProduct();
      bundleProduct.refresh();
    },
  });
  const deleteBundle = useRequest(deleteBundleProduct, {
    manual: true,
    onError: (err) => {
      console.log(err);
    },
    onSuccess: (res) => {
      message.success('Xoá nhóm thành công');
      bundleProduct.refresh();
    },
  });
  const deleteBundleUpdate = useRequest(deleteBundleUpdateProduct, {
    manual: true,
    onError: (err) => {
      console.log(err);
    },
    onSuccess: (res) => {
      message.success('Xoá nhóm thành công');
      bundleProduct.refresh();
    },
  });
  const onEditBundleProduct = (val: any) => {
    if (isUpdateProduct) {
      editBundleUpdate.run(
        {
          name: val?.name,
          maxium_property: +val?.maxium_property,
        },
        idEditBundleProduct,
      );
    } else {
      editBundle.run(idEditBundleProduct, {
        name: val?.name,
        maxium_property: +val?.maxium_property,
      });
    }
  };
  const onAddBundleProduct = (val: any) => {
    if (isUpdateProduct) {
      addBunbleUpdate.run(
        {
          name: val?.name,
          maxium_property: +val?.maxium_property,
        },
        +params.id,
      );
    } else {
      addBundle.run(val?.name, +val?.maxium_property);
    }
  };
  return (
    <Drawer
      className="drawer-product"
      title={
        <div className={styles.drawerHeader}>
          <div className={styles.drawerTitle}>Nhóm sản phẩm bán kèm</div>
          <div className={styles.drawerMaximumProp}>Số lượng chọn</div>
          <div className={styles.drawerAction}>
            <Row justify="space-between">
              <Button
                type="primary"
                ghost
                onClick={() => {
                  if (selectedRowKey) {
                    onChooseBundleProduct(selectedRowKey);
                    onClose();
                  } else {
                    message.error('Vui lòng chọn nhóm sản phẩm');
                  }
                }}
              >
                Lưu
              </Button>
              <Button
                type="primary"
                ghost
                icon={<PlusOutlined />}
                onClick={() => {
                  setIsEditBundleProduct(false);
                  setIsAddBundleProduct(true);
                  form.resetFields();
                }}
              >
                Thêm mới
              </Button>
            </Row>
          </div>
          <CloseOutlined onClick={onClose} />
        </div>
      }
      placement={'bottom'}
      closable={false}
      onClose={onClose}
      open={open}
      key={'drawer'}
    >
      {isEditBundleProduct && !isAddBundleProduct && (
        <Form
          form={form}
          onFinish={onEditBundleProduct}
          style={{
            marginTop: 50,
            padding: 30,
          }}
        >
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập',
              },
            ]}
          >
            <Input placeholder="Tên sản phẩm bán kèm" />
          </Form.Item>
          <Form.Item
            name="maxium_property"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập',
              },
            ]}
          >
            <Input
              type="number"
              style={{
                width: '100%',
              }}
              placeholder="Số lượng tối đa được chọn"
            />
          </Form.Item>
          <Row
            justify="end"
            style={{
              marginTop: 30,
            }}
          >
            <Button
              type="primary"
              className="btn-submit"
              onClick={() => {
                form.submit();
              }}
            >
              Lưu
            </Button>
            <Button
              type="primary"
              danger
              className="btn-submit"
              ghost
              onClick={() => {
                setIsEditBundleProduct(false);
                form.resetFields();
              }}
            >
              Hủy
            </Button>
          </Row>
        </Form>
      )}
      {isAddBundleProduct && !isEditBundleProduct && (
        <Form
          form={form}
          onFinish={onAddBundleProduct}
          style={{
            marginTop: 50,
            padding: 30,
          }}
        >
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập',
              },
            ]}
          >
            <Input placeholder="Nhập tên nhóm sản phẩm" />
          </Form.Item>
          <Form.Item
            name="maxium_property"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập',
              },
            ]}
          >
            <Input
              type="number"
              style={{
                width: '100%',
              }}
              placeholder="Số lượng tối đa được chọn"
            />
          </Form.Item>
          <Row
            justify="end"
            style={{
              marginTop: 30,
            }}
          >
            <Button
              type="primary"
              className="btn-submit"
              onClick={() => {
                form.submit();
              }}
            >
              Thêm
            </Button>
            <Button
              type="primary"
              danger
              className="btn-submit"
              ghost
              onClick={() => {
                setIsAddBundleProduct(false);
                form.resetFields();
              }}
            >
              Hủy
            </Button>
          </Row>
        </Form>
      )}
      {!isAddBundleProduct && !isEditBundleProduct && (
        <Table
          showHeader={false}
          columns={columns}
          rowKey={(item) => item.id}
          dataSource={bundleProduct?.data}
          loading={bundleProduct.loading}
          pagination={false}
        />
      )}
    </Drawer>
  );
};

export default DrawerBundleProduct;
