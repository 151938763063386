import React, { memo, useState, useCallback, useEffect } from 'react';
import { Select, Spin, Form } from 'antd';
import axios from 'axios';
import { ENVIRONMENTS } from '@/utils/constant';
const localSearchFunc = (input: string, option: any) =>
  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
interface IProps {
  fromName: string;
  className?: string;
  onChange: any;
  loadOnMount?: boolean;
  typeSearch?: string;
  allowClear?: boolean;
  propsInput?: any;
  index?: number;
}
interface IData {
  id: number;
  name: string;
  lat: number;
  long: number;
}
var delaySearch: any;
export default (props: IProps) => {
  const {
    index,
    fromName,
    className,
    onChange,
    loadOnMount = true,
    typeSearch = 'local',
    allowClear,
    propsInput,
  } = props;
  const [dataSource, setDataSource] = useState<IData[]>();
  const [fetching, setFetching] = useState(false);
  const [search, setSearch] = useState('');

  /**
   * Handler Lấy dữ liệu
   */
  const _handleLoadData = useCallback(async () => {
    setFetching(true);
    const result: any = await axios.get(
      `${
        ENVIRONMENTS.APP_WEB_API_URL
      }/api/locations?limit=50&blacklist=false&owned_all=true${
        !!search ? `&name=${search}` : ''
      }`,
    );
    setDataSource(result?.data?.data);
    setFetching(false);
  }, [setDataSource, search]);

  /**
   * Xử lí khi chọn dữ liệu
   */

  const _handleOnChange = useCallback(
    (data: any) => {
      onChange(data);
    },
    [onchange],
  );
  /**
   * Xử lí khi tìm kiếm
   */
  const _handleSearch = useCallback(
    (input: string) => {
      clearTimeout(delaySearch);
      delaySearch = setTimeout(function () {
        setSearch(input);
      }, 600);
    },
    [setSearch],
  );

  /*
   * Event Lấy dữ liệu
   */

  useEffect(() => {
    if (loadOnMount) {
      _handleLoadData();
    }
  }, [_handleLoadData, loadOnMount, search]);
  // console.log((index && index >= 0) ? fromName : [index, fromName])
  let nameForm;
  if (typeof index == 'number') {
    nameForm = [index, fromName];
  } else {
    nameForm = fromName;
  }
  return (
    <Form.Item
      name={nameForm}
      label="Địa điểm NFT"
      className={className}
      rules={[
        {
          required: true,
          message: 'Vui lòng nhập địa điểm NFT',
        },
      ]}
    >
      <Select
        placeholder="Chọn NFT"
        allowClear={allowClear}
        style={{ width: '100%' }}
        showSearch
        labelInValue={true}
        className={className}
        filterOption={typeSearch === 'local' ? localSearchFunc : false}
        loading={fetching}
        notFoundContent={fetching ? <Spin size="small" /> : 'Không có dữ liệu'}
        onChange={_handleOnChange}
        onSearch={_handleSearch}
        {...propsInput}
        // onFocus={typeSearch === "local" ? null : _handleSearch}
      >
        {dataSource?.map((item: any) => (
          <Select.Option
            key={`${item.lat},${item.long},${item.user_name_owner.username}`}
            value={item.id}
          >
            {item.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};
