import React from 'react';
import { Col, DatePicker, Form, Image, Input, Row, Table } from 'antd';
import styles from '../index.less';

import { ColumnsType } from 'antd/lib/table';
import { formatNumber } from '@/utils/apis/common';
import { useAntdTable } from 'ahooks';
import { StatisticVoucherService } from '../service';
const StatisticVoucher = () => {
  const [form] = Form.useForm();
  const { tableProps, search, data } = useAntdTable(StatisticVoucherService, {
    form,
  });
  const columns: ColumnsType<any> = [
    {
      title: 'STT',
      dataIndex: 'stt',
    },
    {
      title: 'Thời gian sử dụng',
      dataIndex: 'created_at',
      align: 'right',
    },
    {
      title: 'Người sử dụng',
      dataIndex: 'customer_name',
    },
    {
      title: 'Mã hóa đơn',
      dataIndex: 'bill_code',
    },
    {
      title: 'Số lượng voucher',
      dataIndex: 'voucher_count',
      align: 'right',
    },
    {
      title: 'Giá trị hóa đơn',
      dataIndex: 'invoice_value',
      align: 'right',
      render: (value) => <>{formatNumber(value)}đ</>,
    },
    {
      title: 'Giá trị khuyến mại',
      dataIndex: 'discount',
      align: 'right',
      render: (value) => <>{formatNumber(value)}đ</>,
    },
    {
      title: 'Giá trị hóa đơn sau KM',
      dataIndex: 'invoice_value_after_discount',
      align: 'right',
      render: (value) => <>{formatNumber(value)}đ</>,
    },
    {
      title: 'Nguồn voucher',
      dataIndex: 'origin',
      render: (_, record: any) => (
        <div className={styles.sourceVoucher}>
          {record?.voucher_origin.map((item: string, i: number) => (
            <div>
              Voucher {i + 1}: {item}
            </div>
          ))}
        </div>
      ),
    },
    {
      title: 'Ảnh hóa đơn',
      dataIndex: 'bill_path',
      render: (value) => (
        <>
          <Image
            src={value}
            style={{
              width: '50px',
              height: '100px',
            }}
          />
        </>
      ),
    },
  ];
  const { submit } = search;
  const searchForm = (
    <div>
      <div className={styles.searchContainer}>
        <Form form={form} className={styles.searchForm}>
          <Form.Item name="searchText" className={styles.searchItem}>
            <Input.Search
              placeholder="Tìm kiếm ID/tên người sử dụng/nơi áp dụng"
              allowClear
              onSearch={submit}
            />
          </Form.Item>
          <Form.Item name="toDate" className={styles.searchItem}>
            {/* @ts-ignore */}
            <DatePicker.RangePicker
              showTime={true}
              allowClear
              format="DD/MM/YYYY HH:00"
              onChange={submit}
              className="w-100"
              picker="date"
              placeholder={['Từ ngày', 'Đến ngày']}
            />
          </Form.Item>
        </Form>
      </div>
      <div className={styles.summary}>
        <Row gutter={[16, 16]}>
          <Col span={8} xl={8} lg={12} sm={24} xs={24}>
            <span>
              Tổng giá trị hóa đơn:{' '}
              <b className="primary-color">
                {formatNumber(data?.data?.total_spending)} đ
              </b>
            </span>
          </Col>
          <Col span={8} xl={8} lg={12} sm={24} xs={24}>
            <span>
              Tổng giá trị KM:{' '}
              <b className="primary-color">
                {formatNumber(data?.data?.total_discount)} đ
              </b>
            </span>
          </Col>
          <Col span={8} xl={8} lg={24} sm={24} xs={24}>
            <span>
              Tổng giá trị hóa đơn sau KM:{' '}
              <b className="primary-color">
                {formatNumber(data?.data?.total_value)} đ
              </b>
            </span>
          </Col>
        </Row>
      </div>
    </div>
  );
  return (
    <div>
      {searchForm}
      <div className={styles.tableComponent}>
        <Table
          {...tableProps}
          columns={columns}
          scroll={{ x: 1000 }}
          rowKey={(item) => item.id}
        />
      </div>
    </div>
  );
};

export default StatisticVoucher;
