import React, { useState } from 'react';
import styles from './index.less';
import {
  Breadcrumb,
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Switch,
  Table,
  Tabs,
  Tag,
  Tooltip,
  message,
} from 'antd';
import { useAntdTable, useRequest } from 'ahooks';
import { ADMIN_CONFIRM_STATUS } from '@/utils/constant';
import {
  confirmDiscountEvent,
  detailLink,
  getListDiscountEvent,
  updateDiscountEvent,
} from './service';
import { ColumnsType } from 'antd/lib/table';
import {
  EditOutlined,
  EyeOutlined,
  OrderedListOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';
import { AdminConfirmStatus } from '@/utils/enum';
import { useHistory } from 'umi';
import StatisticVoucherEvent from './Component/StatisticVoucherEvent';
import LinkUser from './Component/linkUser';
const DiscountEvent = () => {
  const [form] = Form.useForm();
  const [openModal, setOpenModal] = useState(false);
  const [dataDetailLink, setDetailLink] = useState({});

  const { tableProps, search, refresh } = useAntdTable(getListDiscountEvent, {
    form,
  });
  const history = useHistory();
  const detailLinkUser = useRequest(detailLink, {
    manual: true,
    onSuccess: (res: any) => {
      if (res?.status !== 'CONFIRM') {
        setDetailLink(res);
        setOpenModal(true);
      } else {
        history.push('/create_discount_event');
      }
    },
    onError: (err: any) => {
      let errText = '';
      if (typeof err.data?.message == 'object') {
        errText = err.data?.message[0];
      } else {
        errText = err.data?.message;
      }
      message.error(errText);
    },
  });

  const updateEvent = useRequest(updateDiscountEvent, {
    manual: true,
    onSuccess: (res) => {
      message.success('Thành công');
      refresh();
    },
    onError: (err) => {
      message.error('Có lỗi xảy ra');
    },
  });
  const { submit } = search;
  const handleDisplayEvent = (id: number, display: boolean) => {
    return Modal.confirm({
      title: `Xác nhận ${display ? 'hiển thị' : 'ẩn'} CTKM này?`,
      okText: 'Xác nhận',
      okType: 'primary',
      cancelText: 'Huỷ',
      onOk: () => {
        updateEvent.run(
          {
            disable: !display,
          },
          id,
        );
      },
    });
  };

  const columns: ColumnsType<any> = [
    {
      title: 'STT',
      dataIndex: 'stt',
    },
    {
      title: 'Tên chương trình',
      dataIndex: 'event_name',
    },

    {
      title: 'Loại chương trình',
      dataIndex: 'type',
      render(value, record) {
        return value === 'PRODUCT_DISCOUNT' ? 'Trên sản phẩm' : 'Trên đơn hàng';
      },
    },
    {
      title: 'Ngày bắt đầu',
      dataIndex: 'start_time',
      render: (value) => <>{dayjs(value).format('DD/MM/YYYY')}</>,
    },
    {
      title: 'Ngày kết thúc',
      dataIndex: 'end_time',
      render: (value) => <>{dayjs(value).format('DD/MM/YYYY')}</>,
    },
    {
      title: 'Số lượng áp dụng',
      dataIndex: 'event_used_voucher',
      render: (value, record) => (
        <>
          {value}/{record?.limit_voucher_event}
        </>
      ),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      render: (value) => <>{renderStatus(value)}</>,
    },
    {
      title: 'Hành động',
      width: '10%',
      align: 'center',
      render: (value, record) => (
        <>
          <Row justify="space-evenly">
            <Tooltip title="Thống kê CTKM">
              <OrderedListOutlined
                style={{
                  cursor: 'pointer',
                }}
                onClick={() =>
                  history.push(`/discount_event_statistic/${record?.id}`)
                }
              />
            </Tooltip>
            <Tooltip title="Chỉnh sửa">
              <EditOutlined
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => history.push(`/discount_event/${record?.id}`)}
                className="purple-color"
              />
            </Tooltip>
            <Tooltip title="Trạng thái hiển thị">
              <div style={{ cursor: 'pointer' }}>
                <Switch
                  checked={!record?.disable}
                  onChange={(val) => handleDisplayEvent(record?.id, val)}
                />
              </div>
            </Tooltip>
          </Row>
        </>
      ),
    },
  ];
  const renderStatus = (status: AdminConfirmStatus) => {
    switch (status) {
      case AdminConfirmStatus.REQUESTED:
        return <Tag color="warning">Chờ duyệt </Tag>;
      case AdminConfirmStatus.STARTED:
        return <Tag color="success">Đang chạy</Tag>;
      case AdminConfirmStatus.FINISHED:
        return <Tag color="gray">Hết hạn</Tag>;
      case AdminConfirmStatus.NOT_YET_STARTED:
        return <Tag color="gray">Chưa bắt đầu</Tag>;
      case AdminConfirmStatus.CANCELED:
        return <Tag color="red">Đã từ chối</Tag>;
      default:
        break;
    }
  };
  const searchForm = (
    <div className={styles.searchContainer}>
      <Form form={form} className={styles.searchForm} layout="inline">
        <Form.Item name="searchText" className={styles.searchItem}>
          <Input.Search
            placeholder="Tìm kiếm theo tên chương trình"
            allowClear
            onSearch={submit}
          />
        </Form.Item>
        <Form.Item name="status" className={styles.searchItem}>
          <Select
            onChange={submit}
            allowClear
            placeholder="Trạng thái"
            options={ADMIN_CONFIRM_STATUS.map((item) => ({
              label: item.name,
              value: item.value,
            }))}
          />
        </Form.Item>
        <Form.Item name="toDate" className={styles.searchItem}>
          <DatePicker.RangePicker
            allowClear
            format="DD/MM/YYYY"
            onChange={submit}
          />
        </Form.Item>
      </Form>

      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => {
          history.push('/create_discount_event');
        }}
      >
        Tạo mới
      </Button>
    </div>
  );

  return (
    <div>
      <Breadcrumb className={styles.breadcrumb}>
        <Breadcrumb.Item>Quản lý chương trình khuyến mại</Breadcrumb.Item>
      </Breadcrumb>
      {searchForm}
      <div className={styles.tableComponent}>
        <Table
          columns={columns}
          {...tableProps}
          rowKey={(item) => item.id}
          scroll={{ x: 1000 }}
        />
      </div>
      {/* {openModal && (
        <LinkUser
          open={openModal}
          setOpen={setOpenModal}
          data={detailLinkUser.data}
        />
      )} */}
    </div>
  );
};

export default DiscountEvent;
