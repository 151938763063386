import { useAntdTable } from 'ahooks';
import React, { useState } from 'react';
import { getListProduct } from '../service';
import { Checkbox, Form, Image, Input, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { BlankImage, ENVIRONMENTS } from '@/utils/constant';
import { formatNumber } from '@/utils/apis/common';
import { TableRowSelection } from 'antd/es/table/interface';
import styles from '../index.less';
const ListProduct = (props: {
  selectedProductId: number[];
  setSelectedProductId: React.Dispatch<React.SetStateAction<number[]>>;
}) => {
  const { selectedProductId, setSelectedProductId } = props;
  const [form] = Form.useForm();
  const { tableProps, search } = useAntdTable(getListProduct, {
    form,
    defaultParams: [
      {
        current: 1,
        pageSize: 6,
      },
      {},
    ],
  });

  const { submit } = search;

  const cols: ColumnsType<any> = [
    {
      title: 'STT',
      dataIndex: 'stt',
      width: '8%',
    },
    {
      title: 'Mã sản phẩm',
      dataIndex: 'id',
      width: '10%',
    },
    {
      title: 'Tên sản phẩm',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Hình ảnh',
      dataIndex: 'images',
      width: '10%',
      render: (value: any, record: any) => (
        <>
          <Image
            src={ENVIRONMENTS.BUCKET_NAME + '/' + record?.images?.[0]?.link}
            alt="product"
            fallback={BlankImage}
            width={40}
            height={40}
          />
        </>
      ),
    },
    {
      title: 'Nhóm sản phẩm',
      dataIndex: 'category_name',
    },
    {
      title: 'Giá bán',
      dataIndex: 'price',
      render: (value: any, record: any) => (
        <>
          {record?.min_price && record?.max_price
            ? `${formatNumber(record?.min_price)}đ - ${formatNumber(
                record?.max_price,
              )}đ`
            : formatNumber(value)}
          đ
        </>
      ),
    },
    {
      title: 'Hành động',
      key: 'select',
      fixed: 'right',
      align: 'center',
      width: '10%',
      render: (text, record: any) => (
        <Checkbox
          type="checkbox"
          checked={selectedProductId.includes(record?.id)}
          onChange={() => {
            setSelectedProductId((prevRowKeys) => {
              const newSelectedRowKeys = [...prevRowKeys];
              const index = newSelectedRowKeys.indexOf(record.id);
              if (index >= 0) {
                newSelectedRowKeys.splice(index, 1);
              } else {
                newSelectedRowKeys.push(record.id);
              }
              return newSelectedRowKeys;
            });
          }}
        />
      ),
    },
  ];
  const searchForm = (
    <Form form={form} className={styles.searchForm} layout="inline">
      <Form.Item name="search" className={styles.searchItem}>
        <Input.Search
          allowClear
          onSearch={submit}
          placeholder="Tra cứu theo tên SP, mã SP"
        />
      </Form.Item>
    </Form>
  );
  return (
    <div>
      {searchForm}
      <div
        style={{
          marginTop: 15,
        }}
      >
        <Table
          columns={cols}
          {...tableProps}
          rowKey={'id'}
          scroll={{ x: 1000 }}
        />
      </div>
    </div>
  );
};

export default ListProduct;
