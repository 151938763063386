import React from 'react';
import styles from './index.less';
import { Tabs } from 'antd';
import { useHistory, useLocation } from 'umi';
import StoreInfo from './page/StoreInfo';
import BusinessInfo from './page/BusinessInfo';
import ChangePassword from './page/ChangePassword';
import LinkAccount from './page/LinkAccount';
import PinCode from './page/PinCode';
const URL_PATH = {
  STORE_INFO: '/account',
  CHANGE_PASSWORD: '/account/change-password',
  PIN_CODE: '/account/pincode',
  BUSINESS_INFO: '/account/business-info',
  LINK_ACCOUNT: '/account/link-account',
};
const StoreAccount = () => {
  const location = useLocation();
  const history = useHistory();

  const renderTab = () => {
    switch (location.pathname) {
      case URL_PATH.STORE_INFO:
        return <StoreInfo />;
      case URL_PATH.BUSINESS_INFO:
        return <BusinessInfo />;
      case URL_PATH.CHANGE_PASSWORD:
        return <ChangePassword />;
      case URL_PATH.LINK_ACCOUNT:
        return <LinkAccount />;
      case URL_PATH.PIN_CODE:
        return <PinCode />;
    }
  };
  return (
    <div>
      <Tabs
        onChange={(key) => {
          history.push(key);
        }}
        className={styles.tabs}
        defaultActiveKey={location.pathname}
      >
        <Tabs.TabPane
          tab="Thông tin cửa hàng"
          key={URL_PATH.STORE_INFO}
        ></Tabs.TabPane>
        <Tabs.TabPane
          tab="Thông tin pháp nhân"
          key={URL_PATH.BUSINESS_INFO}
        ></Tabs.TabPane>
        <Tabs.TabPane
          tab="Mật khẩu"
          key={URL_PATH.CHANGE_PASSWORD}
        ></Tabs.TabPane>
        <Tabs.TabPane tab="Mã PIN" key={URL_PATH.PIN_CODE}></Tabs.TabPane>
        <Tabs.TabPane
          tab="Liên kết tài khoản"
          key={URL_PATH.LINK_ACCOUNT}
        ></Tabs.TabPane>
      </Tabs>
      {renderTab()}
    </div>
  );
};

export default StoreAccount;
