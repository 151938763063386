import { API_PATH, privateRequest, request } from '@/utils/apis';
import { useRequest } from 'ahooks';
import { omitBy, isNil } from 'lodash';
import moment from 'moment';
export interface IPuzzleGift {
  id?: number;
  name: string;
  image: string;
  longitude: number;
  latitude: number;
  id_nft?: number;
  address?: string;
  address_type: string;
}
export interface IUserReceiveGift {
  id?: number;
  _id: string;
  user_id: number;
  full_name: string;
}

export interface ICreateGift {
  id?: number;
  name?: string;
  type?: string;
  limit_time?: number;
  limit?: number;
  locabonus?: number;
  time_end?: string;
  image?: string;
  status: string;
  gift_status?: string;
  set_all_user?: boolean;
  address_type?: string;
  puzzle_gifts: IPuzzleGift[];
  user_receive_gift?: IUserReceiveGift[];
  type_address_receive: 'home' | 'detail';
  address_detail: string;
  address_latitude: number;
  address_longitude: number;
  url_detail_gift: string;
  url_receive_gift: string;
  qr_require?: boolean;
  transferable?: boolean;
  url_cropping_image?: string;
  receive_item?: string;
  conditions_receiving?: string;
  steps_exchange?: string;
  event_id?: number;
}

export interface EventGiftInput {
  name: string;
  organization_location?: string;
  organizational_units?: string;
  notes?: string;
  image?: string;
  start_time: string;
  end_time: string;
  status: boolean;
}

export interface IColumnsGift {
  key: string;
  name: string;
  age: number;
  address: string;
  tags: string[];
}
export enum Status {
  CHO_DUYET = 'CHO_DUYET',
  DUYET = 'DUYET',
  TU_CHOI = 'TU_CHOI',
}

export enum TypeGift {
  GIFT_NFT = 'GIFT_NFT',
  GIFT_COLLECTION = 'GIFT_COLLECTION',
  GIFT_LOCABONUS = 'GIFT_LOCABONUS',
  GIFT_LOCABONUS_QR_BIZ = 'GIFT_LOCABONUS_QR_BIZ',
}

export enum LocationDropGift {
  OTHER = 'OTHER',
  NFT = 'NFT',
  ALL_NFT = 'ALL_NFT',
}

export const OptionStatus = [
  {
    value: Status.CHO_DUYET,
    label: 'Chờ duyệt',
    color: 'warning',
  },
  {
    value: Status.DUYET,
    label: 'Đã duyệt',
    color: 'green',
  },
  {
    value: Status.TU_CHOI,
    label: 'Đã từ chối',
    color: 'red',
  },
];
export const optionGiftType = [
  {
    value: TypeGift.GIFT_COLLECTION,
    label: TypeGift.GIFT_COLLECTION,
    color: 'warning',
  },
  {
    value: TypeGift.GIFT_LOCABONUS,
    label: TypeGift.GIFT_LOCABONUS,
    color: 'green',
  },
  {
    value: TypeGift.GIFT_NFT,
    label: TypeGift.GIFT_NFT,
    color: 'volcano',
  },
  {
    value: TypeGift.GIFT_LOCABONUS_QR_BIZ,
    label: TypeGift.GIFT_LOCABONUS_QR_BIZ,
    color: 'volcano',
  },
];
export const getTableData = (
  { current, pageSize }: { current: number; pageSize: number },
  form: Object,
): Promise<any> => {
  let query = `page_index:${current},page_size:${pageSize}`;

  Object.entries(form).forEach(([key, value]) => {
    if ((value || value === 0) && key !== 'date' && key === 'status') {
      query += `,${key}:${value}`;
    }
    if ((value || value === 0) && key !== 'date' && key !== 'status') {
      query += `,${key}:"${value}"`;
    }
    if ((value || value === 0) && key === 'date') {
      query += `,start_date:"${moment(value?.[0]).format(
        'YYYY-MM-DD',
      )}",end_date:"${moment(value?.[1]).format('YYYY-MM-DD')}"`;
    }
  });
  return privateRequest(request.post, API_PATH.default, {
    data: {
      query: `{enterprise_get_list_gifts(input: {${query}}) {
          statusCode
          total
          data {
            id,
            name,
            created_at,
            type, 
            limit,
            limit_time,
            gift_status,
            received,
            time_end,
            created {
              email
              phone
            }
          }
        }}`,
    },
  }).then((res) => {
    return {
      total: res.data?.enterprise_get_list_gifts?.total,
      list: res.data?.enterprise_get_list_gifts?.data?.map(
        (item: any, i: number) => ({
          ...item,
          stt: i + 1 + (current - 1) * pageSize,
        }),
      ),
    };
  });
};

export const requestCreateGift = (payload: ICreateGift) => {
  return privateRequest(request.post, API_PATH.default, {
    data: {
      query: `
      mutation {
        enterprise_create_gift(
          body: {
            name : "${payload.name}",
            type : ${payload.type},
            limit : ${payload.limit},
            ${
              payload.type === TypeGift.GIFT_LOCABONUS ||
              payload.type === TypeGift.GIFT_LOCABONUS_QR_BIZ
                ? `locabonus :${payload.locabonus}
								url_detail_gift: "${payload.url_detail_gift}"
								`
                : `type_address_receive: ${payload.type_address_receive}
								url_detail_gift: "${payload.url_detail_gift}"
      			url_receive_gift: "https://nhanqua.locamos.vn/"
						 ${
               payload.type_address_receive === 'detail'
                 ? `
								 address_detail: "${payload.address_detail}"
								 address_latitude: ${payload.address_latitude}
						 address_longitude: ${payload.address_longitude}`
                 : ''
             }`
            }
						${
              payload.type === TypeGift.GIFT_NFT ||
              payload.type === TypeGift.GIFT_COLLECTION
                ? `receive_item: ${
                    payload.receive_item
                      ? `${JSON.stringify(payload.receive_item)}`
                      : ''
                  },
							conditions_receiving: ${
                payload.conditions_receiving
                  ? `${JSON.stringify(payload.conditions_receiving)}`
                  : ''
              },
							steps_exchange: ${
                payload.steps_exchange
                  ? `${JSON.stringify(payload.steps_exchange)}`
                  : ''
              },
							url_cropping_image: "${payload.url_cropping_image}"`
                : ''
            }
            limit_time : ${payload.limit_time},
            time_end : "${payload.time_end}",
            status : ${payload.status},
						qr_require: ${payload.qr_require ? true : false},
						transferable: ${payload.transferable ? true : false},
						${!!payload.event_id ? `event_id: ${payload.event_id},` : ''}
            image : "${payload.image}",
						set_all_user : ${payload.set_all_user},
            puzzle_gifts : [${
              payload.puzzle_gifts
                ? payload.puzzle_gifts?.map((item: IPuzzleGift) => {
                    return `{
                      ${
                        payload.type !== TypeGift.GIFT_NFT
                          ? `id_nft: ${item.id_nft},
													address: "${item.address}",
													name: "${item.name}",
													image: "${item.image}",
													address_type : ${item.address_type},
													${
                            item.address_type !== LocationDropGift.ALL_NFT
                              ? `latitude: ${item.latitude},
													longitude: ${item.longitude},`
                              : ''
                          }`
                          : `name: "${item.name}",
													image: "${item.image}",
													address_type : ${LocationDropGift.ALL_NFT},
													`
                      }
                     
                    },`;
                  })
                : ''
            }],

						user_receive_gift: [${
              payload.user_receive_gift &&
              payload.user_receive_gift?.map((item: IUserReceiveGift) => {
                return `{
												_id: "${item._id}",
												user_id: ${item.user_id},
												full_name: "${item.full_name}",
										},`;
              })
            }]
          }
        ) {
					message,
          statusCode,
          data{
            name
          }
        }
      }
      `,
    },
  });
};

export const useGetListUserGetGift = () => {
  const { data, loading, run } = useRequest(
    (name: string) => {
      return privateRequest(request.post, API_PATH.default, {
        data: {
          query: `{enterprise_gift_list_user(input: {name : "${name}"}) {
            statusCode
            data {
              _id
              user_id
              full_name
            }
          }}`,
        },
      });
    },
    {
      manual: true,
    },
  );
  return {
    listUser: data?.data?.gift_list_user?.data,
    loading,
    run,
  };
};
export const requestUpdateGift = (payload: ICreateGift, id: number) => {
  return privateRequest(request.post, API_PATH.default, {
    data: {
      query: `
      mutation {
        enterprise_update_gift(
          id : ${id}
          body: ${Autogenerated(payload)}
        ) {
          statusCode,
          data{
            name
          }
        }
      }
      `,
    },
  });
};
export const useGetGiftDetail = (id: number) => {
  const { data, loading } = useRequest(() => {
    return privateRequest(request.post, API_PATH.default, {
      data: {
        query: `{enterprise_get_gift(id : ${id}) {
            statusCode
            data {
              name,
              id, 
              set_all_user,
              locabonus,
              status,
              image,
              type,
              limit_time, 
              limit,
              time_end,
              nft_name,
							type_address_receive
							url_detail_gift
							url_receive_gift
							address_latitude
							address_longitude
							address_detail
              image,
              nft_id,
              gift_status,
              refusal_reason,
              business_id,
              business_name,
              business_mobile,
							qr_require,
							receive_item 
							conditions_receiving 
							steps_exchange 
							url_cropping_image
							transferable
							event_id
              puzzle_gifts{
                id,
                id_nft,
                address,
                longitude,
                latitude,
                image,
                name,
								address_type
              }, 
              user_receive_gift{
                id
                _id
                user_id
                full_name
              },
              created{
                email
                phone
              }
            }
          }}`,
      },
    });
  });
  return {
    UserDetail: data?.data?.enterprise_get_gift?.data,
    loading,
  };
};

export const requestChangeStatusGift = (
  id: number,
  status: string,
  refusal_reason: string,
) => {
  return privateRequest(request.post, API_PATH.default, {
    data: {
      query: `
      mutation {
        change_gift_status(id: ${id}, status: ${status}, refusal_reason: "${refusal_reason}") {
          statusCode
          message
        }
      }
      `,
    },
  });
};
const onConvertType = (item: any) => {
  const keyStr = `.type.status.type_address_receive.address_type.limit_time.address_latitude.address_longitude.limit.locabonus.`;
  const [key, value] = item;
  if (
    key === 'receive_item' ||
    key === 'conditions_receiving' ||
    key === 'steps_exchange'
  ) {
    return `${key}: ${JSON.stringify(value)}`;
  }
  if (
    typeof value == 'number' ||
    keyStr.indexOf(`.${key}.`) >= 0 ||
    typeof value == 'boolean'
  ) {
    return `
    ${key}: ${value}`;
  } else {
    return `
    ${key}: "${value}"`;
  }
};
const onConvert = (data: any) => {
  return `
    ${Object.entries(data).map((i) => {
      return onConvertType(i);
    })}
  `;
};
export const Autogenerated = (data: ICreateGift) => {
  let { puzzle_gifts, user_receive_gift = [], ...bodyGif } = data;
  delete bodyGif.quantity;
  let omitGift = omitBy(bodyGif, isNil);
  // console.log(onConvert(bodyGif))
  const strQuey = `{
    puzzle_gifts: [
    ${puzzle_gifts.map((puzzle) => {
      let omitPuzzle = omitBy(puzzle, isNil);
      return `{${onConvert(omitPuzzle)}}`;
    })}
    ],
    user_receive_gift: [
      ${user_receive_gift.map((userGift) => {
        let omitUserGift = omitBy(userGift, isNil);
        return `{${onConvert(omitUserGift)}}`;
      })}
    ],${onConvert(omitGift)}}`;
  console.log(strQuey);
  return strQuey;
};

export const getEventGift = (
  { current, pageSize }: { current: number; pageSize: number },
  formData: {
    search_text: string;
    toDate: string[];
  },
) => {
  const params: {
    page: number;
    limit: number;
    text_search?: string;
    start_time?: string;
    end_time?: string;
  } = {
    page: current,
    limit: pageSize,
  };
  if (formData.search_text) {
    params.text_search = formData.search_text;
  }
  if (Array.isArray(formData.toDate)) {
    params.start_time = moment(formData.toDate[0]).format('YYYY-MM-DD');
    params.end_time = moment(formData.toDate[1]).format('YYYY-MM-DD');
  }
  return privateRequest(request.get, API_PATH.EVENT_GIFT, { params }).then(
    (response) => {
      return {
        list: response.data,
        total: response.total,
      };
    },
  );
};
export const createEventGift = (input: EventGiftInput) => {
  return privateRequest(request.post, API_PATH.EVENT_GIFT, {
    data: input,
  });
};

export const detailEventGift = (id: number) => {
  return privateRequest(request.get, API_PATH.DETAIL_EVENT_GIFT(id));
};

export const updateEventGift = (id: number, input: EventGiftInput) => {
  return privateRequest(request.put, API_PATH.DETAIL_EVENT_GIFT(id), {
    data: input,
  });
};

export const getAllEventGift = (searchText?: string) => {
  const params: { page: number; limit: number; text_search?: string } = {
    page: 1,
    limit: 50,
  };
  if (!!searchText) {
    params.text_search = searchText;
  }
  return privateRequest(request.get, API_PATH.EVENT_GIFT, { params });
};

export const getListReceivedGift = (
  { current, pageSize, id }: { current: number; pageSize: number; id: number },
  formData: {
    searchText?: string;
    toDate?: string[];
  },
) => {
  if (!formData.searchText) {
    formData.searchText = '';
  }
  const fromDate = Array.isArray(formData.toDate)
    ? moment(formData.toDate[0]).format('YYYY-MM-DD')
    : '';
  const toDate = Array.isArray(formData.toDate)
    ? moment(formData.toDate[1]).format('YYYY-MM-DD')
    : '';
  const data = {
    query: `{
			list_received_gift_user(input: { id: ${id}, page: ${current}, limit: ${pageSize}, search_text: "${formData.searchText}", end_date: "${toDate}", start_date: "${fromDate}" }) {
				total
				data {
					user_id
					full_name
					status_receive
					time_pick_up
				}
				gift_info {
					id
					name
				}
			}
		}`,
  };
  return privateRequest(request.post, API_PATH.default, { data }).then(
    (res) => {
      return {
        list: res?.data?.list_received_gift_user?.data?.map(
          (item: any, i: number) => ({
            ...item,
            stt: (current - 1) * pageSize + i + 1,
          }),
        ),
        total: res?.data?.list_received_gift_user?.total,
        gift_info: res?.data?.list_received_gift_user?.gift_info,
      };
    },
  );
};
