import React, { useState, memo, useEffect } from 'react';
import { Button, Col, Form, FormListFieldData, Input, Row } from 'antd';
import styles from './index.less';
import { CloseOutlined } from '@ant-design/icons';

import UploadImage from '@/components/UploadImage';
import { FormInstance } from 'antd/es/form/Form';

interface IProps {
  clearable: boolean;
  onClear: () => void;
  field: FormListFieldData;
  isEditPuzzle: boolean;
  disable?: boolean;
  getData: any;
  index: number;
  propsInput?: any;
  form: FormInstance<any>;
}

const FormNFT = memo((props: IProps) => {
  const {
    form,
    field,
    clearable,
    onClear,
    getData,
    index,
    isEditPuzzle,
    propsInput,
  } = props;

  const [fileList, setFileList] = useState<any[]>([]);
  useEffect(() => {
    if (getData?.puzzle_gifts?.length) {
      if (getData?.puzzle_gifts[0]?.image) {
        setFileList([
          {
            uid: `-${index}`,
            name: 'image.png',
            status: 'done',
            url: getData?.puzzle_gifts[index]?.image,
          },
        ]);
      } else {
        setFileList([]);
      }
    }
  }, [getData]);

  return (
    <div className={styles.backgroundColor}>
      <Row
        justify="center"
        style={{
          padding: '20px',
        }}
      >
        <Col span={11}>
          <Form.Item
            label="Tên mảnh ghép"
            name={[field.name, 'name']}
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập tên mảnh ghép',
              },
            ]}
          >
            <Input {...propsInput} placeholder="Tên mảnh ghép" />
          </Form.Item>
          <UploadImage
            propsUpload={propsInput}
            label="Ảnh mảnh ghép"
            fileList={fileList}
            setFileList={setFileList}
            name={[field.name, 'image']}
            compressor={true}
          />
        </Col>
      </Row>
      {clearable && isEditPuzzle == true && (
        <Button
          icon={<CloseOutlined />}
          danger
          onClick={() => {
            const quantity = form.getFieldValue('quantity');
            form.setFieldValue('quantity', quantity - 1);
            onClear();
          }}
          style={{
            background: 'whitesmoke',
            position: 'absolute',
            top: 10,
            right: 10,
          }}
        ></Button>
      )}
    </div>
  );
});

export default FormNFT;
