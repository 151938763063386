import React, { useState } from 'react';
import styles from '../index.less';
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Typography,
  UploadFile,
  message,
} from 'antd';
import { LeftOutlined, PlusOutlined } from '@ant-design/icons';
import { Link, useRequest, history } from 'umi';
import UploadImage from '@/components/UploadImage';
import { formatInputNumber } from '@/utils/apis/common';
import DrawerGroupProduct from './DrawerGroupProduct';
import {
  createProductService,
  getBundleProduct,
  getProductCategory,
  getProductProperty,
} from '../service';
import DrawerPropertyProduct from './DrawerPropertyProduct';
import ListPropertyTable from './ListPropertyTable';
import InputBundleProduct from './InputBundleProduct';
const CreateProduct = () => {
  const [fileList, setFileList] = useState([]);
  const [form] = Form.useForm();
  const [isOpenGroupProduct, setIsOpenGroupProduct] = useState(false);
  const [isOpenPropertyProduct, setIsOpenPropertyProduct] = useState(false);
  const [selectedRowKey, setSelectedRowKey] = useState(0);
  const [checkedKeysProperty, setCheckedKeysProperty] = useState<any[]>([]);
  const [dataSource, setDataSource] = useState<
    {
      key: number;
      main_prop_name: string;
      image_link: UploadFile[];
      status: boolean;
      sub_prop: {
        sub_prop_name: string;
        price: number;
        sku_code: string;
      }[];
    }[]
  >([]);
  const createProduct = useRequest(createProductService, {
    manual: true,
    onSuccess: (res: any) => {
      console.log(res);
      if (res?.status !== 200) {
        message.error(res.message);
      } else {
        message.success('Tạo sản phẩm thành công');
        history.push('/products');
      }
    },
    onError: (error: any) => {
      message.error(error?.data?.message ?? 'Có lỗi xảy ra');
    },
    formatResult: (res) => res,
  });
  const onSubmit = (val: any) => {
    const product_prop_mixed: any[] = [];
    const bundle_products: any[] = [];

    dataSource.forEach((item) => {
      item?.sub_prop?.forEach((prop) => {
        product_prop_mixed.push(
          !!prop.sub_prop_name
            ? {
                main_prop_name: item.main_prop_name,
                secondary_prop_name: prop.sub_prop_name,
                image_link: item.image_link[0]?.response?.path,
                price: +prop.price,
                sku_code: prop.sku_code,
                status: item.status ? true : false,
              }
            : {
                main_prop_name: item.main_prop_name,
                image_link: item.image_link[0]?.response?.path,
                price: +prop.price,
                sku_code: prop.sku_code,
                status: item.status ? true : false,
              },
        );
      });
    });
    if (val?.bundles_products?.length > 0) {
      val?.bundles_products?.forEach((item: any) => {
        if (Object.keys(item).some((key) => Number.isInteger(+key))) {
          Object.keys(item).forEach((key) => {
            if (Number.isInteger(+key)) {
              bundle_products.push({
                bundled_products_default_id: item?.bundled_products_default_id,
                name: item[key].name,
                price: item[key].price,
                maximum_pick: item?.maxium_pick,
                active: item?.active ? true : false,
              });
            }
          });
        } else {
          bundle_products.push({
            bundled_products_default_id: item.bundled_products_default_id,
            name: item.name,
            price: item.price,
            maximum_pick: item.maxium_pick,
            active: item.active ? true : false,
          });
        }
      });
    }
    const payload: any = {
      name: val?.name,
      sku_code: val?.sku_code,
      images: val?.images?.map((item: any) => item?.response?.path),
      category_id: val?.category_id,
      description: val?.description,
      unit: val?.unit,
      price: val?.price,
      property_main_id: checkedKeysProperty[0],
      property_secondary_id: checkedKeysProperty[1],
      product_prop_mixed: product_prop_mixed,
      bundle_products: bundle_products,
    };
    createProduct.run(payload);
  };
  const category = useRequest(getProductCategory);
  const property = useRequest(getProductProperty);
  const bundleProducts = useRequest(getBundleProduct);
  const onChooseCategory = (id: number) => {
    form.setFieldValue('category_id', id);
  };

  return (
    <>
      <Link to="/products" className={styles.previousEditLink}>
        <LeftOutlined />
        <p>Danh sách sản phẩm</p>
      </Link>
      <div className={styles.wrapper}>
        <Form
          form={form}
          labelCol={{
            span: 5,
          }}
          labelAlign="left"
          labelWrap
          onFinish={onSubmit}
        >
          <Row
            justify="end"
            style={{
              marginBottom: 20,
            }}
          >
            <Button
              type="primary"
              className="btn-submit"
              htmlType="submit"
              loading={createProduct.loading}
            >
              Lưu
            </Button>
            <Button
              type="primary"
              ghost
              className="btn-submit"
              onClick={() => history.push('/products')}
            >
              Huỷ
            </Button>
          </Row>
          <Form.Item
            name="name"
            label="Tên sản phẩm"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập tên sản phẩm',
                whitespace: true,
              },
            ]}
          >
            <Input placeholder="Nhập tên sản phẩm" />
          </Form.Item>
          <Form.Item
            name="sku_code"
            label="Mã SKU"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập mã SKU',
                whitespace: true,
              },
            ]}
          >
            <Input placeholder="Nhập mã SKU" />
          </Form.Item>
          <UploadImage
            name="images"
            label="Hình ảnh sản phẩm (Ảnh sản phẩm đại diện là ảnh đầu tiên)"
            setFileList={setFileList}
            fileList={fileList}
            required={true}
            amountUpload={8}
            groupUpload={true}
          />
          <Form.Item label="Nhóm sản phẩm" name="category_id">
            <Select
              placeholder={'Nhóm sản phẩm'}
              onClick={() => setIsOpenGroupProduct(true)}
              open={false}
              options={category?.data?.map((item: any) => ({
                value: item?.id,
                label: item?.name,
              }))}
            />
          </Form.Item>
          <Form.Item label="Mô tả sản phẩm" name="description">
            <Input.TextArea rows={3} placeholder={'Mô tả sản phẩm'} />
          </Form.Item>
          <Row>
            <Col span={12}>
              <Form.Item
                label="Đơn vị tính"
                name="unit"
                labelCol={{
                  span: 10,
                }}
              >
                <Input
                  placeholder={'Đơn vị tính'}
                  style={{
                    width: '80%',
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Giá bán"
                name="price"
                labelCol={{
                  span: 10,
                }}
              >
                <InputNumber
                  formatter={formatInputNumber}
                  placeholder={'Giá bán'}
                  style={{
                    width: '80%',
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label="Các lựa chọn sản phẩm">
            <Button
              type="primary"
              ghost
              icon={<PlusOutlined />}
              style={{
                border: 'none',
              }}
              onClick={() => setIsOpenPropertyProduct(true)}
            >
              Thêm lựa chọn
            </Button>
          </Form.Item>
          {checkedKeysProperty.length > 0 && (
            <ListPropertyTable
              dataSource={dataSource}
              setDataSource={setDataSource}
              flexibleCol={property.data?.filter((item: any) =>
                checkedKeysProperty.includes(item?.id),
              )}
            />
          )}
          <Form.List name="bundles_products">
            {(fields, { add, remove }) => (
              <>
                <Form.Item label="Sản phẩm bán kèm">
                  <Button
                    type="primary"
                    ghost
                    icon={<PlusOutlined />}
                    style={{
                      border: 'none',
                    }}
                    onClick={() => add()}
                  >
                    Thêm sản phẩm bán kèm
                  </Button>
                </Form.Item>
                {fields.map((field) => (
                  <InputBundleProduct
                    key={field.key}
                    field={field}
                    form={form}
                    bundleProduct={bundleProducts}
                    remove={remove}
                  />
                ))}
              </>
            )}
          </Form.List>
        </Form>

        <DrawerGroupProduct
          selectedRowKey={selectedRowKey}
          setSelectedRowKey={setSelectedRowKey}
          onChooseCategory={onChooseCategory}
          category={category}
          open={isOpenGroupProduct}
          setOpen={setIsOpenGroupProduct}
        />
        <DrawerPropertyProduct
          dataSource={dataSource}
          setDataSource={setDataSource}
          checkedKeys={checkedKeysProperty}
          setCheckedKeys={setCheckedKeysProperty}
          property={property}
          open={isOpenPropertyProduct}
          setOpen={setIsOpenPropertyProduct}
        />
      </div>
    </>
  );
};

export default CreateProduct;
