import { ENVIRONMENTS } from '@/utils/constant';
import { useAuth } from '@/utils/hooks/useAuth';
import {
  AlignLeftOutlined,
  AppstoreOutlined,
  ContainerOutlined,
  GiftOutlined,
  IdcardOutlined,
  LogoutOutlined,
  QrcodeOutlined,
  ShoppingOutlined,
  TransactionOutlined,
  UsergroupDeleteOutlined,
  UserOutlined,
  GiftFilled,
  TagsOutlined,
  SwapOutlined,
  UnorderedListOutlined,
  OrderedListOutlined,
  ShoppingCartOutlined,
} from '@ant-design/icons';
import { Avatar, Button, MenuProps } from 'antd';
import { Layout, Menu } from 'antd';
import React from 'react';
import { useIntl, useLocation } from 'umi';

import { Link } from 'umi';
import styles from './index.less';

const { Sider } = Layout;

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
): MenuItem {
  return {
    key,
    children,
    label,
    icon,
  } as MenuItem;
}

const Sidebar = ({
  children,
  collapsed,
  onToggle,
}: {
  children?: React.ReactNode | React.ReactNode[];
  collapsed: boolean;
  onToggle: () => void;
}) => {
  const { formatMessage } = useIntl();
  const location = useLocation();
  const { onLogout } = useAuth();
  // @ts-ignore
  const local = JSON.parse(
    localStorage.getItem(ENVIRONMENTS.LOCAL_STORAGE_KEY as string) as string,
  );
  const userName = local?.display_name;
  const renderLink: (link: string, title: string) => React.ReactNode = (
    link: string,
    title: string,
  ) => {
    return (
      <Link to={link} key={link}>
        {formatMessage({ id: title })}
      </Link>
    );
  };

  const items: MenuItem[] = [
    getItem(
      renderLink('/employee', 'navigation_employee'),
      '/employee',
      <IdcardOutlined />,
    ),
    getItem(
      renderLink('/locabonusHis', 'navigation_history_locaBonus'),
      '/locabonusHis',
      <TransactionOutlined />,
    ),
    getItem(
      renderLink('/customer', 'navigation_list_customer'),
      '/customer',
      <UsergroupDeleteOutlined />,
    ),
    getItem(
      renderLink('/products', 'navigation_products'),
      '/products',
      <OrderedListOutlined />,
    ),
    getItem(
      renderLink('/manage-order', 'manage_order'),
      '/manage-order',
      <ShoppingCartOutlined />,
    ),
    getItem(
      renderLink('/statistic_take_loca', 'navigation_history_take_locaBonus'),
      '/statistic_take_loca',
      <AppstoreOutlined />,
    ),
    local?.register_create_gift &&
      getItem(
        renderLink('/gift-building', 'navigation_gift'),
        '/gift-building',
        <GiftFilled />,
      ),

    getItem(
      renderLink('/package', 'navigation_package'),
      '/package',
      <ShoppingOutlined />,
    ),

    local?.register_release_voucher &&
      getItem(
        renderLink('/voucher', 'navigation_voucher'),
        '/voucher',
        <ContainerOutlined />,
      ),
    // getItem(
    //   <a target="_blank" href="https://qr-biz.dev.locamos.com/" key="qr">
    //     QR hóa đơn
    //   </a>,
    //   'qr',
    //   <QrcodeOutlined />,
    // ),

    local?.register_release_voucher &&
      getItem(
        renderLink('/discount_event', 'navigation_discount_event'),
        '/discount_event',
        <TagsOutlined />,
      ),
    getItem(
      renderLink(
        '/locavoucher_transaction',
        'navigation_locavoucher_transaction',
      ),
      '/locavoucher_transaction',
      <SwapOutlined />,
    ),
    getItem(
      renderLink('/account', 'navigation_account'),
      '/account',
      <UserOutlined />,
    ),
    // getItem(
    //   renderLink('/locabonus_request', 'navigation_locabonus_request'),
    //   '/locabonus_request',
    //   <GiftOutlined />,
    // ),
    // getItem(
    //   renderLink('/voucher', 'navigation_voucher'),
    //   '/voucher',
    //   <ContainerOutlined />,
    // ),
    // getItem(
    //   renderLink('/package', 'navigation_package'),
    //   '/package',
    //   <ShoppingOutlined />,
    // ),
    ,
  ];

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={onToggle}
      trigger={null}
      className={styles.layoutSlider}
      breakpoint="md"
      width={250}
    >
      <div className={styles.logoWrapper}>
        <AlignLeftOutlined className={styles.toggleButton} onClick={onToggle} />
        {!collapsed && (
          <img src="/assets/images/locamos-logo.png" className={styles.logo} />
        )}
      </div>
      <div className={styles.sidebarAvatar}>
        <Avatar icon={<UserOutlined />} />
        {!collapsed && <span>{userName}</span>}
      </div>
      <Menu
        defaultSelectedKeys={[location.pathname]}
        mode="inline"
        items={items}
        className={styles.backgroundPrimary}
      />

      <Button
        target="_blank"
        href="https://qr-biz.dev.locamos.com/"
        key="qr"
        type="primary"
        ghost
        style={{
          width: '60%',
          marginLeft: '20px',
          marginTop: '20px',
        }}
      >
        QR hóa đơn
      </Button>

      <div
        className={styles.logoutButton}
        onClick={() => {
          onLogout();
        }}
      >
        <LogoutOutlined size={24} />
        {!collapsed && <span>Đăng xuất</span>}
      </div>
    </Sider>
  );
};

export default Sidebar;
