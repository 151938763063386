import { Modal, Input, Button, Form } from 'antd';
import React from 'react';
import styles from './index.less';
const { TextArea } = Input;

interface ModalRefuseProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function ModalRefuse(props: ModalRefuseProps) {
  const { isOpen, setIsOpen } = props;
  const [form] = Form.useForm();

  const handleCancel = () => {
    form.resetFields();
    setIsOpen(false);
  };

  const onFinish = (value: { note: string }) => {};

  return (
    <Modal
      open={isOpen}
      centered={true}
      width={550}
      onCancel={handleCancel}
      footer={null}
      maskClosable={false}
    >
      <Form form={form} onFinish={onFinish}>
        <div style={{ marginTop: 20, marginBottom: 5 }}>
          Lý do từ chối<span style={{ color: '#F93920' }}>*</span>:
        </div>
        <Form.Item
          label={null}
          name="note"
          rules={[{ required: true, message: 'Vui lòng nhập lý do từ chối' }]}
        >
          <TextArea showCount rows={6} placeholder="Lý do từ chối..." />
        </Form.Item>
        <div className={styles.showDetail_action}>
          <Button key="back" onClick={handleCancel}>
            Hủy
          </Button>
          <Button key="submit" type="primary" htmlType="submit" danger>
            Từ chối
          </Button>
        </div>
      </Form>
    </Modal>
  );
}

export default ModalRefuse;
