import React, { useEffect, useState } from 'react';
import styles from '../../index.less';
import { Button, Form, Row, Typography } from 'antd';
import { useTranslate } from '@/utils/hooks/useTranslate';
import OtpInput from 'react18-input-otp';
const Step2 = ({
  option,
  username,
  resend,
  loading,
  onFinish,
  otp,
  setOtp,
}: {
  option: string;
  username: string;
  otp: string;
  setOtp: React.Dispatch<React.SetStateAction<string>>;
  resend: () => void;
  loading: boolean;
  onFinish: (val: any) => void;
}) => {
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds, minutes]);

  const resendOTP = () => {
    setSeconds(0);
    setMinutes(1);
    resend();
  };

  return (
    <div>
      <div className={styles.titleModal}>
        <Typography.Title level={5}>Xác thực OTP</Typography.Title>
        <p>
          Chúng tôi đã gửi mã xác thực tới số điện thoại/email đăng ký của bạn{' '}
          <b>{username}</b>. Vui lòng nhập mã OTP.
        </p>
      </div>
      <Form
        layout="vertical"
        style={{
          marginTop: 30,
        }}
        onFinish={onFinish}
      >
        <Form.Item
          name="otp"
          className={styles.otpInput}
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập OTP',
            },
            {
              pattern: /[0-9]/,
              message: 'OTP phải là số',
            },
          ]}
        >
          <OtpInput
            value={otp}
            onChange={(val: any) => setOtp(val)}
            numInputs={6}
            separator={<span>-</span>}
            className={styles.otp}
            isInputNum={true}
          />
        </Form.Item>
        {option === 'email' && (
          <div className={styles.otpContainer}>
            {seconds > 0 || minutes > 0 ? (
              <p>
                Gửi lại OTP: {minutes < 10 ? `0${minutes}` : minutes}:
                {seconds < 10 ? `0${seconds}` : seconds}
              </p>
            ) : (
              <p>Bạn không nhận được OTP?</p>
            )}
            {!(seconds > 0 || minutes > 0) && (
              <div className={styles.resendOTP} onClick={resendOTP}>
                Gửi lại OTP
              </div>
            )}
          </div>
        )}
        <Row
          justify="center"
          style={{
            marginTop: 30,
          }}
        >
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            className="btn-submit"
            disabled={otp.length !== 6}
          >
            Xác nhận
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default Step2;
