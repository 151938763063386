import { useToggle } from 'ahooks';
import { Button, Checkbox, Form, Layout, Modal, Row } from 'antd';
import React, { createContext, useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import { Helmet, history, useIntl, useLocation } from 'umi';
import { authRoutes } from '../../../config/routes/index';
import MainHeader from '../Header/main.header';
import Sidebar from '../Sidebar';
import '/src/global/styles.less';
import { ENVIRONMENTS } from '@/utils/constant';
import styles from './index.less';
import QRCode from 'react-qr-code';
const ReachableContext = createContext<string | null>(null);

const { Content } = Layout;

const App = ({ children }: any) => {
  const [collapsed, setCollapsed] = useToggle(false);

  const [modal, contextHolder] = Modal.useModal();
  const { formatMessage } = useIntl();
  const location = useLocation();

  const findTitle = () => {
    const result = authRoutes.find((r) => r.path === location.pathname);
    return result?.title || 'Locabiz';
  };
  const local = JSON.parse(
    //@ts-ignore
    localStorage.getItem(ENVIRONMENTS.LOCAL_STORAGE_KEY),
  );
  const updated_lisence = local?.updated_lisence;
  const welcome = local?.welcome;
  const [isWelcome, setIsOpenWelcome] = useState(welcome);
  const onCloseWelcome = (val: any) => {
    setIsOpenWelcome(false);
    window?.localStorage.setItem(
      ENVIRONMENTS.LOCAL_STORAGE_KEY as string,
      JSON.stringify({
        ...local,
        welcome: val?.welcome === true ? false : true,
      }),
    );
  };

  return (
    <ReachableContext.Provider value="Light">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{formatMessage({ id: findTitle() })}</title>
      </Helmet>
      <Layout>
        <Sidebar collapsed={collapsed} onToggle={() => setCollapsed.toggle()} />
        <Layout
          className="site-layout"
          style={{
            maxHeight: '100vh',
            overflowY: 'auto',
          }}
        >
          <MainHeader />
          <Content>
            <div className="site-layout-background" style={{ minHeight: 360 }}>
              {children}
            </div>
            {contextHolder}
          </Content>
        </Layout>
      </Layout>
      {/* {location.pathname !== '/update_business' && !updated_lisence && (
        <Modal
          title="Cập nhật thông tin doanh nghiệp"
          open={!updated_lisence}
          footer={null}
          closable={false}
        >
          <div
            style={{
              textAlign: 'center',
            }}
          >
            <p
              style={{
                marginBottom: '20px',
              }}
            >
              Bạn cần phải cập nhật giấy tờ thông tin doanh nghiệp
            </p>
            <Button
              type="primary"
              onClick={() => {
                history.push('/update_business');
              }}
            >
              OK
            </Button>
          </div>
        </Modal>
      )} */}
      {location.pathname !== '/update_business' && welcome && (
        <Modal
          open={isWelcome}
          title={`Chào mừng, ${local?.display_name}`}
          closable={false}
          footer={null}
        >
          <div>
            <Form layout="vertical" onFinish={onCloseWelcome}>
              <Form.Item label={formatMessage({ id: 'qr_code' })}>
                <div className={styles.qrcode_info}>
                  <QRCode
                    size={100}
                    style={{
                      height: '200px',
                      margin: 'auto',
                      width: '200px',
                    }}
                    value={`${local?.qr_code_string}`}
                    viewBox={`0 0 256 256`}
                  />
                </div>
              </Form.Item>
              {/* <a
                  style={{
                    textDecoration: 'underline',
                    color: 'Highlight',
                  }}
                  href="https://media.makeameme.org/created/nothing-absolutely-nothing-37ab272bb4.jpg"
                  target="_blank"
                >
                  Tài liệu hướng dẫn sử dụng
                </a> */}
              <Form.Item name="welcome" valuePropName="checked">
                <Checkbox defaultChecked={false}>
                  Không hiển thị popup lần sau
                </Checkbox>
              </Form.Item>
              <Row justify="center">
                <Button htmlType="submit" type="primary">
                  Đóng
                </Button>
              </Row>
            </Form>
          </div>
        </Modal>
      )}
    </ReachableContext.Provider>
  );
};

const MainLayout: React.FC = ({ children }) => {
  return <App>{children}</App>;
};

export default MainLayout;
