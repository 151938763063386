import { Button, Form, Input, Row, Statistic } from 'antd';

import { useTranslate } from '@/utils/hooks/useTranslate';
import styles from '../index.less';
import { useHistory, useParams, useRequest } from 'umi';
import { useEffect, useState } from 'react';
import { resName } from '../service';

const { Countdown } = Statistic;
type Props = {
  onFinishStep5: (value: any) => void;
  currentPhone: any;
  loading: boolean;
  currentToken: any;
};

const Step5 = (props: Props) => {
  const [name, setName] = useState('');
  const history = useHistory();
  const param = new URLSearchParams(window.location.search);
  const ref_code = param.get('ref_code');

  const { t } = useTranslate();

  useEffect(() => {
    nameRequest.run(ref_code, props.currentToken);
  }, []);

  const nameRequest = useRequest(resName, {
    manual: true,
    onSuccess: (res: any) => {
      setName(res.enterprise_get_invite_name.message);
    },
  });
  const handleName = (event: any) => {
    nameRequest.run(event.target.value, props.currentToken);
  };
  return (
    <div className={styles.loginWrap}>
      <h1>Nhập mã giới thiệu</h1>
      <Form
        onFinish={props.onFinishStep5}
        initialValues={{
          invite_code: ref_code,
        }}
        layout="vertical"
      >
        <Form.Item
          label={'Mã giới thiệu'}
          name="invite_code"
          rules={[
            {
              required: true,
              message: t('error.require', {
                field: 'Mã giới thiệu',
              }),
            },
          ]}
        >
          <Input
            disabled={!!ref_code}
            placeholder={'Mã giới thiệu'}
            onBlur={handleName}
          />
        </Form.Item>
        {name && <span>Người giới thiệu: {name}</span>}
        <Row>
          <Button
            type="ghost"
            onClick={() => {
              history.push('/login');
            }}
            // loading={loading}
            className={styles.btnSubmit}
          >
            Bỏ qua
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            // loading={loading}
            className={styles.btnSubmit}
          >
            Xác nhận
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default Step5;
