import { DatePicker, Input, Table, Form, Tabs, Button, Space } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { DownOutlined, SearchOutlined, UpOutlined } from '@ant-design/icons';
import { useAntdTable } from 'ahooks';
import styles from './index.less';
import { useState } from 'react';
import Expanded from './ExpandedRowRender';
import classNames from 'classnames';
import { ORDER_STATUS, getListOrder } from '../service';
import { formatNumber } from '@/utils/apis/common';

interface DataType {
  id: string;
}
const { RangePicker } = DatePicker;
export default function ManageOrder() {
  dayjs.extend(utc);
  dayjs.extend(customParseFormat);
  const [form] = Form.useForm();
  const [activeKey, setActiveKey] = useState<ORDER_STATUS>(
    ORDER_STATUS.PENDING,
  );
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const { tableProps, search, run } = useAntdTable(getListOrder, {
    form,
    defaultParams: [
      {
        current: 1,
        pageSize: 10,
        status: ORDER_STATUS.PENDING,
      },
      {},
    ],
    onError(err) {},
  });

  const columns: ColumnsType<DataType> = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      width: '100px',
      render: (_, record, index) => <div>{index + 1}</div>,
    },
    {
      title: 'Mã đơn hàng',
      dataIndex: 'id',
      key: 'id',
      width: '150px',
    },
    {
      title: 'Thông tin khách hàng',
      dataIndex: 'user',
      render: (value: any, record: any) => (
        <>
          <p>ID: {record?.user?.id}</p>
          <p>Tên: {record?.user?.user_profile?.full_name}</p>
          <p>Số điện thoại: {record?.user?.user_profile?.full_name}</p>
          <p>Địa chỉ: {record?.user?.user_profile?.address}</p>
        </>
      ),
    },
    {
      title: 'Thời gian đặt đơn',
      dataIndex: 'create_at',
      key: 'create_at',
      render: (value: any) => <>{dayjs(value).format('HH:mm DD/MM/YYYY')}</>,
    },
    {
      title: 'Tổng giá trị đơn hàng',
      dataIndex: 'total_price',
      key: 'total_price',
      render: (value: number) => <>{formatNumber(value)} VND</>,
    },
    activeKey === ORDER_STATUS.DENIED
      ? {
          title: 'Lý do từ chối',
          dataIndex: 'reason',
          key: 'reason',
          width: '150px',
        }
      : {},
    {
      title: 'Hành Động',
      key: 'action',
      render: (_: any, record: any) => {
        const isExpanded = expandedRowKeys.includes(record.id);
        return (
          <Button type="link" onClick={() => handleExpand(record.id)}>
            {!isExpanded ? (
              <Space>
                <span>Xem chi tiết</span>
                <DownOutlined />
              </Space>
            ) : (
              <Space>
                <span>Ẩn chi tiết</span>
                <UpOutlined />
              </Space>
            )}
          </Button>
        );
      },
    },
  ];
  const handleExpand = (key: string) => {
    setExpandedRowKeys((prevState) =>
      prevState.includes(key)
        ? prevState.filter((k) => k !== key)
        : [...prevState, key],
    );
  };

  const { submit } = search;

  return (
    <div className={styles.containerManageOrder}>
      <Tabs
        onChange={(key: string) => {
          setActiveKey(key as ORDER_STATUS);
          run(
            {
              pageSize: 10,
              current: 1,
              status: key,
            },
            {},
          );
        }}
        className={styles.tabs}
      >
        <Tabs.TabPane key={ORDER_STATUS.PENDING} tab="Chờ xử lý"></Tabs.TabPane>
        <Tabs.TabPane
          key={ORDER_STATUS.VERIFIED}
          tab="Đã xác nhận"
        ></Tabs.TabPane>
        <Tabs.TabPane key={ORDER_STATUS.DENIED} tab="Từ chối"></Tabs.TabPane>
        <Tabs.TabPane key={ORDER_STATUS.CANCELED} tab="Đã huỷ"></Tabs.TabPane>
      </Tabs>
      <Form form={form} layout="inline" className={styles.searchForm}>
        <Form.Item name="search">
          <Input.Search
            allowClear
            style={{ minWidth: '250px' }}
            placeholder="Tìm kiếm theo ID, Tên khách hàng, Mã đơn hàng"
            prefix={<SearchOutlined />}
            onSearch={submit}
          />
        </Form.Item>
        <Form.Item name="toDate">
          <DatePicker.RangePicker
            format={'DD/MM/YYYY'}
            onChange={submit}
            picker="date"
            className="w-100"
            placeholder={['Từ ngày', 'Đến ngày']}
          />
        </Form.Item>
      </Form>
      <Table
        {...tableProps}
        columns={columns}
        expandedRowKeys={expandedRowKeys}
        onExpand={(_, record) => handleExpand(record?.id)}
        expandedRowRender={(rc) => <Expanded {...rc} />}
        expandIconColumnIndex={-1}
        rowKey="id"
        scroll={{ x: 1000 }}
      />
    </div>
  );
}
