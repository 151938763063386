import React, { useState } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Typography, message } from 'antd';
import styles from '../index.less';
import { useIntl, useRequest } from 'umi';
import { useTranslate } from '@/utils/hooks/useTranslate';
import { changePasswordService } from '../service';
const ChangePassword = () => {
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();
  const { t } = useTranslate();
  const changePassword = useRequest(changePasswordService, {
    manual: true,
    onSuccess: (res) => {
      message.success('Đổi mật khẩu thành công');
      form.resetFields();
    },
    onError: (err: any) => {
      let errText = '';
      if (typeof err.data?.message == 'object') {
        errText = err.data?.message[0];
      } else {
        errText = err.data?.message;
      }
      message.error(errText);
    },
  });
  const onSubmit = (val: any) => {
    changePassword.run({
      old_password: val?.old_password,
      new_password: val?.password,
    });
  };
  return (
    <div className={styles.wrapperPassword}>
      <Row>
        <Typography.Title className={styles.titleTab} level={4}>
          Đổi mật khẩu
        </Typography.Title>
      </Row>
      <Form
        form={form}
        labelCol={{
          span: 5,
        }}
        labelAlign="left"
        labelWrap
        onFinish={onSubmit}
      >
        <Row>
          <Col span={18} xxl={18} xl={18} lg={24} sm={24} xs={24}>
            <Form.Item
              label="Mật khẩu hiện tại"
              name="old_password"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập mật khẩu hiện tại',
                },
              ]}
            >
              <Input.Password placeholder="Nhập mật khẩu hiện tại" />
            </Form.Item>
            <Form.Item
              label="Mật khẩu mới"
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập mật khẩu mới',
                },
              ]}
            >
              <Input.Password placeholder="Nhập mật khẩu mới" />
            </Form.Item>
            <Form.Item
              name="re_password"
              label={formatMessage({ id: 'enter_password' })}
              dependencies={['password']}
              hasFeedback
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    if (!value && !!getFieldValue('password')) {
                      return Promise.reject(
                        new Error('Vui lòng lại nhập mật khẩu mới'),
                      );
                    }
                    return Promise.reject(new Error('Mật khẩu không khớp'));
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Nhập lại mật khẩu" />
            </Form.Item>
          </Col>
        </Row>
        <Row
          justify="center"
          style={{
            margin: '20px 0',
          }}
        >
          <Button
            type="primary"
            ghost
            danger
            className="btn-submit"
            onClick={() => {
              form.resetFields();
            }}
          >
            Huỷ
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            className="btn-submit"
            loading={changePassword.loading}
          >
            Lưu
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default ChangePassword;
