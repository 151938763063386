import { API_PATH, privateRequest, request } from '@/utils/apis';

export const buyPackageRequest = (data: any) => {
  return privateRequest(request.post, API_PATH.BUY_PACKAGE_REQUEST, {
    data,
  });
};

export const getPackageAmount = (input: {
  value: number;
  currency: string;
  pkg: string;
}) => {
  const { value, currency, pkg } = input;
  const query = `?value=${value}&currency=${currency}&pkg=${pkg}`;
  return privateRequest(request.get, API_PATH.PACKAGE_AMOUNT + query);
};
export const getCurrentPackage = () => {
  return privateRequest(request.get, API_PATH.CAN_PURCHASE);
};
