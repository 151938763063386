export const authRoutes = [
  // {
  //   exact: true,
  //   path: '/',
  //   component: '@/pages/Employee/index',
  //   title: 'navigation_employee',
  //   routes: [],
  //   wrappers: ['@/layouts/Wrapper'],
  // },
  {
    exact: true,
    path: '/employee',
    component: '@/pages/Employee/index',
    title: 'navigation_employee',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/employee_new_account',
    component: '@/pages/EmployeeNewAccount/index',
    title: 'navigation_employee',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/employee_detail/:id',
    component: '@/pages/EmployeeAccount/index',
    title: 'navigation_employee',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/employee_edit_account/:id',
    component: '@/pages/EmployeeEditAccount/index',
    title: 'navigation_employee',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/locabonusHis',
    component: '@/pages/LocaBonusHistory/index',
    title: 'navigation_history_locaBonus',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/locabonusHis',
    component: '@/pages/LocaBonusHistory/index',
    title: 'navigation_history_locaBonus',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/customer',
    component: '@/pages/Customer/index',
    title: 'navigation_list_customer',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/statistic_take_loca',
    component: '@/pages/Statistic/index',
    title: 'navigation_history_take_locaBonus',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  // {
  //   exact: true,
  //   path: '/locabonus_request',
  //   component: '@/pages/LocabonusRequest/index',
  //   title: 'navigation_locabonus_request',
  //   routes: [],
  //   wrappers: ['@/layouts/Wrapper'],
  // },
  {
    exact: true,
    path: '/update_business',
    component: '@/pages/UpdateBusiness/index',
    title: 'navigation_update_business',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/manage-order',
    component: '@/pages/ManageOrder/index',
    title: 'Quản lý đơn hàng',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/gift-building',
    component: '@/pages/Gift/index',
    title: 'Quản lý quà tặng',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/gift-events',
    component: '@/pages/Gift/index',
    title: 'Quản lý sự kiện',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/gift-events/:id',
    component: '@/pages/Gift/DetailEvent/index',
    title: 'Quản lý sự kiện',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/create-gift-events',
    component: '@/pages/Gift/CreateEvent/index',
    title: 'Tạo sự kiện',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },

  {
    exact: true,
    path: '/create-gift-building',
    component: '@/pages/Gift/CreateGift/index',
    title: 'navigation_gift',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/gift-building/:id',
    component: '@/pages/Gift/DetailGift/index',
    title: 'navigation_gift',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/list-received-gift/:id',
    component: '@/pages/Gift/ListReceivedGift/index',
    title: 'ListReceivedGift',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/voucher',
    component: '@/pages/Voucher/index',
    title: 'navigation_voucher',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/voucher/*',
    component: '@/pages/Voucher/index',
    title: 'navigation_voucher',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },

  {
    export: true,
    path: '/package',
    component: '@/layouts/Wrapper',
    title: 'navigation_package',
    routes: [
      {
        export: true,
        path: '/package',
        component: '@/pages/Package/index',
        title: 'navigation_package',
      },
      {
        path: '/package/buy_package',
        component: '@/pages/BuyPackage/index',
        wrappers: ['@/layouts/Wrapper'],
        title: 'navigation_package',
      },
    ],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    path: '/qr_code',
    component: '@/pages/QRCode/index',
    title: 'QR hóa đơn',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  // {
  //   exact: true,
  //   path: '/voucher-history/:id',
  //   component: '@/pages/VoucherHistory/index',
  //   title: 'navigation_voucher_history',
  //   routes: [],
  //   wrappers: ['@/layouts/Wrapper'],
  // },
  // {
  //   export: true,
  //   path: '/voucher',
  //   component: '@/layouts/Wrapper',
  //   title: 'navigation_voucher',
  //   routes: [
  //     {
  //       export: true,
  //       path: '/voucher',
  //       component: '@/pages/Voucher/index',
  //       title: 'navigation_voucher',
  //     },
  //     {
  //       path: '/voucher/:id',
  //       component: '@/pages/VoucherInfo/index',
  //       wrappers: ['@/layouts/Wrapper'],
  //       title: 'navigation_voucher',
  //     },
  //   ],
  //   wrappers: ['@/layouts/Wrapper'],
  // },

  //discount event
  {
    exact: true,
    path: '/discount_event',
    component: '@/pages/DiscountEvent/index',
    title: 'navigation_discount_event',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/create_discount_event',
    component: '@/pages/DiscountEvent/Component/CreateDiscountEvent',
    title: 'navigation_discount_event',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/discount_event/:id',
    component: '@/pages/DiscountEvent/Component/DetailDiscountEvent',
    title: 'navigation_discount_event',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/discount_event_statistic/:id',
    component: '@/pages/DiscountEvent/Component/StatisticDiscountEvent',
    title: 'navigation_discount_event',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  //account
  {
    exact: true,
    path: '/account',
    component: '@/pages/Account',
    title: 'navigation_account',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/account/*',
    component: '@/pages/Account',
    title: 'navigation_account',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/locavoucher_transaction',
    component: '@/pages/LocaVoucherTransaction',
    title: 'navigation_locavoucher_transaction',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  // {
  //   export: true,
  //   path: '/package',
  //   component: '@/layouts/Wrapper',
  //   title: 'navigation_package',
  //   routes: [
  //     {
  //       export: true,
  //       path: '/package',
  //       component: '@/pages/Package/index',
  //       title: 'navigation_package',
  //     },
  //     {
  //       path: '/package/buy_package',
  //       component: '@/pages/BuyPackage/index',
  //       wrappers: ['@/layouts/Wrapper'],
  //       title: 'navigation_package',
  //     },
  //   ],
  //   wrappers: ['@/layouts/Wrapper'],
  // },
  {
    exact: true,
    path: '/products',
    component: '@/pages/Product',
    title: 'navigation_products',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/create-product',
    component: '@/pages/Product/component/CreateProduct',
    title: 'navigation_products',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
  {
    exact: true,
    path: '/products/:id',
    component: '@/pages/Product/component/UpdateProduct',
    title: 'navigation_products',
    routes: [],
    wrappers: ['@/layouts/Wrapper'],
  },
];
export default [
  {
    exact: true,
    path: '/login',
    component: '@/layouts/AuthLayout/index',
    title: 'Login',
    wrappers: ['@/layouts/Wrapper'],
    routes: [
      { exact: true, path: '/login', component: '@/pages/SignIn/index' },
    ],
  },
  {
    exact: true,
    path: '/register',
    component: '@/pages/Register/index',
  },
  {
    exact: true,
    path: '/forgot-password',
    component: '@/pages/ForgotPassword/index',
    title: 'Quên mật khẩu',
  },
  {
    exact: false,
    path: '/',
    component: '@/layouts/MainLayout',
    wrappers: ['@/layouts/Wrapper'],
    routes: authRoutes,
  },
];
