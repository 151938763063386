import React from 'react';
import {
  Breadcrumb,
  Form,
  Select,
  Table,
  Input,
  Row,
  Button,
  DatePicker,
  message,
  Space,
  Tag,
  Tooltip,
} from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import { useIntl, history } from 'umi';
import styles from '../index.less';
import { useAntdTable } from 'ahooks';
import {
  Status,
  IColumnsGift,
  OptionStatus,
  getTableData,
  optionGiftType,
} from '../service';
import moment from 'moment';
import { EyeOutlined, OrderedListOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker;
const ListGift = () => {
  const [form] = Form.useForm();
  const columns: ColumnsType<IColumnsGift> = [
    {
      title: 'STT',
      width: 80,
      dataIndex: 'stt',
      key: 'stt',
      align: 'center',
      render: (_: any, record: any, index: number) => {
        return <>{index + 1}</>;
      },
    },
    {
      title: 'Thời gian tạo',
      dataIndex: 'created_at',
      key: 'created_at',
      width: 200,
      render: (values: string, record: any, index: number) => {
        return <>{moment(values).format('HH:mm:ss DD/MM/YYYY')}</>;
      },
    },
    {
      title: 'Tên quà',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Loại quà tặng',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Số lượng quà',
      key: 'limit',
      render: (record: any) => {
        return (
          <>
            {record.received}/{record.limit}
          </>
        );
      },
    },
    {
      title: 'Người tạo',
      key: 'created',
      render: (record: any) => {
        return <>{record?.created?.email}</>;
      },
    },
    {
      title: 'Thời gian hết hạn',
      dataIndex: 'time_end',
      key: 'time_end',
      width: 200,
      render: (value: string, record: any, index: number) => {
        return <>{moment(value).format('HH:mm:ss DD/MM/YYYY')}</>;
      },
    },
    {
      title: 'Trạng thái',
      dataIndex: 'gift_status',
      key: 'gift_status',
      render: (value: string) => {
        {
          const data: any = OptionStatus.filter((status) => {
            if (status.value == value) {
              return true;
            } else {
              return false;
            }
          });
          return (
            <Tag color={data[0]?.color} key={value}>
              {data[0]?.label}
            </Tag>
          );
        }
      },
    },
    {
      title: 'Hành động',
      dataIndex: 'action',
      key: 'action',
      width: '10%',
      align: 'center',
      render: (value: any, record: any, index: number) => {
        // console.log('🚀 ~ file: index.tsx:127 ~ record:', record);
        return (
          <Space
            className={styles.action}
            style={{
              justifyContent: 'center',
            }}
          >
            <Tooltip title="Xem">
              <a href={`/gift-building/${record.id}`}>
                <EyeOutlined className="purple-color" />
              </a>
            </Tooltip>
            {record?.gift_status === Status.DUYET && (
              <Tooltip title="Xem danh sách nhặt quà">
                <a href={`/list-received-gift/${record.id}`}>
                  <OrderedListOutlined />
                </a>
              </Tooltip>
            )}
          </Space>
        );
      },
    },
  ];
  const { tableProps, search, params, refresh, loading, error } = useAntdTable(
    getTableData,
    {
      form,
      onError: (error: any) => {
        message.error(
          error.errors ? error.errors[0] : formatMessage({ id: 'error' }),
        );
      },
    },
  );
  const { submit } = search;
  const { formatMessage } = useIntl();
  const onChangeDatePicker = () => {
    // const value = form.getFieldValue('date');
    // console.log(value);

    // if (value) {
    //   const startDate = moment(value?.[0]).startOf('day').toISOString();
    //   const endDate = moment(value?.[1]).endOf('day').toISOString();
    //   console.log(startDate, endDate);

    //   // form.setFieldsValue({
    //   //   date: [startDate, endDate],
    //   // });
    // } else {
    //   form.setFieldValue('start_date', '');
    //   form.setFieldValue('end_date', '');
    // }

    submit();
  };
  return (
    <>
      <div className={styles.searchContainer}>
        <Form
          form={form}
          autoComplete="off"
          className={styles.searchForm}
          layout="inline"
        >
          <Form.Item name="search_text" className={styles.searchItem}>
            <Input.Search
              placeholder="Tìm kiếm tên quà tặng, tên building"
              allowClear
              onSearch={submit}
            />
          </Form.Item>
          <Form.Item name="date" className={styles.searchItem}>
            {/* @ts-ignore */}
            <RangePicker
              onChange={submit}
              format={['DD/MM/YYYY', 'DD/MM/YY']}
            />
          </Form.Item>
          <Form.Item name="status" className={styles.searchItem}>
            <Select
              placeholder="Trạng thái"
              allowClear
              options={OptionStatus}
              onChange={submit}
            />
          </Form.Item>
          <Form.Item name="gift_type" className={styles.searchItem}>
            <Select
              placeholder="Loại quà"
              allowClear
              options={optionGiftType}
              onChange={submit}
            />
          </Form.Item>
        </Form>
        <Button
          onClick={() => history.push('/create-gift-building')}
          className={styles.createBuilding}
        >
          Tạo quà tặng
        </Button>
      </div>
      <div className={styles.tableComponent}>
        <Table
          rowKey="id"
          columns={columns}
          locale={{ emptyText: formatMessage({ id: 'const_column_empty' }) }}
          scroll={{ x: 1000 }}
          {...tableProps}
          // {...tableProps}
        />
      </div>
    </>
  );
};

export default ListGift;
