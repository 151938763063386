import { API_PATH, privateRequest, request } from '@/utils/apis';
import moment from 'moment';

export const getListTransactionVoucher = (
  {
    current,
    pageSize,
  }: {
    current: number;
    pageSize: number;
  },
  formData: {
    searchText?: string;
    status?: string;
    toDate?: string[];
    typeAccount?: string;
  },
) => {
  const params: any = {
    page: current,
    limit: pageSize,
  };
  if (!!formData.searchText) {
    params.keyword = formData.searchText;
  }
  if (!!formData.status) {
    params.status = formData.status;
  }
  if (!!formData.typeAccount) {
    params.type_account = formData.typeAccount;
  }
  if (Array.isArray(formData.toDate)) {
    params.date_from = moment(formData.toDate[0]).format('YYYY-MM-DD');
    params.date_to = moment(formData.toDate[1]).format('YYYY-MM-DD');
  }
  return privateRequest(request.get, API_PATH.TRANSACTION_VOUCHER, {
    params,
  }).then((response) => {
    return {
      list: response.data?.map((item: any, index: number) => ({
        ...item,
        stt: (current - 1) * pageSize + index + 1,
      })),
      total: response?.total,
      data: response,
    };
  });
};
