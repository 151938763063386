import { Button, Col, Form, Input, Row, Select, Statistic } from 'antd';

import { useTranslate } from '@/utils/hooks/useTranslate';

import styles from '../index.less';
import { useHistory, useIntl } from 'umi';
import { useRequest } from 'ahooks';

import { BUSINESS_TYPE } from '@/utils/constant';

import { getDetailAccount, resName } from '../service';
import { paternPhone } from '@/utils/patern';
import MapReaflat from '@/components/MapReaflat';
import { getListOrgs } from '@/pages/UpdateBusiness/service';

const { Countdown } = Statistic;
type Props = {
  onFinishStep4: (value: any) => void;
  currentIdent: any;
  option: 'email' | 'phone';
  loading: boolean;
  value: any;
  token: string;
};

const Step4 = (props: Props) => {
  const { formatMessage } = useIntl();
  const { t } = useTranslate();
  const { token } = props;

  const history = useHistory();
  const detailStore = useRequest(getDetailAccount, {
    defaultParams: [token],
    onSuccess: (res) => {
      if (res?.data?.email) {
        form.setFieldValue('display_email', res.data?.email);
      }
      if (res?.data?.phone) {
        form.setFieldValue('display_phone', res.data?.phone);
      }
    },
  });

  const [form] = Form.useForm();

  const requestOrgs = useRequest(getListOrgs);
  const onSelectPos = (pos: any) => {
    const provinceSelected = pos?.name?.split(',').reverse()[1]?.trim();
    const province = requestOrgs.data?.data?.get_all_orgs?.find(
      (item: any) => item.name === provinceSelected,
    );

    form.setFieldValue('province_id', province?.id);

    // setPickerPosition({
    //   lat: pos?.lat,
    //   lng: pos?.long,
    //   customName: pos?.name,
    // });

    form.setFieldsValue({
      address: pos?.name,
      lat: pos?.lat,
      long: pos?.long,
    });
  };
  const nameRequest = useRequest(resName, {
    manual: true,
    debounceWait: 1000,
    // onSuccess: (res: any) => {
    //   setName(res?.enterprise_get_invite_name?.message);
    // },
  });
  const handleName = (event: any) => {
    nameRequest.run(event.target.value, token);
  };

  return (
    <div className={styles.loginWrap}>
      <h1>Thông tin thương hiệu / cửa hàng</h1>
      <Form
        form={form}
        onKeyDown={(e) => {
          if (e.key === 'Enter') e.preventDefault();
        }}
        labelWrap
        labelAlign="left"
        labelCol={{
          span: 6,
        }}
        onFinish={(val) => {
          const data = {
            ...val,
          };
          props.onFinishStep4(data);
        }}
        layout="horizontal"
      >
        <div className={styles.detailAdm}>
          <Form.Item
            label="Tên thương hiệu/cửa hàng"
            name="name"
            rules={[
              {
                required: true,
                message: t('required'),
              },
            ]}
          >
            <Input placeholder={formatMessage({ id: 'brand_name' })}></Input>
          </Form.Item>
          <Form.Item
            label={formatMessage({ id: 'email' })}
            name="display_email"
            rules={[
              {
                type: 'email',
                message: t('error.email', {
                  field: t('email'),
                }),
              },
              {
                required: true,
                message: t('error.require', {
                  field: t('email'),
                }),
              },
            ]}
          >
            <Input placeholder={formatMessage({ id: 'email' })} />
          </Form.Item>
          <Form.Item
            label={formatMessage({ id: 'phone_number' })}
            name="display_phone"
            rules={[
              {
                required: true,
                message: t('error.require', {
                  field: 'Số điện thoại',
                }),
              },
              {
                pattern:
                  props.option === 'email' ? /^.[0-9]{0,15}$/ : /^.{0,15}$/,
                message: t('error.patern', {
                  field: t('phone_number'),
                }),
              },
            ]}
          >
            <Input placeholder={formatMessage({ id: 'phone_number' })}></Input>
          </Form.Item>
          <Form.Item
            label="Địa chỉ"
            name="address"
            rules={[
              {
                required: true,
                message: 'Vui lòng chọn địa điểm trên bản đồ',
              },
            ]}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item name="province_id"></Form.Item>
          <Form.Item
            style={{
              textAlign: 'center',
            }}
          >
            <MapReaflat
              setPos={onSelectPos}
              listPosition={[]}
              loading={false}
              disable={false}
            />

            <Row
              gutter={16}
              style={{
                marginTop: '10px',
              }}
            >
              <Col span={12} className={styles.dialogFormItem}>
                <Form.Item
                  label="Lat"
                  name="lat"
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng chọn',
                    },
                  ]}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={12} className={styles.dialogFormItem}>
                <Form.Item
                  label="Lng"
                  name="long"
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng chọn',
                    },
                  ]}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item
            label={formatMessage({ id: 'business_type' })}
            name="business_type"
            rules={[
              {
                required: true,
                message: t('required'),
              },
            ]}
          >
            <Select
              placeholder={t('business_type_input')}
              options={BUSINESS_TYPE.map((item) => ({
                label: t(item.name),
                value: item.value,
              }))}
            />
          </Form.Item>
          <Form.Item label={'Mã giới thiệu (nếu có)'} name="ref_code">
            <Input placeholder={'Mã giới thiệu'} onChange={handleName} />
          </Form.Item>
          {!nameRequest.error && nameRequest.data?.data && (
            <span>
              Người giới thiệu:{' '}
              {nameRequest.data?.data?.enterprise_get_invite_name?.message}
            </span>
          )}
        </div>

        <Row
          justify="center"
          style={{
            marginTop: '20px',
          }}
        >
          <Button
            type="primary"
            ghost
            className="btn-submit"
            onClick={() => {
              localStorage.removeItem('SESSION_KEY');
              history.push('/login');
            }}
          >
            Bỏ qua
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={props.loading}
            className="btn-submit"
            // className={styles.btnSubmit}
          >
            Xác nhận
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default Step4;
