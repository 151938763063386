import {
  createDetailInviteCode,
  cunlinkInviteCode,
  detailLink,
  getDetailInviteCode,
} from '@/pages/DiscountEvent/service';
import { Button, Form, Input, Row, Tag, Typography, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useRequest } from 'ahooks';
import styles from '../index.less';
var delayTimer: any;
const LinkAccount = () => {
  const [inviteData, setInviteData] = useState<any>({});
  const [form] = Form.useForm();
  const detailLinkUser = useRequest(detailLink, {
    onSuccess: (res) => {
      form.setFieldValue('link_account', res?.link_code);
      setInviteData(res);
    },
    onError: (err: any) => {
      console.log(err?.data);
    },
  });

  const getInviteCode = useRequest(getDetailInviteCode, {
    debounceWait: 1000,
    manual: true,
    onSuccess: (res) => {
      setInviteData(res);
    },
    onError: (err: any) => {
      let errText = '';
      if (typeof err.data?.message == 'object') {
        errText = err.data?.message[0];
      } else {
        errText = err.data?.message;
      }
      setInviteData({
        ...inviteData,
        error: errText,
      });
    },
  });
  const linkUser = useRequest(createDetailInviteCode, {
    manual: true,
    onSuccess: (res) => {
      message.success('Liên kết tài khoản thành công');
      setInviteData({});
      detailLinkUser.run();
    },
    onError: (err: any) => {
      let errText = '';
      form.resetFields();
      if (typeof err.data?.message == 'object') {
        errText = err.data?.message[0];
      } else {
        errText = err.data?.message;
      }
      message.error(errText ?? 'Có lỗi xảy ra');
    },
  });
  const unlinkUser = useRequest(cunlinkInviteCode, {
    manual: true,
    onSuccess: (res) => {
      message.success('Hủy liên kết tài khoản thành công');
      setInviteData({});
      detailLinkUser.run();
    },
    onError: (err: any) => {
      let errText = '';
      if (typeof err.data?.message == 'object') {
        errText = err.data?.message[0];
      } else {
        errText = err.data?.message;
      }
      message.error(errText);
    },
  });
  const renderStatus = (status: string) => {
    switch (status) {
      case 'PENDING':
        return <Tag color="warning">Chờ xử lý</Tag>;
      case 'ENTEPRISE_CANCELS':
        return <Tag color="error">DN huỷ liên kết</Tag>;
      case 'USER_CANCELS':
        return <Tag color="error">User huỷ liên kết</Tag>;
      case 'CONFIRM':
        return <Tag color="success">Dã liên kết</Tag>;
      default:
        break;
    }
  };
  const onSubmit = (val: any) => {
    if (detailLinkUser?.data?.status === 'PENDING') {
      unlinkUser.run();
    } else {
      if (val?.link_account) {
        linkUser.run(val?.link_account);
      }
    }
  };

  function onSearch(text: string) {
    if (!!text) {
      getInviteCode.run(text);
    }
  }

  return (
    <div className={styles.wrapperLinkAccount}>
      <Row>
        <Typography.Title className={styles.titleTab} level={4}>
          Liên kết tài khoản
        </Typography.Title>
      </Row>
      <Form
        form={form}
        labelCol={{
          span: 5,
        }}
        wrapperCol={{
          span: 12,
        }}
        labelAlign="left"
        labelWrap
        onFinish={onSubmit}
        layout="vertical"
      >
        <Form.Item
          label="Liên kết tài khoản"
          name="link_account"
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập mã liên kết',
            },
            {
              validator(rule, value) {
                if (!!value) {
                  if (value?.length < 8) {
                    return Promise.reject('Mã liên kết phải đủ 8 kí tự');
                  }
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input
            placeholder="Nhập mã liên kết của tài khoản nhận voucher"
            allowClear
            disabled={
              inviteData?.status == 'CONFIRM' || inviteData?.status == 'PENDING'
                ? true
                : false
            }
            onChange={(value) => onSearch(value.target.value)}
          />
        </Form.Item>
        {inviteData?.username && <p>Họ và tên: {inviteData?.username}</p>}
        {/* {inviteData?.status === 'PENDING' && (
          <p style={{ color: '#ddae20' }}>Chờ xử lý</p>
        )} */}
        {inviteData?.status !== 'NONE' && (
          <p
            style={{
              marginBottom: '15px',
            }}
          >
            Trạng thái: {renderStatus(detailLinkUser.data?.status)}
          </p>
        )}
        {detailLinkUser.data?.status !== 'CONFIRM' && (
          <Row justify="start">
            {detailLinkUser.data?.status === 'PENDING' ? (
              <Button
                type="primary"
                ghost
                danger
                htmlType="submit"
                loading={unlinkUser.loading}
              >
                Huỷ liên kết
              </Button>
            ) : (
              <Button
                type="primary"
                ghost
                htmlType="submit"
                loading={linkUser.loading}
              >
                Liên kết
              </Button>
            )}
          </Row>
        )}
      </Form>
    </div>
  );
};

export default LinkAccount;
