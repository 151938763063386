import { Breadcrumb, Tabs } from 'antd';

import styles from './index.less';

import { useState } from 'react';
import { history, useLocation } from 'umi';
import ListGift from './ListGift';
import EventGift from './EventGift';

// const { Option } = Select;

export default () => {
  const [activeTabs, setActiveTabs] = useState(1);
  const location = useLocation();

  const renderTab = () => {
    switch (location.pathname) {
      case '/gift-building':
        return <ListGift />;
      case '/gift-events':
        return <EventGift />;
      default:
        return <></>;
    }
  };
  return (
    <>
      <Breadcrumb className={styles.breadcrumb}>
        <Breadcrumb.Item>Quản lý quà tặng</Breadcrumb.Item>
      </Breadcrumb>
      {/* {searchForm} */}
      <Tabs
        defaultActiveKey={location.pathname}
        onChange={(key) => {
          history.push(key);
        }}
        className={styles.tabs}
      >
        <Tabs.TabPane
          key="/gift-building"
          tab="Danh sách quà tặng"
        ></Tabs.TabPane>
        <Tabs.TabPane key="/gift-events" tab="Danh sách sự kiện"></Tabs.TabPane>
      </Tabs>
      {renderTab()}
    </>
  );
};
