import React from 'react';
import styles from '../../index.less';
import { Button, Form, Input, Row, Typography } from 'antd';
import { paternPinCode } from '@/utils/patern';

const Step3 = ({
  onFinishStep3,
  loading,
}: {
  onFinishStep3: (values: any) => void;
  loading: boolean;
}) => {
  return (
    <div>
      <div className={styles.titleModal}>
        <Typography.Title level={5}>Tạo mã PIN</Typography.Title>
      </div>
      <Form layout="vertical" onFinish={onFinishStep3}>
        <Form.Item
          label="Tạo mã PIN"
          name="pin_code"
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập mã PIN',
            },
            {
              pattern: paternPinCode,
              message: 'Vui lòng nhập mã PIN 4 số',
            },
          ]}
        >
          <Input placeholder="Nhập mã PIN" />
        </Form.Item>
        <Form.Item
          name="re_pincode"
          label="Nhập lại mã PIN"
          dependencies={['pin_code']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập lại mã PIN',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('pin_code') === value) {
                  return Promise.resolve();
                }
                return Promise.reject('Mã PIN không khớp');
              },
            }),
          ]}
        >
          <Input placeholder="Nhập lại mã PIN" />
        </Form.Item>
        <Row justify="center">
          <Button
            className="btn-submit"
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            Xác nhận
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default Step3;
