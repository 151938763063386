import { numberWithDots } from '@/utils/apis/common';
import { useAntdTable, useToggle } from 'ahooks';
import {
  Breadcrumb,
  Form,
  Input,
  message,
  Select,
  Skeleton,
  Table,
  Typography,
} from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import { useState } from 'react';
import { useIntl, useHistory, useRequest } from 'umi';
import styles from './index.less';
import { getTableData, switchStatusAdmin } from './service';

interface DataType {
  key: string;
  stt: number;
  full_name: string;
  phone: string;
  email: string;
  dateOfBirth: string;
  status: string;
}
export default () => {
  const [form] = Form.useForm();
  const [numberLoca, setNUmberLoca] = useState(10000);

  const { tableProps, search, params, refresh, error, loading } = useAntdTable(
    getTableData,
    {
      form,
      onError: (error: any) => {
        console.log(error);
        message.error(
          error.errors ? error.errors[0] : formatMessage({ id: 'error' }),
        );
      },
    },
  );

  const { formatMessage } = useIntl();

  const { type, changeType, submit, reset } = search;

  const columns: ColumnsType<DataType> = [
    {
      title: 'STT',
      width: 100,
      dataIndex: 'stt',
      key: 'stt',
      align: 'center',
    },
    {
      title: 'const_column_customer',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'const_column_phone_number',
      dataIndex: 'phone',
      align: 'right',
      key: 'phone',
    },
    {
      title: 'const_column_use_service',
      dataIndex: 'checkin_count',
      align: 'right',
      key: 'checkin_count',
    },
    {
      title: 'const_column_gift',
      dataIndex: 'get_point_count',
      align: 'right',
      key: 'get_point_count',
    },
    {
      title: 'const_column_point_target',
      dataIndex: 'temporary_spending',
      align: 'right',
      render: (_: number, record: any) => (
        <>{numberWithDots(record?.temporary_spending)}</>
      ),
      key: 'get_point_count',
    },
    {
      title: 'const_column_cumulative_target',
      dataIndex: 'accrual_spending',
      align: 'right',
      render: (_: number, record: any) => (
        <>{numberWithDots(record?.accrual_spending)}</>
      ),
      key: 'get_point_count',
    },
    {
      title: 'const_column_loca_received',
      dataIndex: 'total_get_point',
      align: 'right',
      key: 'total_get_point',
      render: (_: number, record: any) => (
        <>{numberWithDots(record?.total_get_point)}</>
      ),
    },
  ].map((item: any) => {
    return { ...item, title: formatMessage({ id: item.title }) };
  });
  const searchForm = (
    <div className={styles.searchContainer}>
      <Form form={form} className={styles.searchForm}>
        <Form.Item name="fullName" className={styles.searchItem}>
          <Input.Search
            placeholder={formatMessage({ id: 'form_search_text_admin' })}
            allowClear
            onSearch={submit}
          />
        </Form.Item>
      </Form>
      {/* <Typography.Text>Tổng Loca Bonus: {numberLoca}</Typography.Text> */}
    </div>
  );

  return (
    <>
      <Breadcrumb className={styles.breadcrumb}>
        <Breadcrumb.Item>
          {formatMessage({ id: 'customer_list_go_shop' })}
        </Breadcrumb.Item>
      </Breadcrumb>
      {searchForm}
      <div className={styles.tableComponent}>
        {loading || error ? (
          <Skeleton active />
        ) : (
          <Table
            {...tableProps}
            columns={columns}
            locale={{ emptyText: formatMessage({ id: 'const_column_empty' }) }}
            scroll={{ x: 1000 }}
          />
        )}
      </div>
      {/* {openDialog && (
        <Dialog
          open={openDialog}
          setOpen={(b) => {
            setOpenDialog.set(b);
            refresh();
          }}
          itemEdit={idSelected}
        />
      )} */}
    </>
  );
};
