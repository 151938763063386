import { numberWithDots } from '@/utils/apis/common';
import { BlankImage, STATUS_POINT_GIVING } from '@/utils/constant';
import { useAntdTable } from 'ahooks';
import {
  Breadcrumb,
  DatePicker,
  Form,
  Image,
  Input,
  Select,
  Skeleton,
  Table,
  Tooltip,
} from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import moment, { isMoment } from 'moment';
import React, { useState } from 'react';
import { useIntl, useHistory } from 'umi';
// import Dialog from './Components/Dialog';
import styles from './index.less';
import { getTableData, switchStatusAdmin } from './service';

const { Option } = Select;

interface Item {
  name: {
    last: string;
  };
  email: string;
  phone: string;
  gender: 'male' | 'female';
}
interface DataType {
  key: string;
  stt: number;
  full_name: string;
  phone: string;
  email: string;
  dateOfBirth: string;
  status: string;
}
export default () => {
  const { RangePicker } = DatePicker;
  const [numberLoca, setNumberLoca] = useState(0);
  const [totalUserAmt, setTotalUserAmt] = useState(0);
  const [totalEnterpriseAmt, setTotalEnterpriseAmt] = useState(0);
  const [turns, setTurns] = useState(0);
  const formatDate = 'DD/MM/YYYY';

  const [form] = Form.useForm();

  const { tableProps, search, params, refresh, error, loading } = useAntdTable(
    getTableData,
    {
      form,
      onSuccess: (data: any) => {
        setNumberLoca(data.total);
        setTotalUserAmt(data.total_customer_amt);
        setTotalEnterpriseAmt(data.total_enterprise_amt);
        setTurns(data.turns);
        console.log('🚀 ~ file: index.tsx:69 ~ data', data);
      },
    },
  );

  const { formatMessage } = useIntl();

  const { type, changeType, submit, reset } = search;

  const columns: ColumnsType<DataType> = [
    {
      title: 'STT',
      width: 100,
      dataIndex: 'stt',
      key: 'stt',
      align: 'center',
    },
    {
      title: 'const_column_create_date',
      dataIndex: 'created_at',
      align: 'right',
      key: 'created_at',
      render: (value: any, record: any, index: number) => {
        return (
          <React.Fragment key={index}>
            {record.created_at
              ? moment(record.created_at).format('HH:mm:ss DD/MM/YYYY')
              : ''}
          </React.Fragment>
        );
      },
    },
    {
      title: 'const_column_employee',
      dataIndex: 'staff_name',
      key: 'staff_name',
    },
    {
      title: 'const_column_receiver',
      dataIndex: 'receive_customer',
      key: 'receive_customer',
    },
    {
      title: 'const_column_local_user',
      dataIndex: 'customer_amt',
      align: 'right',
      key: 'customer_amt',
      render: (_: number, record: any) => (
        <>{numberWithDots(record?.customer_amt)}</>
      ),
    },
    {
      title: 'const_column_local_business',
      dataIndex: 'enterprise_amt',
      align: 'right',
      key: 'enterprise_amt',
      render: (_: number, record: any) => (
        <>{numberWithDots(record?.enterprise_amt)}</>
      ),
    },
    {
      title: 'const_column_bill',
      dataIndex: 'spending',
      align: 'right',
      render: (_: number, record: any) => (
        <>{numberWithDots(record?.spending)}</>
      ),
      key: 'spending',
    },
    {
      title: 'const_column_status',
      dataIndex: 'type',
      key: 'type',
      render: (value: any, record: any, index: number) => {
        const status = STATUS_POINT_GIVING.find((i) => i.value === record.type);
        return (
          <React.Fragment key={index}>
            {status?.name ? formatMessage({ id: status.name }) : <></>}
          </React.Fragment>
        );
      },
    },
    {
      title: 'navigation_rate',
      dataIndex: 'rating',
      align: 'right',
      key: 'rating',
    },
    {
      title: 'const_column_img_bill',
      dataIndex: 'bill_path',
      align: 'center',
      render: (t: any, r: any) => (
        <Tooltip title={'Xem hoá đơn'}>
          <Image
            src={r?.bill_path ?? BlankImage}
            alt="bill"
            style={{
              width: '60px',
              height: '60px',
            }}
            fallback={BlankImage}
          />
        </Tooltip>
      ),
    },
  ].map((item: any) => {
    return { ...item, title: formatMessage({ id: item.title }) };
  });
  const searchForm = (
    <div className={styles.searchContainer}>
      <Form form={form} className={styles.searchForm} layout="inline">
        <Form.Item className={styles.searchItem} name="name">
          <Input.Search
            placeholder="Tìm kiếm theo tên nhân viên,tên người nhận"
            allowClear
            onSearch={submit}
          />
        </Form.Item>
        <Form.Item
          name="toDate"
          // initialValue={[moment(new Date()), moment(new Date())]}
          className={styles.searchItem}
        >
          {/* @ts-ignore */}
          <RangePicker
            onChange={submit}
            format={formatDate}
            picker="date"
            className="w-100"
            placeholder={['Từ ngày', 'Đến ngày']}
          />
        </Form.Item>
        <Form.Item name="status" className={styles.searchItem}>
          <Select onChange={submit} allowClear placeholder="Trạng thái">
            {STATUS_POINT_GIVING.map((item) => (
              <Option value={item.value}>
                {formatMessage({ id: item.name })}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </div>
  );

  return (
    <>
      <div className={styles.header}>
        <Breadcrumb className={styles.breadcrumb}>
          <Breadcrumb.Item>
            {formatMessage({ id: 'navigation_history_locaBonus' })}
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className={styles.form_locabonus}>
          <div className={styles.searchItem}>
            <p>
              Lượt tặng còn lại:{' '}
              <b className="primary-color">{numberWithDots(turns)}</b>
            </p>
          </div>
          <div className={styles.searchItem}>
            <p>
              Tổng số Loca Bonus user nhận:{' '}
              <b className="primary-color">{numberWithDots(totalUserAmt)}</b>
            </p>
          </div>
          <div className={styles.searchItem}>
            <p>
              Tổng số Loca Bonus DN nhận:{' '}
              <b className="primary-color">
                {numberWithDots(totalEnterpriseAmt)}
              </b>
            </p>
          </div>
        </div>
      </div>
      {searchForm}
      <div className={styles.tableComponent}>
        {loading || error ? (
          <Skeleton active />
        ) : (
          <Table
            {...tableProps}
            columns={columns}
            locale={{ emptyText: formatMessage({ id: 'const_column_empty' }) }}
            scroll={{ x: 1000 }}
          />
        )}
      </div>
    </>
  );
};
