import React, { useEffect, useState } from 'react';

const QRCode = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    const iframe = document.getElementById('qr-biz');
    const iframeElement = iframe as HTMLIFrameElement;
    console.log(iframeElement?.contentWindow?.location?.href);

    iframeElement?.contentWindow?.addEventListener('login', () => {
      setIsLoggedIn(true);
    });

    // Điều hướng đến trang chủ của trang web đó nếu người dùng đã đăng nhập
  }, []);
  return (
    <div>
      <iframe
        id="qr-biz"
        title="QR Biz"
        style={{
          width: '100%',
          height: '80vh',
        }}
        src="https://qr-biz.dev.locamos.com/"
      />
    </div>
  );
};

export default QRCode;
