import React from 'react';
import { Breadcrumb, Tabs } from 'antd';
import { useHistory, useLocation } from 'umi';
import styles from './index.less';
import HistoryReleaseVoucher from './pages/HistoryReleaseVoucher';
import StatisticVoucher from './pages/StatisticVoucher';
import SettingVoucher from './pages/SettingVoucher';
import StatisticAlias from './pages/StatisticAlias';
const VoucherEnterprise = () => {
  const location = useLocation();
  const history = useHistory();
  const renderTab = () => {
    switch (location.pathname) {
      // case '/voucher':
      //   return <HistoryReleaseVoucher />;
      case '/voucher':
        return <StatisticVoucher />;
      // case '/voucher/setting':
      //   return <SettingVoucher />;
      case '/voucher/alias':
        return <StatisticAlias />;
      default:
        return <></>;
    }
  };
  return (
    <div>
      <Breadcrumb className={styles.breadcrumb}>
        <Breadcrumb.Item>Quản lý voucher</Breadcrumb.Item>
      </Breadcrumb>
      <Tabs
        defaultActiveKey={location.pathname}
        onChange={(key) => {
          history.push(key);
        }}
        className={styles.tabs}
      >
        {/* <Tabs.TabPane
          tab="Lịch sử phát hành Voucher"
          key="/voucher"
        ></Tabs.TabPane> */}
        <Tabs.TabPane
          tab="Thống kê sử dụng voucher"
          key="/voucher"
        ></Tabs.TabPane>
        <Tabs.TabPane
          tab="Thống kê sử dụng mã Alias"
          key="/voucher/alias"
        ></Tabs.TabPane>
        {/* <Tabs.TabPane tab="Cài đặt" key="/voucher/setting"></Tabs.TabPane> */}
      </Tabs>
      {renderTab()}
    </div>
  );
};

export default VoucherEnterprise;
