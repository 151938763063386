import React, { useState } from 'react';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Space,
  Switch,
  message,
} from 'antd';
import styles from './index.less';
import { history, useRequest } from 'umi';
import dayjs from 'dayjs';
import UploadImage from '@/components/UploadImage';
import { createEventGift } from '../service';
const CreateEvent = () => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<any[]>([]);
  const createEvent = useRequest(createEventGift, {
    manual: true,
    onSuccess: (res) => {
      message.success('Tạo sự kiện thành công');
      history.push('/gift-events');
    },
    onError: (err) => {
      message.error('Có lỗi xảy ra');
    },
  });
  const onFinish = (value: any) => {
    const payload = {
      ...value,
      end_time: dayjs(value.end_time).toISOString(),
      start_time: dayjs(value.start_time).toISOString(),
      status: value.status ?? true,
      image:
        value.image?.[0]?.response?.url + value.image?.[0]?.response?.path ??
        '',
    };

    createEvent.run(payload);
  };

  return (
    <div className={styles.createBuilding}>
      <Space
        onClick={() => history.push('/gift-events')}
        className={styles.titlePage}
        size={5}
        align="center"
      >
        <svg
          viewBox="64 64 896 896"
          focusable="false"
          data-icon="left"
          width="1em"
          height="1em"
          fill="currentColor"
          aria-hidden="true"
        >
          <path d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"></path>
        </svg>
        Tạo sự kiện
      </Space>
      <div className={styles.titleSub}>Thông tin sự kiện</div>
      <div className={styles.formInfoGeneral}>
        <Form
          onFinish={onFinish}
          form={form}
          autoComplete="off"
          className={styles.formSearch}
          layout="vertical"
        >
          <Row justify="space-between" className={styles.backgroundColor}>
            <Col span={11} lg={11} sm={11} xs={24}>
              <Form.Item
                label="Tên sự kiện"
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng nhập',
                  },
                  {
                    validator(rule, value) {
                      if (!!value) {
                        if (value.length > 100) {
                          return Promise.reject('Tối đa 100 kí tự');
                        }
                        return Promise.resolve();
                      }
                      return Promise.reject();
                    },
                  },
                ]}
              >
                <Input placeholder="Nhập tên sự kiện" />
              </Form.Item>
              <Form.Item
                label="Địa điểm tổ chức"
                name="organization_location"
                rules={[
                  {
                    validator(rule, value) {
                      if (!!value) {
                        if (value.length > 100) {
                          return Promise.reject('Tối đa 100 kí tự');
                        }
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input placeholder="Nhập địa điểm tổ chức" />
              </Form.Item>

              <Form.Item
                label="Đơn vị tổ chức"
                name="organizational_units"
                rules={[
                  {
                    validator(rule, value) {
                      if (!!value) {
                        if (value.length > 100) {
                          return Promise.reject('Tối đa 100 kí tự');
                        }
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input placeholder="Nhập đơn vị tổ chức" />
              </Form.Item>
              <UploadImage
                fileList={fileList}
                setFileList={setFileList}
                label="Ảnh sự kiện"
                name="image"
                required={false}
              />
            </Col>

            <Col span={11} lg={11} sm={11} xs={24}>
              <Row justify="space-between">
                <Col span={11}>
                  <Form.Item
                    label="Thời gian bắt đầu"
                    name="start_time"
                    rules={[
                      {
                        required: true,
                        message: 'Vui lòng chọn thời gian bắt đầu',
                      },
                    ]}
                  >
                    {/* @ts-ignore */}
                    <DatePicker
                      format="HH:mm DD/MM/YYYY"
                      showTime
                      disabledDate={(currentDate) => {
                        if (!!form.getFieldValue('end_time')) {
                          const end_time = dayjs(
                            form.getFieldValue('end_time'),
                          );
                          return (
                            currentDate > end_time || currentDate < dayjs()
                          );
                        }
                        return currentDate < dayjs();
                      }}
                      // onChange={(date, dateString) => {
                      //   setData('time_end', dayjs(dateString).format());
                      // }}
                      style={{
                        width: '100%',
                      }}
                      showNow={false}
                    />
                  </Form.Item>
                </Col>
                <Col span={11}>
                  <Form.Item
                    label="Thời gian kết thúc"
                    name="end_time"
                    rules={[
                      {
                        required: true,
                        message: 'Vui lòng chọn thời gian kết thúc',
                      },
                    ]}
                  >
                    {/* @ts-ignore */}
                    <DatePicker
                      format="HH:mm DD/MM/YYYY"
                      showTime
                      disabledDate={(currentDate) => {
                        if (!!form.getFieldValue('start_time')) {
                          const start_time = dayjs(
                            form.getFieldValue('start_time'),
                          ).add(1, 'day');
                          return currentDate < start_time;
                        }
                        return currentDate < dayjs();
                      }}
                      style={{
                        width: '100%',
                      }}
                      showNow={false}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                label="Ghi chú"
                name="note"
                rules={[
                  {
                    validator(rule, value) {
                      if (!!value) {
                        if (value.length > 200) {
                          return Promise.reject('Tối đa 200 kí tự');
                        }
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input placeholder="Nhập ghi chú" />
              </Form.Item>
              <Form.Item
                name="status"
                valuePropName="checked"
                label="Trạng thái"
              >
                <Switch defaultChecked={true} />
              </Form.Item>
            </Col>
          </Row>

          <Row justify="center" className={styles.submitGroup}>
            <Button
              className="btn-submit"
              danger
              onClick={() => history.push('/gift-events')}
            >
              Huỷ
            </Button>
            <Button
              className="btn-submit"
              type="primary"
              htmlType="submit"
              loading={createEvent.loading}
            >
              Lưu
            </Button>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default CreateEvent;
