import { Modal, Button } from 'antd';
import React from 'react';
import styles from './index.less';

interface ModalConfirmProps {
  id: string;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function ModalConfirm(props: ModalConfirmProps) {
  const { id, isOpen, setIsOpen } = props;

  const handleCancel = () => {
    setIsOpen(false);
  };

  const onFinish = () => {};

  return (
    <Modal
      open={isOpen}
      centered={true}
      width={450}
      onCancel={handleCancel}
      footer={null}
      maskClosable={false}
    >
      <div style={{ marginTop: 30, textAlign: 'center' }}>
        Bạn có chắc muốn xác nhận đơn hàng {`<${id}>`}
      </div>
      <div className={styles.showDetail_center}>
        <Button key="back" onClick={handleCancel}>
          Hủy
        </Button>
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          onClick={onFinish}
        >
          Xác nhận
        </Button>
      </div>
    </Modal>
  );
}

export default ModalConfirm;
