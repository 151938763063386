import { API_PATH, privateRequest, request } from '@/utils/apis';
import { BusinessType } from '@/utils/enum';
export interface IUpdateStoreInfo {
  name?: string;
  display_email?: string;
  display_phone?: string;
  address?: string;
  lat?: number;
  long?: number;
  province_id?: string;
  business_type?: BusinessType;
  ref_code?: string;
}
export const checkLogin = (phone: any) => {
  return request.post(API_PATH.default, {
    data: {
      query: `
            mutation {
              check_ident_to_signup(input: { ident: "${phone}" }) {
                status
              }
            }      
          `,
    },
  });
};
export const registerEnterprise = (input: any) => {
  return request.post(API_PATH.default, {
    data: {
      query: `     
            mutation {
              enterprise_signup_request(
                input: {
                  phone: "${input.phone}"
                  password: "${input.password}"
                  name: "${input.name}"
                  email: "${input.email}"
                  address: "${input.address}"
                  lat: ${input.lat}
                  long: ${input.long}
                  enterprise_image: "${input.enterprise_image}"
                  license_image: "${input.license_image}"
                  identity_front: "${
                    input.identity_front ? input.identity_front : ''
                  }"
                  identity_backside: "${
                    input.identity_backside ? input.identity_backside : ''
                  }"
                  passport_image: "${input.passport ? input.passport : ''}"
                  brand_name: "${input.brand_name}"
                  tax_code: "${input.tax_code}"
                  business_type: ${input.business_type}
                  scale: ${input.scale}
                  average_invoice: ${input.average_invoice}
                  bill_template: "${
                    input.bill_template ? input.bill_template : ''
                  }"
                  bank: "${input.bank}"
                  bank_account_name: "${input.bank_account_name}"
                  bank_account_number: "${input.bank_account_number}"
                  owner_name: "${input.owner_name}"
									register_create_gift: ${input.register_create_gift}
									register_pickup_gift_location: ${input.register_pickup_gift_location}
									register_release_voucher: ${input.register_release_voucher}
									qr_transfer: "${input.qr_transfer ? input.qr_transfer : ''}"
									logo: "${input.logo}"
									description: "${
                    input?.description?.includes(`\n`)
                      ? `""${input.description?.replace(/"/g, '\\"')}""`
                      : `${input.description?.replace(/"/g, '\\"')}`
                  }"
									province_id: "${input.province_id}"
                }
              ) {
                enterprise_id
                access_token
                refresh_token
                display_name
              }
            }     
          `,
    },
  });
};
export const inputRefCpde = (data: any, config: any) => {
  return request.post(API_PATH.default, {
    data: {
      query: `
            mutation {
              enterprise_invite_request(input: {
                invite_code:"${data.invite_code}"
              }) {
                message
              }
            }          
          `,
    },
    headers: {
      Authorization: `Bearer ${config}`,
    },
  });
};

export const sendOtpEmail = (email: string) => {
  const data = {
    query: `mutation {
      enterprise_get_otp(email: "${email}") {
        message
      }
    }`,
  };
  return request.post(API_PATH.default, { data });
};

export const enterpriseVerifyOtp = (payload: {
  email: string;
  otp: string;
}) => {
  const data = {
    query: `mutation {
      enterprise_verify_otp(input: { otp_code: "${payload.otp}", email: "${payload.email}" }) {
        message
				session
      }
    }`,
  };
  return request.post(API_PATH.default, { data });
};

export const resName = (code: any, config: any) => {
  return request.post(API_PATH.default, {
    data: {
      query: `  
mutation {
  enterprise_get_invite_name(input: {invite_code:"${code}"}) {
    message
  }
}

      `,
    },
    headers: {
      Authorization: `Bearer ${config}`,
    },
  });
};
export const getListCountry = () => {
  const data = {
    query: `{
			get_list_country {
				code
				calling_code
				svg
			}
		}`,
  };
  return privateRequest(request.post, API_PATH.default, { data });
};

export const getPhoneOtpVoice = (input: {
  calling_code: string;
  phone_number: string;
}) => {
  return request.post(API_PATH.PHONE_OTP_VOICE, { data: input });
};

export const verifyPhoneOTPVoice = (input: {
  phone_number: string;
  otpCode: string;
}) => {
  const data = {
    query: `mutation {
			verify_phone_by_otp(input: { phone_number: "${input.phone_number}", otpCode: "${input.otpCode}", is_biz: true }) {
				status
				message
				session
			}
		}
		`,
  };
  return privateRequest(request.post, API_PATH.default, { data });
};

export const registerStore = (input: { password: string; session: string }) => {
  return request.post(API_PATH.REGISTER_STORE, { data: input });
};

export const updateStoreService = (input: IUpdateStoreInfo, token: string) => {
  return request.post(API_PATH.UPDATE_STORE, {
    data: input,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getDetailAccount = (token: string) => {
  return request.get(API_PATH.DETAIL_STORE, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
