import React, { Dispatch, SetStateAction, useState } from 'react';
import {
  Button,
  Drawer,
  Form,
  Input,
  Radio,
  Row,
  Table,
  Tooltip,
  message,
} from 'antd';
import styles from '../index.less';
import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import { useRequest } from 'umi';
import {
  addProductCategory,
  deleteProductCategory,
  editProductCategory,
} from '../service';

const DrawerGroupProduct = ({
  open,
  setOpen,
  category,
  onChooseCategory,
  selectedRowKey,
  setSelectedRowKey,
}: {
  open: boolean;
  setOpen: any;
  category: any;
  onChooseCategory: (id: number) => void;
  selectedRowKey: number;
  setSelectedRowKey: Dispatch<SetStateAction<number>>;
}) => {
  const [isEditCategory, setIsEditCategory] = useState(false);
  const [isAddCategory, setIsAddCategory] = useState(false);
  const [idEditCategory, setIdEditCategory] = useState(0);
  const [form] = Form.useForm();
  const onClose = () => {
    setOpen(false);
  };
  const handleSelect = (key: any) => {
    setSelectedRowKey(key);
  };
  const columns: ColumnsType<any> = [
    {
      width: '5%',
    },
    {
      dataIndex: 'name',
    },
    {
      width: '200px',
      dataIndex: 'value',
      render: (value, record) => (
        <>
          <Row justify="space-around">
            <Tooltip title="Chỉnh sửa">
              <EditOutlined
                className="primary-color cursor-pointer"
                onClick={() => {
                  setIsEditCategory(true);
                  setIsAddCategory(false);
                  form.setFieldValue('name', record?.name);
                  setIdEditCategory(record?.id);
                }}
              />
            </Tooltip>
            <Tooltip title="Xoá">
              <DeleteOutlined
                className="red-color cursor-pointer"
                onClick={() => deleteCategory.run(record?.id)}
              />
            </Tooltip>
            <Tooltip title="Chọn">
              <Radio
                checked={selectedRowKey === record.id}
                onChange={() => handleSelect(record.id)}
              />
            </Tooltip>
          </Row>
        </>
      ),
    },
  ];
  const closeAddCategory = () => {
    form.resetFields();
    setIsAddCategory(false);
  };
  const closeEditCategory = () => {
    form.resetFields();
    setIsEditCategory(false);
  };
  const addCategory = useRequest(addProductCategory, {
    manual: true,
    onError: (err) => {
      console.log(err);
      closeAddCategory();
    },
    onSuccess: (res) => {
      message.success('Thêm nhóm thành công');
      closeAddCategory();
      category.refresh();
    },
  });
  const editCategory = useRequest(editProductCategory, {
    manual: true,
    onError: (err) => {
      console.log(err);
      closeEditCategory();
    },
    onSuccess: (res) => {
      message.success('Sửa nhóm thành công');
      closeEditCategory();
      category.refresh();
    },
  });
  const deleteCategory = useRequest(deleteProductCategory, {
    manual: true,
    onError: (err) => {
      console.log(err);
    },
    onSuccess: (res) => {
      message.success('Xoá nhóm thành công');
      category.refresh();
    },
  });
  const onEditCategory = (val: any) => {
    console.log(val);
    editCategory.run(idEditCategory, val?.name);
  };
  const onAdminCategory = (val: any) => {
    console.log(val);
    addCategory.run(val?.name);
  };
  return (
    <Drawer
      className="drawer-product"
      title={
        <div className={styles.drawerHeader}>
          <div className={styles.drawerTitle}>Nhóm sản phẩm</div>
          <div className={styles.drawerAction}>
            <Row justify="space-between">
              <Button
                type="primary"
                ghost
                onClick={() => {
                  if (selectedRowKey) {
                    onChooseCategory(selectedRowKey);
                    onClose();
                  } else {
                    message.error('Vui lòng chọn nhóm sản phẩm');
                  }
                }}
              >
                Lưu
              </Button>
              <Button
                type="primary"
                ghost
                icon={<PlusOutlined />}
                onClick={() => {
                  setIsEditCategory(false);
                  setIsAddCategory(true);
                  form.resetFields();
                }}
              >
                Thêm mới
              </Button>
            </Row>
          </div>
          <CloseOutlined onClick={onClose} />
        </div>
      }
      placement={'bottom'}
      closable={false}
      onClose={onClose}
      open={open}
      key={'drawer'}
    >
      {isEditCategory && !isAddCategory && (
        <Form
          form={form}
          onFinish={onEditCategory}
          style={{
            marginTop: 50,
            padding: 30,
          }}
        >
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập',
              },
            ]}
          >
            <Input placeholder="Nhập tên nhóm sản phẩm" />
          </Form.Item>
          <Row
            justify="end"
            style={{
              marginTop: 30,
            }}
          >
            <Button
              type="primary"
              className="btn-submit"
              onClick={() => {
                form.submit();
              }}
            >
              Lưu
            </Button>
            <Button
              type="primary"
              danger
              className="btn-submit"
              ghost
              onClick={() => {
                setIsEditCategory(false);
                form.resetFields();
              }}
            >
              Hủy
            </Button>
          </Row>
        </Form>
      )}
      {isAddCategory && !isEditCategory && (
        <Form
          form={form}
          onFinish={onAdminCategory}
          style={{
            marginTop: 50,
            padding: 30,
          }}
        >
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập',
              },
            ]}
          >
            <Input placeholder="Nhập tên nhóm sản phẩm" />
          </Form.Item>
          <Row
            justify="end"
            style={{
              marginTop: 30,
            }}
          >
            <Button
              type="primary"
              className="btn-submit"
              onClick={() => {
                form.submit();
              }}
            >
              Thêm
            </Button>
            <Button
              type="primary"
              danger
              className="btn-submit"
              ghost
              onClick={() => {
                setIsAddCategory(false);
                form.resetFields();
              }}
            >
              Hủy
            </Button>
          </Row>
        </Form>
      )}
      {!isAddCategory && !isEditCategory && (
        <Table
          showHeader={false}
          columns={columns}
          rowKey={(item) => item.id}
          dataSource={category?.data}
          loading={category.loading}
          pagination={false}
        />
      )}
    </Drawer>
  );
};

export default DrawerGroupProduct;
