import React, { useEffect, useState } from 'react';
import {
  Breadcrumb,
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  message,
} from 'antd';
import styles from './index.less';
import { Link, useHistory, useIntl } from 'umi';
import { useRequest } from 'ahooks';
import { LeftOutlined } from '@ant-design/icons';
import {
  buyPackageRequest,
  getCurrentPackage,
  getPackageAmount,
} from './service';
import { numberWithDots } from '@/utils/apis/common';
const BuyPackage = () => {
  const { formatMessage } = useIntl();
  const [form] = Form.useForm();
  const history = useHistory();
  const [value, setValue] = useState(0);
  const [pkg, setPkg] = useState('');
  const [currency, setCurrency] = useState('');
  const buyPackage = useRequest(buyPackageRequest, {
    manual: true,
    onSuccess(res: any) {
      if (currency === 'VND') {
        console.log(res);

        window.location.href = res;
        // window.open(res, '_blank').focus();
      } else {
        message.success('Mua gói thành công');
        history.push('/package');
      }
    },
    onError: (err: any) => {
      if (err?.data?.statusCode === 429) {
        message.error('Bạn gửi quá nhiều yêu cầu. Vui lòng đợi');
      } else {
        message.error(err.data?.message || 'Có lỗi xảy ra');
      }
    },
  });

  const currentPackage = useRequest(getCurrentPackage, {});
  console.log(currentPackage.data);

  const [amount, setAmount] = useState({
    amount: 0,
    origin_amount: 0,
  });
  const packageAmount = useRequest(getPackageAmount, {
    debounceWait: 1000,
    onSuccess: (res) => {
      console.log(res);
      setAmount({
        amount: res?.amount,
        origin_amount: res?.origin_amount,
      });
    },
  });

  useEffect(() => {
    if (value && currency && pkg) {
      packageAmount.run({
        value: form.getFieldValue('value'),
        currency: currency,
        pkg: form.getFieldValue('type'),
      });
    }
  }, [value, currency, pkg]);

  const confirmBuyPackage = (val: any) => {
    return Modal.confirm({
      content: `Bạn có chắc chắn muốn thanh toán ${numberWithDots(
        amount.amount,
      )} ${currency} không?`,
      okText: formatMessage({ id: 'general_accept' }),
      cancelText: formatMessage({ id: 'general_cancel' }),
      maskClosable: true,
      onOk() {
        buyPackage.run(val);
      },
    });
  };

  const onSubmit = (val: any) => {
    confirmBuyPackage(val);
  };
  return (
    <div className={styles.wrapper}>
      <Breadcrumb className={styles.breadcrumb}>
        <Breadcrumb.Item className={styles.breacrumbItem}>
          <Link to="/package" className={styles.previousEditLink}>
            <LeftOutlined />
            <div>{formatMessage({ id: 'navigation_buy_package' })}</div>
          </Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className={styles.buyPackageWrapper}>
        <Form
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          labelAlign="left"
          onFinish={onSubmit}
        >
          <Form.Item
            name="type"
            label="Chọn gói"
            rules={[
              {
                required: true,
                message: 'Vui lòng chọn',
              },
            ]}
          >
            <Radio.Group onChange={(e) => setPkg(e.target.value)}>
              <Space direction="vertical" align="start">
                <Radio
                  value="PLATINUM"
                  disabled={!currentPackage.data?.includes('PLATINUM')}
                >
                  {formatMessage({ id: 'general_platinum_package' })}
                </Radio>
                <Radio
                  value="GOLD"
                  disabled={!currentPackage.data?.includes('GOLD')}
                >
                  {formatMessage({ id: 'general_gold_package' })}
                </Radio>
                <Radio
                  value="RUBY"
                  disabled={!currentPackage.data?.includes('RUBY')}
                >
                  {formatMessage({ id: 'general_ruby_package' })}
                </Radio>
                <Radio
                  value="DIAMOND"
                  disabled={!currentPackage.data?.includes('DIAMOND')}
                >
                  {formatMessage({ id: 'general_diamond_package' })}
                </Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="Số lượng"
            name="value"
            rules={[
              {
                required: true,
                message: formatMessage({ id: 'required' }),
              },
            ]}
          >
            <InputNumber
              min={1}
              style={{
                width: '62%',
              }}
              onChange={(val) => setValue(Number(val))}
            />
          </Form.Item>
          <Form.Item
            label="Phương thức thanh toán"
            name="buy_type"
            wrapperCol={{ span: 10 }}
            // labelCol={{
            //   span: 9,
            // }}
            rules={[
              {
                required: true,
                message: 'Vui lòng chọn',
              },
            ]}
          >
            <Select
              placeholder="Chọn phương thức thanh toán"
              onChange={(val) => {
                if (val === 'LOCABONUS') {
                  setCurrency('LCB');
                } else {
                  setCurrency('VND');
                }
              }}
              options={[
                {
                  label: 'Locabonus',
                  value: 'LOCABONUS',
                },
                {
                  label: 'VNPay',
                  value: 'VNPAY',
                  disabled: true,
                },
              ]}
            ></Select>
          </Form.Item>

          {amount.origin_amount !== 0 &&
            amount.origin_amount !== amount.amount && (
              <p className={styles.originAmount}>
                {numberWithDots(amount.origin_amount)}
              </p>
            )}
          {amount.amount !== 0 && (
            <b>{`Tổng cộng: ${numberWithDots(amount.amount)} ${currency}`}</b>
          )}

          <Row justify="center">
            <Button
              type="primary"
              htmlType="submit"
              disabled={amount.amount === 0}
              loading={buyPackage.loading}
            >
              Xác nhận
            </Button>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default BuyPackage;
