import { Modal, message } from 'antd';
import React, { useState } from 'react';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import { useRequest } from 'umi';
import {
  createPinCodeService,
  getDetailStore,
  getOTPEmailService,
  otpVerifyService,
} from '../../service';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { auth } from '@/utils/firebaseConfig';
import { sendOtpEmail } from '@/pages/Register/service';
interface ICreatePinCode {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSuccessCreatePinCode: () => void;
}

const CreatePinCode = ({
  isOpen,
  setIsOpen,
  onSuccessCreatePinCode,
}: ICreatePinCode) => {
  const [step, setStep] = useState(1);
  const [option, setOption] = useState('');
  const [loadingSendPhone, setLoadingSendPhone] = useState(false);
  const [username, setUsername] = useState('');
  const [session, setSession] = useState('');
  const detailStore = useRequest(getDetailStore, {});
  const [otp, setOtp] = useState('');
  const onCancel = () => {
    setIsOpen(false);
    setStep(1);
  };

  const getOTP = useRequest(getOTPEmailService, {
    manual: true,
    onSuccess: () => {
      setStep(2);
    },
  });
  const createPinCode = useRequest(createPinCodeService, {
    manual: true,
    onSuccess: (res) => {
      message.success('Tạo mã PIN thành công');
      setIsOpen(false);
      setStep(1);
      onSuccessCreatePinCode();
    },
    onError: (err: any) => {
      message.error(err.data.message ?? 'Có lỗi xảy ra');
      setIsOpen(false);
      setStep(1);
    },
  });
  const verifyOTP = useRequest(otpVerifyService, {
    manual: true,
    onSuccess: () => {
      message.success('Xin mời nhập mã PIN');
      setStep(3);
    },
    onError: (err: any) => {
      message.error(err.data.message ?? 'Bạn đã nhập sai OTP');
    },
  });
  const sendOTP = () => {
    //@ts-ignore
    const appVerifier = window.recaptchaVerifier;
    setLoadingSendPhone(true);
    let phoneNumber = detailStore.data?.phone;
    if (phoneNumber[0] === '0') {
      phoneNumber = '+84' + phoneNumber?.substring(1);
    }

    signInWithPhoneNumber(auth, phoneNumber, appVerifier)
      .then((confirmationResult) => {
        //@ts-ignore
        window.confirmationResult = confirmationResult;
        setSession(confirmationResult?.verificationId);
        // setRecaptchaToken(confirmationResult.recaptchaToken);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        getOTP.run(phoneNumber);
        setStep(2);
        setLoadingSendPhone(false);
      });
  };
  const generateCaptcha = () => {
    //@ts-ignore
    window.recaptchaVerifier = new RecaptchaVerifier(
      'recaptcha-container',
      {
        size: 'invisible',
        callback: (response: any) => {},
      },
      auth,
    );
    //@ts-ignore
    sendOTP();
  };
  const sentOTPPhone = () => {
    generateCaptcha();
  };
  const onFinishStep1 = (opt: string) => {
    setOption(opt);
    if (opt === 'email') {
      setUsername(detailStore.data?.email);
      getOTP.run(detailStore.data?.email);
    } else {
      setUsername(detailStore.data?.phone);
      sentOTPPhone();
    }
  };
  const onFinishStep2 = (val: any) => {
    const payload: any = {
      sending: username,
      otp: val.otp,
    };
    if (option === 'phone') {
      let phoneNumber = username;
      if (phoneNumber[0] === '0') {
        phoneNumber = '+84' + phoneNumber?.substring(1);
      }
      payload.sending = phoneNumber;
      payload.sessionInfo = session;
    }
    verifyOTP.run(payload);
  };
  const onFinishStep3 = (values: any) => {
    createPinCode.run({
      pin_code: `${values.pin_code}`,
      otp_code: otp,
      sending: username,
    });
  };

  const resend = () => {
    if (option === 'email') {
      getOTP.run(detailStore.data?.email);
    } else {
      sentOTPPhone();
    }
  };
  return (
    <Modal open={isOpen} onCancel={onCancel} footer={null} width={600}>
      <div id="recaptcha-container"></div>
      {step === 1 && (
        <Step1
          loading={option === 'email' ? getOTP.loading : loadingSendPhone}
          setStep={setStep}
          dataStore={detailStore.data}
          onFinish={onFinishStep1}
        />
      )}
      {step === 2 && (
        <Step2
          option={option}
          otp={otp}
          setOtp={setOtp}
          loading={verifyOTP.loading}
          username={username}
          resend={resend}
          onFinish={onFinishStep2}
        />
      )}
      {step === 3 && (
        <Step3 onFinishStep3={onFinishStep3} loading={createPinCode.loading} />
      )}
    </Modal>
  );
};

export default CreatePinCode;
