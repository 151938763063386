import React, { useEffect, useRef, useState } from 'react';
import { Marker, Popup, useMap, useMapEvent } from 'react-leaflet';
import * as L from 'leaflet';
import { toLowerCaseNonAccentVietnamese } from '@/utils/apis/common';

const geocoder = new google.maps.Geocoder();

const LocationMarker = ({
  defaultPostion,
  setPos,
  setNameAddress,
  nameAddress,
  setItemPopup,
  disable,
}: any) => {
  const [position, setPosition] = useState(defaultPostion);
  const [address, setAddress] = useState('');

  const pop = useRef<any>(null);
  const map = useMap();
  let showPopupTimeout: any;

  useEffect(() => {
    setPosition({ lat: defaultPostion.lat, lng: defaultPostion.lng });
    setAddress(defaultPostion.address);

    return () => {
      setPosition({ lat: null, lng: null });
      setAddress('');
    };
  }, [defaultPostion, setPosition, setAddress]);

  useEffect(() => {
    clearTimeout(showPopupTimeout);

    if (pop.current && position.lat && position.lng) {
      showPopupTimeout = setTimeout(() => {
        pop.current?.openOn(map);
      }, 200);
    }
  }, [position, pop]);

  useMapEvent('click', (e: any) => {
    setPosition(e.latlng);
    setAddress('');
    geocoder.geocode({ location: e.latlng }, function (results: any, status) {
      if (status == 'OK') {
        let nameDistrict;
        let nameProvince;
        setAddress(results[0]?.formatted_address as string);
        results[0]?.address_components.forEach((add: any) => {
          if (
            add?.types[0] === 'administrative_area_level_2' ||
            add?.types[0] === 'locality'
          ) {
            nameDistrict = toLowerCaseNonAccentVietnamese(add?.long_name);
          }
          if (add?.types[0] === 'administrative_area_level_1') {
            nameProvince = toLowerCaseNonAccentVietnamese(add?.long_name);
          }
        });
        setNameAddress({ nameDistrict, nameProvince });
      }
    });
  });

  const marker_picker = L.icon({
    iconUrl: '/public_assets/images/map-marker-picker.png',
    iconSize: [64, 70],
    iconAnchor: [32, 53],
    popupAnchor: [0, -40],
  });

  function handleSelectPos() {
    // console.log();
    setItemPopup(null);
    setPos({
      lat: position.lat,
      long: position.lng,
      name: address,
      nameDistrict: nameAddress.nameDistrict,
      nameProvince: nameAddress.nameProvince,
      id: 0,
    });
  }

  return !position.lat || !position.lng ? (
    <Marker position={position} icon={marker_picker}></Marker>
  ) : (
    <Marker position={position} icon={marker_picker}>
      <Popup className="marker-popup-custom" ref={pop}>
        <div className="marker-popup-container">
          <p>
            <span className="fw-bold">{address || ''}</span>
            <br />
            Latitude: {position.lat || '...'} <br /> Longitude:{' '}
            {position.lng || '...'}
          </p>
          {!disable && (
            <div
              className="btn-primary-buy-nft"
              onClick={() => handleSelectPos()}
            >
              Chọn
            </div>
          )}
        </div>
      </Popup>
    </Marker>
  );
};

export default LocationMarker;
