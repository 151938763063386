import { LeftOutlined, UserOutlined } from '@ant-design/icons';
import {
  Breadcrumb,
  Button,
  Col,
  Form,
  Input,
  message,
  Row,
  Select,
  Skeleton,
  Switch,
  Typography,
  UploadFile,
  UploadProps,
} from 'antd';
import React, { useEffect, useState } from 'react';
import {
  history,
  Link,
  useIntl,
  useLocation,
  useParams,
  useRequest,
} from 'umi';
import styles from './index.less';
import type { DataNode, TreeProps } from 'antd/es/tree';
import { useTranslate } from '@/utils/hooks/useTranslate';
import Dialog from './Components/Dialog';
import { useToggle } from 'ahooks';
import { getStaffAccount, getAllRole, onSubmitValue } from './service';
import { PERMISSIONS, ROLE } from '@/utils/enum';
import { RESPONSE_TYPE } from '@/utils/constant';
import { RcFile } from 'antd/lib/upload';

const { Text } = Typography;

interface IAccountInfo {
  email: string;
  staff_id?: string;
  phone: string;
  status: boolean;
  full_name: string;
  nums_of_give: number;
}

export default () => {
  const params = useParams<{ id: string }>();

  const { formatMessage } = useIntl();

  const [editable, setEditable] = useState(true);

  const location = useLocation();

  const [infoAccount, setInfoAccount] = useState<IAccountInfo | null>(null);
  const [idSelected, setIdSelected] = React.useState<number | string | null>(
    null,
  );

  useEffect(() => {
    if (location.pathname.includes('employee_detail')) setEditable(false);
    else setEditable(true);
  }, [location]);

  useEffect(() => {
    const { id } = params;
    if (id) {
      getStaffRequest.run(id);
    } else {
      setInfoAccount(null);
    }
  }, [params]);

  const { t } = useTranslate();

  const [openDialog, setOpenDialog] = useToggle(false);

  const handleDelete = () => {
    setOpenDialog.set(true);
  };

  const getStaffRequest = useRequest(getStaffAccount, {
    manual: true,
    onSuccess(res: any) {
      const data = res.get_staff_detail;

      const infoRes: IAccountInfo = {
        email: data.email,
        staff_id: data.staff_id,
        phone: data.phone,
        status: data.status,
        full_name: data.name,
        nums_of_give: data.nums_of_give,
      };
      setInfoAccount(infoRes);
    },
  });

  const onFinishFailed = (errorInfo: any) => {};

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item className={styles.breacrumbItem}>
          <Link to="/employee" className={styles.previousEditLink}>
            <LeftOutlined />
            <div>{formatMessage({ id: 'employee_detail_account' })}</div>
          </Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className={styles.tableComponent}>
        {params.id && !infoAccount ? (
          <Skeleton active />
        ) : (
          <Form
            name="basic"
            className={styles.itemForm}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ ...infoAccount }}
            // onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Row>
              <Col span={12}>
                <div className={styles.detailAdm}>
                  <div>
                    <div className={styles.formGeneric}>
                      <Form.Item
                        label={formatMessage({ id: 'fullname' })}
                        name="full_name"
                        rules={[
                          {
                            required: true,
                            message: t('error.require', {
                              field: t('fullname'),
                            }),
                          },
                        ]}
                      >
                        <Input placeholder="Admin" disabled={!editable} />
                      </Form.Item>
                      <Form.Item
                        label={formatMessage({
                          id: 'const_column_phone_number',
                        })}
                        name="phone"
                        rules={[
                          {
                            required: true,
                            message: t('error.require', {
                              field: t('const_column_phone_number'),
                            }),
                          },
                          {
                            pattern: /(84|0[3|5|7|8|9])+([0-9]{8})\b/,
                            message: t('error.patern', {
                              field: t('const_column_phone_number'),
                            }),
                          },
                        ]}
                      >
                        <Input
                          placeholder={formatMessage({
                            id: 'const_column_phone_number',
                          })}
                          disabled={!editable}
                        />
                      </Form.Item>
                      <Form.Item
                        label={formatMessage({ id: 'const_column_email' })}
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: t('error.require', {
                              field: t('email'),
                            }),
                          },
                          {
                            type: 'email',
                            message: t('error.email', {
                              field: t('email'),
                            }),
                          },
                        ]}
                      >
                        <Input
                          placeholder="const_column_email"
                          disabled={!editable}
                        />
                      </Form.Item>
                      <Form.Item
                        label={formatMessage({ id: 'password' })}
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: t('error.require', {
                              field: t('password'),
                            }),
                          },
                          {
                            min: 8,
                            max: 99,
                            message: t('error.password', {
                              field: t('password'),
                            }),
                          },
                        ]}
                      >
                        <Input.Password
                          placeholder={formatMessage({ id: 'password' })}
                          disabled={!editable}
                        />
                      </Form.Item>
                      <Form.Item
                        name="re_password"
                        label={formatMessage({ id: 'enter_password' })}
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: t('error.require', {
                              field: t('enter_password'),
                            }),
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value ||
                                getFieldValue('password') === value
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error(
                                  'The two passwords that you entered do not match!',
                                ),
                              );
                            },
                          }),
                        ]}
                      >
                        <Input.Password disabled={!editable} />
                      </Form.Item>

                      <Form.Item
                        label={formatMessage({ id: 'status' })}
                        name="status"
                        rules={[
                          {
                            required: true,
                            message: t('error.require', {
                              field: t('status'),
                            }),
                          },
                        ]}
                      >
                        <Switch
                          defaultChecked={infoAccount?.status}
                          disabled={!editable}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </div>
      {openDialog && (
        <Dialog
          open={openDialog}
          setOpen={(b) => {
            setOpenDialog.set(b);
          }}
          itemEdit={idSelected}
        />
      )}
    </>
  );
};
