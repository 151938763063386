import { API_PATH, privateRequest, request } from '@/utils/apis';
import moment from 'moment';

export interface CreateDiscountEventInput {
  event_name?: string;
  image?: string;
  enterprise_id?: number;
  start_time?: string;
  end_time?: string;
  disable?: boolean;
  description?: string;
  type?: string;
  voucher_type_id?: number;
  limit_voucher_per_bill?: number;
  limit_voucher_per_product?: number;
  limit_discount_percent?: number;
  minimum_amount_require?: number;
  apply_at_same_time?: boolean;
  apply_same_time_list?: number[];
  discount_product_ids?: number[];
}

export interface ConfirmDiscountEventInput {
  confirm: boolean;
  note?: string;
}

export const getListDiscountEvent = (
  {
    current,
    pageSize,
  }: {
    current: number;
    pageSize: number;
  },
  formData: {
    searchText?: string;
    toDate?: string[];
    status?: string;
  },
) => {
  const params: any = {
    page: current,
    limit: pageSize,
  };
  if (!!formData.searchText) {
    params.search = formData.searchText;
  }
  if (!!formData.status) {
    params.status = formData.status;
  }
  if (Array.isArray(formData.toDate)) {
    params.start_time = moment(formData.toDate[0]).format('YYYY/MM/DD');
    params.end_time = moment(formData.toDate[1]).format('YYYY/MM/DD');
  }
  return privateRequest(request.get, API_PATH.DISCOUNT_EVENT, { params }).then(
    (response) => {
      return {
        list: response.data?.data?.map((item: any, index: number) => ({
          ...item,
          stt: (current - 1) * pageSize + index + 1,
        })),
        total: response?.data?.total,
      };
    },
  );
};

export const getAllDiscountEvent = (search?: string) => {
  const params: any = {
    page: 1,
    limit: 20,
    search,
  };
  return privateRequest(request.get, API_PATH.DISCOUNT_EVENT, { params });
};

export const createDiscountEvent = (input: CreateDiscountEventInput) => {
  return privateRequest(request.post, API_PATH.DISCOUNT_EVENT, {
    data: {
      ...input,
    },
  });
};

export const updateDiscountEvent = (
  input: CreateDiscountEventInput,
  id: number,
) => {
  return privateRequest(request.put, API_PATH.DISCOUNT_EVENT + `/${id}`, {
    data: {
      ...input,
    },
  });
};

export const confirmDiscountEvent = (
  input: ConfirmDiscountEventInput,
  id: number,
) => {
  return privateRequest(
    request.put,
    API_PATH.CONFIRM_DISCOUNT_EVENT + `/${id}`,
    { data: input },
  );
};

export const detailDiscountEvent = (id: number) => {
  return privateRequest(request.get, API_PATH.DISCOUNT_EVENT + `/detail/${id}`);
};
// export const getCoreVoucher = ()

export const getListEnterpriseRegisterVoucher = (search?: string) => {
  const params: any = {};
  if (!!search) {
    params.search = search;
  }
  return privateRequest(request.get, API_PATH.ENTERPRISE_EVENT, { params });
};

export const getListApplyEvent = (event_id?: number) => {
  const params: { event_id?: number } = {};
  if (event_id) {
    params.event_id = event_id;
  }
  return privateRequest(request.get, API_PATH.APPLY_EVENT, { params });
};

export const getCoreVoucher = () => {
  return privateRequest(request.get, API_PATH.CORE_VOUCHER_EVENT);
};
export const getDetailInviteCode = (inviteCode: string) => {
  return privateRequest(
    request.get,
    `${API_PATH.INVITE_CODE}${inviteCode}`,
    {},
  );
};

export const createDetailInviteCode = (inviteCode: string) => {
  return privateRequest(request.post, `${API_PATH.LINK_USER}${inviteCode}`, {});
};

export const cunlinkInviteCode = () => {
  return privateRequest(request.patch, `${API_PATH.LINK_USER}`, {});
};

export const detailLink = () => {
  return privateRequest(request.get, `${API_PATH.DETAIL_LINK}`, {});
};

export const statisticUseEvents = (
  {
    current,
    pageSize,
    id,
  }: {
    current: number;
    pageSize: number;
    id: number;
  },
  formData: {
    search?: string;
    toDate?: string[];
  },
) => {
  const params: any = {
    page: current,
    limit: pageSize,
  };
  if (!!formData.search) {
    params.search = formData.search;
  }
  if (Array.isArray(formData.toDate)) {
    params.start_time = moment(formData.toDate[0]).format('YYYY/MM/DD');
    params.end_time = moment(formData.toDate[1]).format('YYYY/MM/DD');
  }
  return privateRequest(request.get, API_PATH.STATISTIC_USE_EVENTS + '/' + id, {
    params,
  }).then((response) => {
    return {
      list: response.data?.data?.map((item: any, index: number) => ({
        ...item,
        stt: (current - 1) * pageSize + index + 1,
      })),
      total: response?.data?.total,
    };
  });
};

export const getListProduct = (
  { current, pageSize }: { current: number; pageSize: number },
  formData: {
    search?: string;
  },
) => {
  const params: any = {
    page: current,
    limit: pageSize,
  };

  if (!!formData.search) {
    params.search = formData.search;
  }

  return privateRequest(request.get, API_PATH.LIST_PRODUCT, {
    params,
  }).then((response) => {
    return {
      list: response.data?.map((item: any, index: number) => ({
        ...item,
        stt: (current - 1) * pageSize + (index + 1),
      })),
      total: response?.total,
    };
  });
};
