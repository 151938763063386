import { API_PATH, request } from '@/utils/apis';

export const checkLogin = (phone: any) => {
  return request.post(API_PATH.default, {
    data: {
      query: `
            mutation {
              check_ident_to_signup(input: { ident: "${phone}" }) {
                status
              }
            }      
          `,
    },
  });
};
export const resetPassword = (input: any) => {
  return request.post(API_PATH.default, {
    data: {
      query: `     
            mutation {
              enterprise_update_password(
                input: { phone: "${input.phone_number}", new_password: "${input.password}" }
              ) {
                message
              }
            }
          `,
    },
  });
};
export const inputRefCpde = (data: any, config: any) => {
  return request.post(API_PATH.default, {
    data: {
      query: `
            mutation {
              enterprise_invite_request(input: {
                invite_code:"${data.invite_code}"
              }) {
                message
              }
            }          
          `,
    },
    headers: {
      Authorization: `Bearer ${config}`,
    },
  });
};
