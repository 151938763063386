import { API_PATH } from '@/utils/apis';
import { deepCopy, formatInputNumber } from '@/utils/apis/common';
import { ENVIRONMENTS } from '@/utils/constant';
import { DeleteOutlined, DiffOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Input,
  InputNumber,
  Space,
  Switch,
  Upload,
  Table,
  Button,
  UploadFile,
  Modal,
  Form,
  Row,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { RcFile } from 'antd/lib/upload';
import React, { useEffect, useState } from 'react';

const ListPropertyTable = ({
  flexibleCol,
  dataSource,
  setDataSource,
}: {
  flexibleCol: any[];
  dataSource: any[];
  setDataSource: React.Dispatch<
    React.SetStateAction<
      {
        key: number;
        main_prop_name: string;
        image_link: UploadFile[];
        status: boolean;
        sub_prop: {
          sub_prop_name: string;
          price: number;
          sku_code: string;
        }[];
      }[]
    >
  >;
}) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const handleCancel = () => setPreviewOpen(false);
  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    setPreviewImage(src);
    setPreviewOpen(true);
    // const image = new Image();
    // image.src = src;
    // const imgWindow = window.open(src);
    // imgWindow?.document.write(image.outerHTML);
  };

  const handleOnchangeMainCol = (val: string, index: number, key: string) => {
    setDataSource((state) => {
      const newDataSource = deepCopy(state);
      //@ts-ignore
      newDataSource[index][key] = val;
      return newDataSource;
    });
  };
  const handleOnchangeSubCol = (
    val: string,
    indexOfRc: number,
    indexOfInput: number,
    key: string,
  ) => {
    const newDataSource = deepCopy(dataSource);
    //@ts-ignore
    newDataSource[indexOfRc].sub_prop[indexOfInput][key] = val;

    setDataSource((state) => {
      const newDataSource = deepCopy(state);
      //@ts-ignore
      newDataSource[indexOfRc].sub_prop[indexOfInput][key] = val;
      return newDataSource;
    });
  };

  const subCol: any[] = [
    {
      title: 'Ảnh',
      dataIndex: 'image_link',
      width: '15%',
      render: (_: any, record: any, index: any) => (
        <Upload
          action={ENVIRONMENTS.API_URL + API_PATH.UPLOAD}
          className="product-upload"
          listType="picture-card"
          fileList={dataSource?.[index]?.image_link}
          onChange={(val: any) => {
            setDataSource((state) => {
              const newProducts = [...state];
              newProducts[index].image_link = val.fileList;
              return newProducts;
            });
          }}
          onRemove={() => {
            setDataSource((state) => {
              const newProducts = [...state];
              newProducts[index].image_link = [];
              return newProducts;
            });
          }}
          onPreview={onPreview}
        >
          {dataSource?.[index]?.image_link.length < 1 && <>+</>}
        </Upload>
      ),
    },
    {
      title: 'Giá bán',
      width: '20%',
      dataIndex: 'price',
      render: (value: any, record: any, index: number) => (
        <>
          {dataSource?.[index]?.sub_prop?.map((item: any, i: number) => (
            <InputNumber
              defaultValue={record?.sub_prop?.[i]?.price}
              style={{
                width: '100%',
                marginBottom: 10,
              }}
              formatter={formatInputNumber}
              key={i}
              onChange={(val) =>
                handleOnchangeSubCol(`${val}`, index, i, 'price')
              }
            />
          ))}
        </>
      ),
    },
    {
      title: 'SKU phân loại',
      dataIndex: 'sku_code',
      width: '20%',
      render: (value: any, record: any, index: number) => (
        <>
          {dataSource?.[index]?.sub_prop?.map((item: any, i: number) => (
            <Input
              style={{
                marginBottom: 10,
              }}
              defaultValue={record?.sub_prop?.[i]?.sku_code}
              key={i}
              onChange={(e) =>
                handleOnchangeSubCol(`${e.target.value}`, index, i, 'sku_code')
              }
            />
          ))}
          <div
            style={{
              marginTop: 10,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              gap: 10,
            }}
          >
            <Button
              type="text"
              ghost
              onClick={() => {
                const newSubProp = dataSource[index].sub_prop?.map(
                  (item: any) => {
                    delete item['id'];
                    return item;
                  },
                );

                console.log(newSubProp);
                setDataSource((prevData) => {
                  const newDataSource = [...prevData];
                  const newSubProp = prevData[index].sub_prop?.map(
                    (item: any) => {
                      delete item['id'];
                      return item;
                    },
                  );
                  newDataSource.push({
                    key: index + 2,
                    main_prop_name: record.main_prop_name,
                    image_link: record.image_link,
                    sub_prop: [...newSubProp],
                    status: record?.status,
                  });
                  return newDataSource;
                });
              }}
              icon={<DiffOutlined />}
            ></Button>
            <Button
              type="text"
              ghost
              disabled={dataSource?.length === 1}
              onClick={() => {
                setDataSource((prevData) => {
                  const newDataSource = [...prevData];
                  newDataSource.splice(index, 1);
                  return newDataSource;
                });
              }}
              icon={<DeleteOutlined className="red-color cursor-pointer" />}
            ></Button>
            <Switch
              defaultChecked={record?.status}
              onChange={(val) => {
                setDataSource((state) => {
                  const newDataSource = deepCopy(state);
                  newDataSource[index].status = val;
                  return newDataSource;
                });
              }}
            />
          </div>
        </>
      ),
    },
  ];
  const mainCol: any[] = flexibleCol
    ?.map((item: any, index: number) => ({
      title: item?.name,
      width: index === 0 ? '15%' : '25%',
      dataIndex: index === 0 ? 'main_prop_name' : 'sub_prop_name',
      key: index,
      render: (value: any, record: any, indexOfRc: number) => (
        <>
          {index === 1 ? (
            <>
              {dataSource?.[indexOfRc]?.sub_prop?.map(
                (item: any, i: number) => (
                  <Space
                    key={i}
                    style={{
                      marginBottom: '10px',
                    }}
                  >
                    <Input
                      defaultValue={record?.sub_prop?.[i]?.sub_prop_name}
                      onChange={(e) =>
                        handleOnchangeSubCol(
                          e.target.value,
                          indexOfRc,
                          i,
                          'sub_prop_name',
                        )
                      }
                    />
                    <Button
                      type="text"
                      disabled={dataSource[indexOfRc]?.sub_prop?.length === 1}
                      onClick={() => {
                        setDataSource((prevData) => {
                          const newDataSource = [...prevData];
                          newDataSource[indexOfRc]?.sub_prop.splice(i, 1);
                          return newDataSource;
                        });
                      }}
                      icon={
                        <DeleteOutlined className="red-color cursor-pointer" />
                      }
                    ></Button>
                    <Button
                      type="text"
                      ghost
                      onClick={() => {
                        setDataSource((prevData) => {
                          const newDataSource = [...prevData];
                          newDataSource[indexOfRc]?.sub_prop.push({
                            sub_prop_name: '',
                            price: 0,
                            sku_code: '',
                          });
                          return newDataSource;
                        });
                      }}
                      icon={<PlusOutlined className="cursor-pointer" />}
                    ></Button>
                  </Space>
                ),
              )}
            </>
          ) : (
            <Input
              defaultValue={record?.main_prop_name}
              onChange={(e) =>
                handleOnchangeMainCol(
                  e.target.value,
                  indexOfRc,
                  'main_prop_name',
                )
              }
            />
          )}
        </>
      ),
    }))
    .concat(subCol);
  return (
    <div
      style={{
        marginBottom: 20,
      }}
    >
      <Form.List name="product_prop_mixed">
        {() => (
          <Table columns={mainCol} dataSource={dataSource} pagination={false} />
        )}
      </Form.List>

      {previewOpen && (
        <Modal open={previewOpen} footer={null} onCancel={handleCancel}>
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      )}
    </div>
  );
};

export default ListPropertyTable;
