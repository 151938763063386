import { useState, useEffect } from 'react';

import * as L from 'leaflet';

import { useMap } from 'react-leaflet';

import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import { formatHide } from '@/utils/apis/common';

function updateProgressBar(processed: any, total: any, elapsed: any) {
  if (elapsed > 1000) {
    console.log(Math.round((processed / total) * 100) + '%');
  }
}

const mcg = (L as any).markerClusterGroup({
  chunkedLoading: true,
  chunkProgress: updateProgressBar,
  disableClusteringAtZoom: 17,
});

function MarkerCluster({ markers, mapItemDetail }: any) {
  const map = useMap();

  // const [listMarkers, setListMarkers] = useState<any>([]);

  // useEffect(() => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // let res: any[] = [];
  // if (markers) {
  //   filters.forEach((filter: any) => {
  //     switch (filter.type) {
  //       case 1: //Owned;
  //         if (filter.active) {
  //           res = res.length ? res : markers;
  //         } else {
  //           const newData = res.length
  //             ? [...res].filter(
  //                 (item) =>
  //                   item.user_id === null &&
  //                   item.user_name_owner === null &&
  //                   item.user_full_name === null,
  //               )
  //             : [...markers].filter(
  //                 (item) =>
  //                   item.user_id === null &&
  //                   item.user_name_owner === null &&
  //                   item.user_full_name === null,
  //               );
  //           res = newData;
  //         }
  //         break;
  //       case 0: //black list;
  //         if (filter.active) {
  //           res = res.length ? res : markers;
  //         } else {
  //           const newData = [...res].filter((item) => !item.is_blacklist);
  //           res = newData;
  //         }
  //         break;

  //       case 2: //Approved;
  //         if (filter.active) {
  //           res = res.length ? res : markers;
  //         } else {
  //           const newData = [...res].filter(
  //             (item) => item.status === 'approved',
  //           );
  //           res = newData;
  //         }
  //         break;
  //     }
  //   });
  // }
  // setListMarkers(res);

  // setListMarkers(markers);
  // }, [markers]);

  useEffect(() => {
    mcg.clearLayers();
    const markerList: any[] = [];
    if (mapItemDetail?.lat && mapItemDetail?.long) {
      const marker = L.marker(
        new L.LatLng(mapItemDetail.lat, mapItemDetail.long),
        {
          icon: L.icon({
            iconUrl: '/public_assets/images/map-marker-picker.png',
            iconSize: [64, 70],
            iconAnchor: [32, 53],
            popupAnchor: [0, -40],
          }),
        },
      ).bindPopup(`
						<div class="marker-popup-container">
							<p class="${mapItemDetail.is_blacklist ? 'mb-0' : ''}">
								<span class='nameAddressMarker'>${mapItemDetail.address}</span><br />
	
								Latitude: ${mapItemDetail.lat}<br />
								Longitude: ${mapItemDetail.long}<br />
							</p>
	
						</div>
					`);
      marker.addTo(map).openPopup();
      markerList.push(marker);
    }

    // optionally center the map around the markers
    // map.fitBounds(mcg.getBounds());

    // add the marker cluster group to the map
    mcg.addLayers(markerList);
    // map.addLayer(mcg);
  }, [map, mapItemDetail]);

  return null;
}

export default MarkerCluster;
