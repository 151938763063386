import { useAuth } from '@/utils/hooks/useAuth';
import { API_PATH, request } from '@/utils/apis';
import { useRequest } from 'ahooks';
import { message } from 'antd';
import { history } from 'umi';

export const useLogin = () => {
  const { onLogin } = useAuth();
  return useRequest(
    async (values) => {
      return request.post(API_PATH.default, {
        data: {
          query: `
            mutation {
              login_enterprise(input: { email_or_phone: "${values?.phone_number?.trim()}", password: "${
            values.password
          }" }) {
                enterprise_id
                access_token
                refresh_token
                display_name
                updated_lisence
                qr_code_string
								register_create_gift
  							register_release_voucher
              }
            }
          `,
        },
      });
    },
    {
      manual: true,
      onSuccess: (result) => {
        // console.log(result);
        if (result.errors) {
          if (result?.errors[0]?.message === 'ACCOUNT_NOT_ACTIVE') {
            message.error('Tài khoản không hoạt động');
          } else {
            message.error('Tài khoản hoặc mật khẩu không chính xác');
          }
        }
        // console.log(result);
        let loginItem;
        if (result?.data?.login_enterprise) {
          onLogin({
            token: result?.data?.login_enterprise?.access_token,
            refreshToken: result?.data?.login_enterprise?.refresh_token,
            enterprise_id: result?.data?.login_enterprise?.enterprise_id,
            display_name: result?.data?.login_enterprise?.display_name,
            updated_lisence: result?.data?.login_enterprise?.updated_lisence,
            welcome: true,
            qr_code_string: result?.data?.login_enterprise?.qr_code_string,
            register_create_gift:
              result?.data?.login_enterprise?.register_create_gift,
            register_release_voucher:
              result?.data?.login_enterprise?.register_release_voucher,
          });
        }
        if (result.data) {
          history.push('/employee');
        }
      },
      onError: (err) => {
        message.error(err?.message || 'Login failed');
      },
    },
  );
};
