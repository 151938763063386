import {
  AutoComplete,
  Button,
  Form,
  Input,
  Row,
  Select,
  Space,
  Tabs,
} from 'antd';
import React, { Dispatch, useEffect, useState } from 'react';

import { useTranslate } from '@/utils/hooks/useTranslate';
import styles from '../index.less';
import { useHistory, useRequest } from 'umi';
import { paternPhone } from '@/utils/patern';
import { getListCountry } from '../service';

type Props = {
  onFinishStep1: (value: any) => void;
  loading: boolean;
  option: 'phone' | 'email';
  setOption: Dispatch<React.SetStateAction<any>>;
  setDialCode: Dispatch<React.SetStateAction<string>>;
  dialCode: string;
};

const Step1 = (props: Props) => {
  const { option, setOption, dialCode, setDialCode } = props;
  const { t } = useTranslate();
  const history = useHistory();
  const nation = useRequest(getListCountry, {
    manual: true,
  });
  const onFinish = (val: any) => {
    if (option === 'phone') {
      if (dialCode === '+84' && val.phone_number.charAt(0) === '0') {
        val.phone_number = val.phone_number.substring(
          1,
          val.phone_number.length,
        );
      } else {
        if (val.phone_number?.includes(dialCode)) {
          val.phone_number = val.phone_number?.replace(dialCode, '');
        }
        if (val.phone_number?.includes(dialCode.replace('+', ''))) {
          val.phone_number = val.phone_number?.replace(
            dialCode.replace('+', ''),
            '',
          );
        }
      }
      // console.log({ phone_number: dialCode + val.phone_number });

      props.onFinishStep1({
        phone_number: dialCode + val.phone_number,
      });
    } else {
      props.onFinishStep1(val);
    }
  };
  useEffect(() => {
    if (option === 'phone') {
      nation.run();
    }
  }, [option]);
  return (
    <div className={styles.loginWrap}>
      <h1>Đăng ký tài khoản doanh nghiệp</h1>
      <Tabs
        centered
        style={{
          border: 'none',
        }}
        onChange={(key) => setOption(key.toString())}
      >
        <Tabs.TabPane
          tab="Đăng ký bằng số điện thoại"
          key="phone"
        ></Tabs.TabPane>
        <Tabs.TabPane tab="Đăng ký bằng Email" key="email"></Tabs.TabPane>
      </Tabs>
      <Form onFinish={onFinish} layout="vertical">
        {option === 'phone' ? (
          <>
            <Form.Item
              label={t('phone_number')}
              name="phone_number"
              rules={[
                {
                  required: true,
                  message: t('error.require', {
                    field: t('phone_number'),
                  }),
                },

                {
                  pattern: dialCode === '+84' ? paternPhone : /^.[0-9]{0,15}$/,

                  message: t('error.patern', {
                    field: t('const_column_phone_number'),
                  }),
                },
              ]}
            >
              <Space.Compact
                style={{
                  width: '100%',
                }}
              >
                <Select
                  showSearch
                  defaultValue="+84"
                  onChange={(val) => setDialCode(val)}
                  style={{
                    width: '110px',
                  }}
                  options={nation.data?.get_list_country
                    ?.filter((item: any) => item?.code !== 'CAN')
                    ?.map((item: any, i: number) => ({
                      label: (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'flex-start',
                            paddingTop: '1px',
                          }}
                        >
                          <div
                            style={{
                              marginTop: '2px',
                            }}
                            dangerouslySetInnerHTML={{
                              __html: item?.svg,
                            }}
                          ></div>
                          <div>{item?.calling_code}</div>
                        </div>
                      ),
                      value: item?.calling_code,
                      key: i,
                    }))}
                ></Select>
                <Input type="text" placeholder={t('phone_number')} />
              </Space.Compact>
            </Form.Item>
          </>
        ) : (
          <Form.Item
            label={t('email')}
            name="email"
            rules={[
              {
                type: 'email',
                message: t('error.email', {
                  field: t('email'),
                }),
              },
              {
                required: true,
                message: t('error.require', {
                  field: t('email'),
                }),
              },
            ]}
          >
            <Input placeholder={t('email')} />
          </Form.Item>
        )}
        <Row justify="center">
          <Button
            type="primary"
            htmlType="submit"
            loading={props.loading}
            className={styles.btnSubmit}
          >
            Đăng ký
          </Button>
        </Row>
      </Form>
      <div className={styles.register}>
        <h2>Đã có tài khoản?</h2>
        <div
          className={styles.links}
          onClick={() => {
            history.push('/login');
          }}
        >
          <span>Đăng Nhập</span>
        </div>
      </div>
    </div>
  );
};

export default Step1;
