import { formatTime } from './../../utils/formatTime';
import { privateRequest, request, API_PATH } from '@/utils/apis';
import { ENVIRONMENTS } from '@/utils/constant';

interface Result {
  total: number;
  list: any[];
}

export const getTableData = (
  { current, pageSize }: { current: number; pageSize: number },
  formData: {
    fullName: string;
  },
): Promise<Result> => {
  if (formData.fullName === undefined) {
    formData.fullName = '';
  }

  // `page=${current}&pageSize=${pageSize}`

  const data = {
    query: `
    mutation {
      get_list_staff(filter: "${formData.fullName}") {
        staff_id
        name
        email
        phone
        created_at
        status
      }
    }
       
    `,
  };

  return privateRequest(request.post, API_PATH.default, { data }).then(
    (res: any) => {
      const result = res?.data?.get_list_staff.map((e: any, index: any) => ({
        ...e,
        stt: index + 1,
        full_name: e.name,
        dateOfBirth: formatTime(e.created_at),
      }));
      return {
        total: res?.total,
        list: result,
      };
    },
  );
};

export const deleteEmployee = (id: any) => {
  const query = `
  mutation {
    enterprise_delete_staff(staff_id: ${id}) {
      message
    }
  }

  `;
  return privateRequest(request.post, API_PATH.default, {
    data: {
      query,
    },
  });
};

export const switchStatusAdmin = (id: any) => {
  const query = `
    mutation {
      switch_status_admin(id: "${id}")
    }
  `;
  return privateRequest(request.post, API_PATH.default, {
    data: {
      query,
    },
  });
};
