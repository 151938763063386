// @ts-nocheck
import React from 'react';
import { ApplyPluginsType } from '/root/workspace/locameet-cms-business/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';

export function getRoutes() {
  const routes = [
  {
    "exact": true,
    "path": "/login",
    "component": require('@/layouts/AuthLayout/index').default,
    "title": "Login",
    "wrappers": [require('@/layouts/Wrapper').default],
    "routes": [
      {
        "exact": true,
        "path": "/login",
        "component": require('@/pages/SignIn/index').default
      }
    ]
  },
  {
    "exact": true,
    "path": "/register",
    "component": require('@/pages/Register/index').default
  },
  {
    "exact": true,
    "path": "/forgot-password",
    "component": require('@/pages/ForgotPassword/index').default,
    "title": "Quên mật khẩu"
  },
  {
    "exact": false,
    "path": "/",
    "component": require('@/layouts/MainLayout').default,
    "wrappers": [require('@/layouts/Wrapper').default],
    "routes": [
      {
        "exact": true,
        "path": "/employee",
        "component": require('@/pages/Employee/index').default,
        "title": "navigation_employee",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/employee_new_account",
        "component": require('@/pages/EmployeeNewAccount/index').default,
        "title": "navigation_employee",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/employee_detail/:id",
        "component": require('@/pages/EmployeeAccount/index').default,
        "title": "navigation_employee",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/employee_edit_account/:id",
        "component": require('@/pages/EmployeeEditAccount/index').default,
        "title": "navigation_employee",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/locabonusHis",
        "component": require('@/pages/LocaBonusHistory/index').default,
        "title": "navigation_history_locaBonus",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/locabonusHis",
        "component": require('@/pages/LocaBonusHistory/index').default,
        "title": "navigation_history_locaBonus",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/customer",
        "component": require('@/pages/Customer/index').default,
        "title": "navigation_list_customer",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/statistic_take_loca",
        "component": require('@/pages/Statistic/index').default,
        "title": "navigation_history_take_locaBonus",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/update_business",
        "component": require('@/pages/UpdateBusiness/index').default,
        "title": "navigation_update_business",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/manage-order",
        "component": require('@/pages/ManageOrder/index').default,
        "title": "Quản lý đơn hàng",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/gift-building",
        "component": require('@/pages/Gift/index').default,
        "title": "Quản lý quà tặng",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/gift-events",
        "component": require('@/pages/Gift/index').default,
        "title": "Quản lý sự kiện",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/gift-events/:id",
        "component": require('@/pages/Gift/DetailEvent/index').default,
        "title": "Quản lý sự kiện",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/create-gift-events",
        "component": require('@/pages/Gift/CreateEvent/index').default,
        "title": "Tạo sự kiện",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/create-gift-building",
        "component": require('@/pages/Gift/CreateGift/index').default,
        "title": "navigation_gift",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/gift-building/:id",
        "component": require('@/pages/Gift/DetailGift/index').default,
        "title": "navigation_gift",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/list-received-gift/:id",
        "component": require('@/pages/Gift/ListReceivedGift/index').default,
        "title": "ListReceivedGift",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/voucher",
        "component": require('@/pages/Voucher/index').default,
        "title": "navigation_voucher",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/voucher/*",
        "component": require('@/pages/Voucher/index').default,
        "title": "navigation_voucher",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "export": true,
        "path": "/package",
        "component": require('@/layouts/Wrapper').default,
        "title": "navigation_package",
        "routes": [
          {
            "export": true,
            "path": "/package",
            "component": require('@/pages/Package/index').default,
            "title": "navigation_package",
            "exact": true
          },
          {
            "path": "/package/buy_package",
            "component": require('@/pages/BuyPackage/index').default,
            "wrappers": [require('@/layouts/Wrapper').default],
            "title": "navigation_package",
            "exact": true
          }
        ],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "path": "/qr_code",
        "component": require('@/pages/QRCode/index').default,
        "title": "QR hóa đơn",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/discount_event",
        "component": require('@/pages/DiscountEvent/index').default,
        "title": "navigation_discount_event",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/create_discount_event",
        "component": require('@/pages/DiscountEvent/Component/CreateDiscountEvent').default,
        "title": "navigation_discount_event",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/discount_event/:id",
        "component": require('@/pages/DiscountEvent/Component/DetailDiscountEvent').default,
        "title": "navigation_discount_event",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/discount_event_statistic/:id",
        "component": require('@/pages/DiscountEvent/Component/StatisticDiscountEvent').default,
        "title": "navigation_discount_event",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/account",
        "component": require('@/pages/Account').default,
        "title": "navigation_account",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/account/*",
        "component": require('@/pages/Account').default,
        "title": "navigation_account",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/locavoucher_transaction",
        "component": require('@/pages/LocaVoucherTransaction').default,
        "title": "navigation_locavoucher_transaction",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/products",
        "component": require('@/pages/Product').default,
        "title": "navigation_products",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/create-product",
        "component": require('@/pages/Product/component/CreateProduct').default,
        "title": "navigation_products",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      },
      {
        "exact": true,
        "path": "/products/:id",
        "component": require('@/pages/Product/component/UpdateProduct').default,
        "title": "navigation_products",
        "routes": [],
        "wrappers": [require('@/layouts/Wrapper').default]
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
