import { API_PATH } from '@/utils/apis';
import { ENVIRONMENTS } from '@/utils/constant';
import { DownloadOutlined } from '@ant-design/icons';
import { useAntdTable, useToggle } from 'ahooks';
import {
  Breadcrumb,
  Button,
  DatePicker,
  Form,
  message,
  Skeleton,
  Table,
} from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import React, { useState } from 'react';
import { useIntl } from 'umi';
import styles from './index.less';
import { getTableData } from './service';
import { formatNumber } from '@/utils/apis/common';

interface Item {
  name: {
    last: string;
  };
  email: string;
  phone: string;
  gender: 'male' | 'female';
}
interface DataType {
  key: string;
  stt: number;
  full_name: string;
  phone: string;
  email: string;
  dateOfBirth: string;
  status: string;
}
export default () => {
  const formatDate = 'DD/MM/YYYY';
  const { RangePicker } = DatePicker;

  const [form] = Form.useForm();
  const { tableProps, search, error, loading, data } = useAntdTable(
    getTableData,
    {
      form,
      onError: (error: any) => {
        console.log(error);
        message.error(
          error.errors ? error.errors[0] : formatMessage({ id: 'error' }),
        );
      },
    },
  );

  const { formatMessage } = useIntl();

  const { type, changeType, submit, reset } = search;

  const handleDownload = () => {
    const a = document.createElement('a');
    a.style.display = 'none';
    document.body.appendChild(a);
    const localStorageInfo: any = window.localStorage.getItem(
      ENVIRONMENTS.LOCAL_STORAGE_KEY as string,
    );
    const token = JSON.parse(localStorageInfo)?.token;
    const url = `${ENVIRONMENTS.API_URL}${
      API_PATH.EXPORT_STATISTIC
    }?start_time=${form.getFieldValue('toDate')?.[0] || ''}&end_time=${
      form.getFieldValue('toDate')?.[1] || ''
    }&token=${token}`;
    a.href = url;
    a.download = 'testfile.xlsx';
    a.click();
    window.URL.revokeObjectURL(url);
  };
  const columns: ColumnsType<DataType> = [
    {
      title: 'STT',
      width: 100,
      dataIndex: 'stt',
      key: 'stt',
      align: 'center',
    },
    {
      title: 'time',
      dataIndex: 'created_at_string',
      align: 'right',
      key: 'created_at_string',
    },
    {
      title: 'const_column_gift',
      dataIndex: 'nums_of_given',
      align: 'right',
      key: 'nums_of_given',
    },
    {
      title: 'const_column_local_business',
      dataIndex: 'locabonus_receive',
      align: 'right',
      key: 'locabonus_receive',
    },
  ].map((item: any) => {
    return { ...item, title: formatMessage({ id: item.title }) };
  });
  const searchForm = (
    <div className={styles.searchContainer}>
      <Form form={form} className={styles.searchForm}>
        <Form.Item name="toDate" className={styles.searchItem}>
          {/* @ts-ignore */}
          <RangePicker
            onChange={submit}
            format={formatDate}
            picker="date"
            className="w-100"
            placeholder={['Từ ngày', 'Đến ngày']}
          />
        </Form.Item>
        <Form.Item>
          <Button
            icon={<DownloadOutlined />}
            ghost
            type="primary"
            onClick={() => handleDownload()}
          >
            Xuất excel
          </Button>
        </Form.Item>
      </Form>
      <div>
        Tổng số locabonus:{' '}
        <b className="primary-color">{formatNumber(Number(data?.totalLB))}</b>
      </div>
    </div>
  );

  return (
    <>
      <Breadcrumb className={styles.breadcrumb}>
        <Breadcrumb.Item>
          {formatMessage({ id: 'navigation_history_take_locaBonus' })}
        </Breadcrumb.Item>
      </Breadcrumb>
      {searchForm}
      <div className={styles.tableComponent}>
        {loading || error ? (
          <Skeleton active />
        ) : (
          <Table
            {...tableProps}
            columns={columns}
            locale={{ emptyText: formatMessage({ id: 'const_column_empty' }) }}
            scroll={{ x: 1000 }}
          />
        )}
      </div>
    </>
  );
};
