import { Button, Form, Input, message, notification, Typography } from 'antd';
import React from 'react';

import { auth } from '@/utils/firebaseConfig';
import { useTranslate } from '@/utils/hooks/useTranslate';
import { useRequest } from 'ahooks';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import styles from './index.less';
import {
  checkLogin,
  enterpriseVerifyOtp,
  getPhoneOtpVoice,
  inputRefCpde,
  registerEnterprise,
  registerStore,
  sendOtpEmail,
  updateStoreService,
  verifyPhoneOTPVoice,
} from './service';
import Step1 from './Step/Step1';
import Step2 from './Step/Step2';
import Step3 from './Step/Step3';
import Step4 from './Step/Step4';
import Step5 from './Step/Step5';
import { useHistory } from 'umi';
import Step4_5 from './Step/Step4_5';

const { Text, Link } = Typography;

const Register: React.FC = () => {
  const { t } = useTranslate();

  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [valueSubmit, setValueSubmit] = React.useState({});
  const [currentToken, setCurrentToken] = React.useState<string>('');
  const [loading, setLoading] = React.useState(false);
  const [option, setOption] = React.useState<'phone' | 'email'>('phone');
  const history = useHistory();

  const [recaptchaToken, setRecaptchaToken] = React.useState('');
  const [dialCode, setDialCode] = React.useState('+84');
  const [step, setStep] = React.useState<1 | 2 | 3 | 4 | 4.5 | 5>(1);
  const requestCreateStore = useRequest(registerStore, {
    manual: true,
    onSuccess: (res) => {
      message.success('Đăng ký tài khoản thành công');
      setStep(4);
    },
    onError: (err: any) => {
      message.error(err.data?.messsage || 'Có lỗi xảy ra');
    },
  });
  console.log(requestCreateStore.data);

  const getOTPEmail = useRequest(sendOtpEmail, {
    manual: true,
    onSuccess: () => {
      setLoading(false);
      setStep(2);
    },
  });
  const getOTPVoice = useRequest(getPhoneOtpVoice, {
    manual: true,
    onSuccess: () => {
      setLoading(false);
      setStep(2);
    },
    onError: () => {
      message.error('Vui lòng đợi ít phút.');
      setLoading(false);
    },
  });
  const verifyOTPVoice = useRequest(verifyPhoneOTPVoice, {
    manual: true,
    onSuccess: (res) => {
      if (res.data?.verify_phone_by_otp?.status) {
        localStorage.setItem(
          'SESSION_KEY',
          res.data.verify_phone_by_otp.session,
        );
        message.success('Thành công');
        setStep(3);
      } else {
        message.error('Bạn đã nhập sai OTP');
      }
      setLoading(false);
    },
  });

  const sentOTPPhone = () => {
    if (dialCode === '+84') {
      getOTPVoice.run({
        calling_code: dialCode,
        phone_number: phoneNumber,
      });
    } else {
      generateCaptcha();
    }
  };

  const nextToOtp = () => {
    if (option === 'phone') {
      sentOTPPhone();
    } else {
      getOTPEmail.run(email);
    }
  };

  const verifyOTPEmail = useRequest(enterpriseVerifyOtp, {
    manual: true,
    onSuccess: (res: any) => {
      if (res?.data?.enterprise_verify_otp?.message === 'INVALID_CODE') {
        message.error('Bạn đã nhập sai OTP');
      } else if (res?.data?.enterprise_verify_otp?.message === 'TIME_OUT') {
        message.error('Mã OTP đã quá hạn');
      } else {
        localStorage.setItem(
          'SESSION_KEY',
          res?.data?.enterprise_verify_otp.session,
        );
        message.success('Thành công');
        setStep(3);
      }
      setLoading(false);
    },
  });

  const requestCreateEnterprise = useRequest(registerEnterprise, {
    manual: true,
    onSuccess(data, params) {
      if (data?.data) {
        setCurrentToken(data.data?.enterprise_signup_request?.access_token);
        message.success('Đăng ký tài khoản doanh nghiệp thành công');
        setStep(5);
        setLoading(false);
      } else {
        if (data?.errors[0]?.message === 'PHONE OR EMAIL ALREADY IN USE') {
          notification.error({
            message: `${
              option === 'email' ? 'Số điện thoại' : 'Email'
            } đã tồn tại`,
          });
          setStep(4);
        } else {
          notification.error({
            message: 'Có lỗi xảy ra',
          });
        }
      }
    },
  });

  const requestMakeInvite = useRequest(inputRefCpde, {
    manual: true,
    onSuccess(data, params) {
      if (!data?.errors) {
        history.push('/login');
        message.success('Thành công');
      } else {
        message.error('Mã giới thiệu không tồn tại');
      }
    },
  });

  const backScreen = () => {
    setStep(4);
  };

  const sendOTP = () => {
    //@ts-ignore
    const appVerifier = window.recaptchaVerifier;

    // let phoneToVerifier: any[] = phoneNumber.split('');
    // if (phoneNumber[0] === '0') {
    //   //@ts-ignore
    //   phoneToVerifier.shift();
    //   phoneToVerifier.unshift('+84');
    //   phoneToVerifier.join('');
    // }
    // if (phoneNumber[0] === '8' && phoneNumber[1] === '4') {
    //   phoneToVerifier.unshift('+');
    //   phoneToVerifier.join('');
    // }
    signInWithPhoneNumber(auth, phoneNumber, appVerifier)
      .then((confirmationResult) => {
        //@ts-ignore
        window.confirmationResult = confirmationResult;
        //@ts-ignore
        setRecaptchaToken(confirmationResult.recaptchaToken);
      })
      .catch((error) => {})
      .finally(() => {
        setStep(2);
        setLoading(false);
      });
  };

  const generateCaptcha = () => {
    //@ts-ignore
    window.recaptchaVerifier = new RecaptchaVerifier(
      'recaptcha-container',
      {
        size: 'invisible',
        callback: (response: any) => {},
      },
      auth,
    );
    //@ts-ignore
    sendOTP();
  };

  const requestCheckPhone = useRequest(checkLogin, {
    manual: true,
    onSuccess(data, params) {
      if (data?.data?.check_ident_to_signup?.status) {
        nextToOtp();
      } else {
        message.error(
          `${option === 'phone' ? 'Số điện thoại' : 'Email'} đã được đăng ký`,
        );
        setLoading(false);
      }
    },
  });

  const updateStore = useRequest(updateStoreService, {
    manual: true,
    onSuccess: (res: any) => {
      localStorage.removeItem('SESSION_KEY');
      message.success('Cập nhật cửa hàng thành công');
      history.push('/login');
    },
    onError: (err: any) => {
      console.log(err.data);

      message.error(err.data?.messsage || 'Có lỗi xảy ra');
    },
  });

  const onFinishStep1 = async (value: any) => {
    setLoading(true);
    if (option === 'phone') {
      setPhoneNumber(value.phone_number);
      await requestCheckPhone.runAsync(value.phone_number);
    } else {
      setEmail(value.email);
      await requestCheckPhone.runAsync(value.email);
    }
  };

  const onFinishStep2 = async (value: any) => {
    setLoading(true);
    if (option === 'phone') {
      if (dialCode === '+84') {
        verifyOTPVoice.run({
          phone_number: phoneNumber,
          otpCode: value.otp,
        });
      } else {
        //@ts-ignore
        if (!window.confirmationResult) {
          message.error('Có lỗi xảy ra, vui lòng thử lại !');
          setStep(1);
          return;
        }
        //@ts-ignore
        window.confirmationResult
          .confirm(value.otp)
          .then((result: any) => {
            message.success('Thành công');
            setStep(3);
          })
          .catch((error: any) => {
            message.error('Bạn đã nhập sai OTP');
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } else {
      verifyOTPEmail.run({
        email: email,
        otp: value?.otp,
      });
    }
  };

  const onFinishStep3 = async (value: any) => {
    // setLoading(true);
    // setValueSubmit({
    //   ...valueSubmit,
    //   password: value?.password,
    // });
    // setStep(4);
    // setLoading(false);
    const sessionIdentifier = localStorage.getItem('SESSION_KEY');
    if (sessionIdentifier) {
      requestCreateStore.run({
        password: value?.password,
        session: sessionIdentifier,
      });
    }
  };

  const onFinishStep4 = (value: any) => {
    // setValueSubmit((state) => ({
    //   ...state,
    //   ...value,
    //   register_create_gift: value.register_create_gift ? true : false,
    //   register_pickup_gift_location: value.register_pickup_gift_location
    //     ? true
    //     : false,
    //   register_release_voucher: value.register_release_voucher ? true : false,
    // }));
    // setStep(4.5);
    // setLoading(false);
    updateStore.run(value, requestCreateStore.data?.data?.token);
  };

  const onFinishStep4_5 = (value: any) => {
    requestCreateEnterprise.run({
      ...valueSubmit,
      ...value,
    });

    // setLoading(false);
  };

  const onFinishStep5 = async (value: any) => {
    requestMakeInvite.runAsync(value, currentToken);
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <Step1
            dialCode={dialCode}
            setDialCode={setDialCode}
            option={option}
            setOption={setOption}
            onFinishStep1={onFinishStep1}
            loading={loading}
          />
        );
        break;
      case 2:
        return (
          <Step2
            isVNDialcode={dialCode === '+84' && option === 'phone'}
            onFinishStep2={onFinishStep2}
            resendOTP={nextToOtp}
            currentIdent={option === 'phone' ? phoneNumber : email}
            option={option}
            loading={loading}
          />
        );
        break;
      case 3:
        return (
          <Step3
            onFinishStep3={onFinishStep3}
            // currentPhone={phoneNumber}
            loading={requestCreateStore.loading}
          />
        );
        break;
      case 4:
        return (
          <Step4
            token={requestCreateStore.data?.data?.token}
            value={valueSubmit}
            currentIdent={option === 'phone' ? phoneNumber : email}
            option={option}
            onFinishStep4={onFinishStep4}
            loading={updateStore.loading}
          />
        );
        break;
      case 4.5:
        return (
          <Step4_5
            loading={requestCreateEnterprise.loading}
            backSreen={backScreen}
            onFinishStep4_5={onFinishStep4_5}
          />
        );
        break;
      case 5:
        return (
          <Step5
            onFinishStep5={onFinishStep5}
            currentPhone={phoneNumber}
            loading={loading}
            currentToken={currentToken}
          />
        );
        break;

      default:
        return (
          <Step1
            dialCode={dialCode}
            setDialCode={setDialCode}
            option={option}
            setOption={setOption}
            onFinishStep1={onFinishStep1}
            loading={loading}
          />
        );
        break;
    }
  };

  return (
    <>
      <div id="recaptcha-container"></div>
      {renderStep()}
      {/* <Step4
        value={valueSubmit}
        currentIdent={option === 'phone' ? phoneNumber : email}
        option={option}
        onFinishStep4={onFinishStep4}
        loading={loading}
      /> */}
    </>
  );
};

export default Register;
