export enum StatusKyc {
  NOT_REQUEST = 'NOT_REQUEST',
  PENDING = 'PENDING',
  ACCEPT = 'ACCEPT',
  REFUSE = 'REFUSE',
}
export enum ROLE {
  ADMIN = 'ADMIN',
  SUPER_ADMIN = 'SUPER_ADMIN',
}

export enum StatusAccount {
  ACTIVE = 'true',
  INACTIVE = 'false',
}
export enum Applicable {
  ALL = 'ALL',
  USER = 'USER',
}
export enum DisplayType {
  ON = 'ON',
  OFF = 'OFF',
}

export enum BusinessType {
  FOOD_DRINK = 'FOOD_DRINK',
  RESTAURANT = 'RESTAURANT',
  HOTEL = 'HOTEL',
  FASHION = 'FASHION',
  GROCERY = 'GROCERY',
  MART = 'MART',
  HEALTHCARE = 'HEALTHCARE',
  HOUSEWARE = 'HOUSEWARE',
}

export enum BusinessScale {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}
export enum AverageInvoice {
  FROM50TO200 = 'FROM50TO200',
  FROM200TO500 = 'FROM200TO500',
  FROM500TO1000 = 'FROM500TO1000',
  FROM1000TO3000 = 'FROM1000TO3000',
  OVER3000 = 'OVER3000',
}

export enum PaymentType {
  VTC_PAY = 'VTCPay',
  DOMESTIC_BANK = 'DomesticBank',
  INTERNATIONAL_CARD = 'InternationalCard',
}

export enum KycType {
  IDENTIFY_CARD = 'IDENTIFY_CARD',
  PASSPORT = 'PASSPORT',
  DRIVING_LICENSE = 'DRIVING_LICENSE',
}

export enum KycPhotoType {
  FRONT_PHOTO = 'FRONT_PHOTO',
  BACK_PHOTO = 'BACK_PHOTO',
}
export enum MerchantType {
  APP = 'APP',
  WEBSITE = 'WEBSITE',
}
export enum TypeTemplateNoti {
  USER = 'USER',
  TOPIC = 'TOPIC',
}

//locamos

export enum PERMISSIONS {
  NONE = 'NONE',
  READ = 'READ',
  FULL = 'FULL',
}
export enum CUSTOMER_REQUEST_TYPE {
  UNDEFINE = 'UNDEFINE',
  CHECKIN = 'CHECKIN',
  GET_BONUS_PENDING = 'GET_BONUS_PENDING',
  GET_BONUS_SUCCESS = 'GET_BONUS_SUCCESS',
  GET_BONUS_FAILED = 'GET_BONUS_FAILED',
}
export enum AdminConfirmStatus {
  REQUESTED = 'REQUESTED',
  CANCELED = 'CANCELED',
  NOT_YET_STARTED = 'NOT_YET_STARTED',
  FINISHED = 'FINISHED',
  STARTED = 'STARTED',
}
export enum LocaVoucherStatus {
  SUCCESS = 'SUCCESS',
  RECEIVED = 'RECEIVED',
  TRANSFERED = 'TRANSFERED',
  FAILED = 'FAILED',
}
export enum DiscountType {
  PRODUCT_DISCOUNT = 'PRODUCT_DISCOUNT',
  BILL_DISCOUNT = 'BILL_DISCOUNT',
}
export enum TypeOfAccount {
  ENTERPRISE = 'ENTERPRISE',
  PERSONAL = 'PERSONAL',
  COLLABORATOR = 'COLLABORATOR',
}
export enum TypeOfTransaction {
  USE_LOCAVOUCHER = 'USE_LOCAVOUCHER',
  TRANSFER_LOCAVOUCHER = 'TRANSFER_LOCAVOUCHER',
  RECEIVE_LOCAVOUCHER = 'RECEIVE_LOCAVOUCHER',
}
