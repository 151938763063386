import { privateRequest, request, API_PATH } from '@/utils/apis';

export const onSubmitValue = (data: any) => {
  return privateRequest(request.post, API_PATH.default, {
    data,
  });
};

export const getStaffAccount = (id: any) => {
  const query = `
  mutation {
    get_staff_detail(staff_id: ${id}) {
      staff_id
      name
      phone
      email
      status
    }
  }
  `;
  return privateRequest(request.post, API_PATH.default, {
    data: {
      query,
    },
  });
};
