import { useTranslate } from '@/utils/hooks/useTranslate';
import {
  CheckOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  LeftOutlined,
} from '@ant-design/icons';
import { useToggle } from 'ahooks';
import {
  Breadcrumb,
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Skeleton,
  Switch,
  Tag,
  Upload,
  UploadFile,
} from 'antd';
import { history, Link, useIntl, useParams, useRequest } from 'umi';
import styles from './index.less';
import {
  GoogleMap,
  InfoWindow,
  Marker,
  StandaloneSearchBox,
  useLoadScript,
} from '@react-google-maps/api';
import React, { useEffect, useState } from 'react';
import {
  AVERAGE_INVOICE,
  BUSINESSS_SCALE,
  BUSINESS_TYPE,
  ENVIRONMENTS,
} from '@/utils/constant';
import QRCode from 'react-qr-code';
import { getBusinessAccount, getListOrgs, onSubmitValue } from './service';
import UploadEnterpriseInfo from '@/components/UploadEnterpriseInfo';
import LisenceImage from './Component/LisenceImage';
import { API_PATH } from '@/utils/apis';
import { RcFile } from 'antd/lib/upload';
import MapReaflat from '@/components/MapReaflat';

interface INfoBusiness {
  name: string;
  address: string;
  create_at: string;
  email: string;
  enterprise_id: string;
  lat: number;
  long: number;
  phone: string;
  qr_code_string: string;
  status: boolean;
  enterprise_image: string;
  license_image: string;
  identity_backside?: string;
  identity_front?: string;
  passport_image?: string;
  business_type: string;
  bill_template: string;
  verified: boolean;
  verify_status: string;
  refuse_reson: string;
  qr_transfer: string;
  logo: string;
  province_id: string;
}
const PersonalInfo = ({ infoAccount }: any) => {
  const [value, setValue] = useState<INfoBusiness | null>(null);
  const [disabled, setDisabled] = useState(false);
  const [disableQRCode, setDisableQRCode] = useState(false);
  const [fileList, setFileList] = useState<{
    enterprise_image: UploadFile[];
    license_image: UploadFile[];
    identity_backside: UploadFile[];
    identity_front: UploadFile[];
    passport_image: UploadFile[];
    bill_template: UploadFile[];
    qr_transfer: UploadFile[];
    logo: UploadFile[];
  }>({
    enterprise_image: [],
    license_image: [],
    identity_backside: [],
    identity_front: [],
    passport_image: [],
    bill_template: [],
    qr_transfer: [],
    logo: [],
  });
  const { formatMessage } = useIntl();
  const requestOrgs = useRequest(getListOrgs);
  const [identityType, setIdentityType] = useState(1);
  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  const getBusinessRequest = useRequest(getBusinessAccount, {
    manual: true,
    onSuccess: (result: any) => {
      const data = result.get_my_enterprise_detail;
      const infoRes: INfoBusiness = {
        name: data.name,
        address: data.address,
        create_at: data.create_at,
        email: data.email,
        enterprise_id: data.enterprise_id,
        lat: data.lat,
        long: data.long,
        phone: data.phone,
        qr_code_string: data.qr_code_string,
        status: data.status,
        ...data,
      };
      setValue(infoRes);
    },
  });

  useEffect(() => {
    getBusinessRequest.run();
  }, []);
  const info = () => {
    Modal.info({
      title: 'QR code',
      content: (
        <div className={styles.qrcode_info}>
          <QRCode
            size={256}
            style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
            value={`${value?.qr_code_string}`}
            viewBox={`0 0 256 256`}
          />
        </div>
      ),
      onOk() {},
    });
  };

  ///MAP
  const [pickerPosition, setPickerPosition] = useState<{
    lat?: number;
    lng?: number;
    customName?: string;
  } | null>(null);

  const [position, setPosition] = useState<{
    lat?: number;
    lng?: number;
    customName?: string;
  }>({});

  const [searchBoxRef, setSearchBoxRef] = useState(null);

  const [dataSearch, setDataSearch] = useState<{
    lat?: number;
    lng?: number;
  } | null>(null);

  React.useEffect(() => {
    if (!!value && !!value.lat && !!value.long) {
      setDataSearch({
        lat: value.lat,
        lng: value.long,
      });
      setPosition({
        lat: value.lat,
        lng: value.long,
      });
      const geocode = new window.google.maps.Geocoder();
      geocode.geocode(
        {
          location: { lat: value.lat, lng: value.long },
        },
        (result, status) => {
          if (result && status == google.maps.GeocoderStatus.OK) {
            setPickerPosition({
              lat: value.lat,
              lng: value.long,
              customName: result[0].formatted_address,
            });
          }
        },
      );
    }

    if (!!value && !!value.bill_template && value.verify_status !== 'REFUSE') {
      setDisabled(true);
      if (value?.passport_image) {
        setIdentityType(2);
        setFileList((state) => ({
          ...state,
          passport_image: [
            {
              uid: '-5',
              name: 'image4.png',
              status: 'done',
              url: `${ENVIRONMENTS.BUCKET_NAME + '/' + value?.passport_image}`,
            },
          ],
        }));
      } else {
        setFileList((state) => ({
          ...state,
          identity_backside: [
            {
              uid: '-3',
              name: 'image2.png',
              status: 'done',
              url: `${
                ENVIRONMENTS.BUCKET_NAME + '/' + value?.identity_backside
              }`,
            },
          ],
          identity_front: [
            {
              uid: '-4',
              name: 'image3.png',
              status: 'done',
              url: `${ENVIRONMENTS.BUCKET_NAME + '/' + value?.identity_front}`,
            },
          ],
        }));
      }
      setFileList((state) => ({
        ...state,
        enterprise_image: [
          {
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: `${ENVIRONMENTS.BUCKET_NAME + '/' + value?.enterprise_image}`,
          },
        ],
        license_image: [
          {
            uid: '-2',
            name: 'image1.png',
            status: 'done',
            url: `${ENVIRONMENTS.BUCKET_NAME + '/' + value?.license_image}`,
          },
        ],

        bill_template: [
          {
            uid: '-6',
            name: 'image5.png',
            status: 'done',
            url: `${ENVIRONMENTS.BUCKET_NAME + '/' + value?.bill_template}`,
          },
        ],
      }));
      if (!!value.qr_transfer) {
        setDisableQRCode(true);
        setFileList((state) => ({
          ...state,
          qr_transfer: [
            {
              uid: '-7',
              name: 'image5.png',
              status: 'done',
              url: `${ENVIRONMENTS.BUCKET_NAME + '/' + value?.qr_transfer}`,
            },
          ],
        }));
      }
    } else if (!!value && !!value.enterprise_image && !value.bill_template) {
      if (value?.passport_image) {
        setIdentityType(2);
        setFileList((state) => ({
          ...state,
          passport_image: [
            {
              uid: '-5',
              name: 'image4.png',
              status: 'done',
              url: `${ENVIRONMENTS.BUCKET_NAME + '/' + value?.passport_image}`,
            },
          ],
        }));
      } else {
        setFileList((state) => ({
          ...state,
          identity_backside: [
            {
              uid: '-3',
              name: 'image2.png',
              status: 'done',
              url: `${
                ENVIRONMENTS.BUCKET_NAME + '/' + value?.identity_backside
              }`,
            },
          ],
          identity_front: [
            {
              uid: '-4',
              name: 'image3.png',
              status: 'done',
              url: `${ENVIRONMENTS.BUCKET_NAME + '/' + value?.identity_front}`,
            },
          ],
        }));
      }
      setFileList((state) => ({
        ...state,
        enterprise_image: [
          {
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: `${ENVIRONMENTS.BUCKET_NAME + '/' + value?.enterprise_image}`,
          },
        ],
        license_image: [
          {
            uid: '-2',
            name: 'image1.png',
            status: 'done',
            url: `${ENVIRONMENTS.BUCKET_NAME + '/' + value?.license_image}`,
          },
        ],
      }));
    }
    if (value?.logo) {
      setFileList((state) => ({
        ...state,
        logo: [
          {
            uid: '-8',
            name: 'image6.png',
            status: 'done',
            url: `${ENVIRONMENTS.BUCKET_NAME + '/' + value?.logo}`,
          },
        ],
      }));
    }
  }, [value]);

  const loadScript = useLoadScript({
    googleMapsApiKey: ENVIRONMENTS.DEFAULT_API_MAP as string,
    libraries: ['drawing', 'places'],
  });

  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const handleCancel = () => {
    history.push('/');
  };
  const params = useParams<{ id: string }>();

  const [form] = Form.useForm();

  const [editable, setEditable] = useToggle(false);
  const { t } = useTranslate();

  const updateRequest = useRequest(onSubmitValue, {
    manual: true,
    onSuccess(data) {
      console.log(data);

      // localStorage.setItem
      const local = JSON.parse(
        localStorage.getItem(
          ENVIRONMENTS.LOCAL_STORAGE_KEY as string,
        ) as string,
      );
      window?.localStorage.setItem(
        ENVIRONMENTS.LOCAL_STORAGE_KEY as string,
        JSON.stringify({
          ...local,
          display_name: data?.update_my_enterprise_detail?.name,
          updated_lisence: true,
        }),
      );

      message.success(t('message_user_success'));
      setTimeout(() => {
        window.location.reload();
      }, 500);
    },
  });

  const onFinish = (values: any) => {
    const data = {
      query: ` 
      mutation {
        update_my_enterprise_detail(input: {
          name:"${values?.name}"
          address:"${values?.address}"
          brand_name:"${values?.brand_name}"
          tax_code:"${values?.tax_code}"
          business_type:${values?.business_type}
          scale:${values?.scale}
          average_invoice:${values?.average_invoice}
					limit_to_give_lb: ${values?.limit_to_give_lb}
					register_create_gift: ${values?.register_create_gift}
					register_pickup_gift_location: ${values?.register_pickup_gift_location}
					register_release_voucher: ${values?.register_release_voucher}
          bank:"${values?.bank}"
          bank_account_name:"${values?.bank_account_name}"
          bank_account_number:"${values?.bank_account_number}"
          owner_name:"${values?.owner_name}"
					province_id:"${values?.province_id}"
					description:"${
            values?.description?.includes(`\n`)
              ? `""${values.description?.replace(/"/g, '\\"')}""`
              : `${
                  !!values?.description
                    ? values.description?.replace(/"/g, '\\"')
                    : ''
                }`
          }"
          ${
            values.enterprise_image?.file
              ? `enterprise_image: "${values.enterprise_image?.file?.response.path}"`
              : `${
                  values.enterprise_image
                    ? `enterprise_image: "${values.enterprise_image}"`
                    : ''
                }`
          }
          ${
            values.license_image?.file
              ? `license_image: "${values.license_image?.file?.response.path}"`
              : `${
                  values.license_image
                    ? `license_image: "${values.license_image}"`
                    : ''
                }`
          }
          ${
            values.identity_front?.file
              ? `identity_front: "${values.identity_front?.file?.response.path}"`
              : `${
                  values.identity_front
                    ? `identity_front: "${values.identity_front}"`
                    : ''
                }`
          }
          ${
            values.identity_backside?.file
              ? `identity_backside: "${values.identity_backside?.file?.response.path}"`
              : `${
                  values.identity_backside
                    ? `identity_backside: "${values.identity_backside}"`
                    : ''
                }`
          }
          ${
            values.bill_template?.file
              ? `bill_template: "${values.bill_template?.file?.response.path}"`
              : `${
                  values.passport
                    ? `bill_template: "${values.bill_template}"`
                    : ''
                }`
          }
					${
            values.qr_transfer?.file
              ? `qr_transfer: "${values.qr_transfer?.file?.response.path}"`
              : `${
                  values.passport ? `qr_transfer: "${values.qr_transfer}"` : ''
                }`
          }
          ${
            values.passport_image?.file
              ? `passport_image: "${values.passport_image?.file?.response.path}"`
              : `${
                  values.passport
                    ? `passport_image: "${values.passport_image}"`
                    : ''
                }`
          }
					${
            values.logo?.file
              ? `logo: "${values.logo?.file?.response.path}"`
              : `${values.passport ? `logo: "${values.logo}"` : ''}`
          }
        }) {
          name
          email
          phone
          address
          lat
          long
          qr_code_string
        }
      }
          
    `,
    };

    updateRequest.run(data);
  };

  return loadScript.isLoaded ? (
    <div id="section-1">
      <Breadcrumb>
        <Breadcrumb.Item className={styles.breacrumbItem}>
          <div
            onClick={() => {
              history.goBack();
            }}
            className={styles.previousEditLink}
          >
            <LeftOutlined />
            <div>Cập nhật tài khoản doanh nghiệp</div>
          </div>
        </Breadcrumb.Item>
      </Breadcrumb>
      {!value ? (
        <Skeleton active />
      ) : (
        <Form
          form={form}
          layout="horizontal"
          onKeyDown={(e) => {
            if (e.key === 'Enter') e.preventDefault();
          }}
          className={styles.mainInfoUser}
          initialValues={{
            ...value,
          }}
          autoComplete="off"
          onFinish={onFinish}
          labelWrap
          labelAlign="left"
          labelCol={{
            span: 5,
          }}
        >
          <Row
            justify="space-between"
            style={{
              margin: '15px 0',
            }}
          >
            <h2>Thông tin doanh nghiệp</h2>

            <p className={styles.qrcode} onClick={info}>
              Mã QR code doanh nghiệp
            </p>
          </Row>
          <Form.Item label="Trạng thái">
            {value?.verified ? (
              <Tag color="green" icon={<CheckOutlined />}>
                Đã xác thực
              </Tag>
            ) : value?.verify_status === 'PENDING' ? (
              <Tag color="warning" icon={<ClockCircleOutlined />}>
                Đang chờ xác thực
              </Tag>
            ) : (
              <>
                <Tag color="red" icon={<CloseOutlined />}>
                  Từ chối xác thực
                </Tag>{' '}
                Lý do: {value.refuse_reson}
              </>
            )}
          </Form.Item>
          <Row>
            <Col xs={24}>
              <Form.Item
                name="name"
                label={t('const_column_business_name')}
                rules={[
                  {
                    required: true,
                    message: t('error.require', {
                      field: t('const_column_business_name'),
                    }),
                  },
                ]}
              >
                <Input placeholder={t('const_column_business_name')} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <Form.Item
                name="phone"
                label="SĐT"
                rules={[
                  {
                    required: true,
                    message: t('error.require', {
                      field: 'Số điện thoại',
                    }),
                  },
                ]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col xs={24}>
              <Form.Item
                name="email"
                label={t('email')}
                rules={[
                  {
                    required: true,
                    message: t('error.require', {
                      field: t('email'),
                    }),
                  },
                  {
                    type: 'email',
                    message: t('error.email', {
                      field: t('email'),
                    }),
                  },
                ]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name="tax_code"
            label={t('tax_code')}
            rules={[
              {
                required: true,
                message: t('required'),
              },
            ]}
          >
            <Input disabled={disabled} placeholder={t('tax_code_input')} />
          </Form.Item>
          <Form.Item
            name="brand_name"
            label={t('brand_name')}
            rules={[
              {
                required: true,
                message: t('required'),
              },
            ]}
          >
            <Input disabled={disabled} placeholder={t('brand_name')} />
          </Form.Item>
          <Form.Item
            label={formatMessage({ id: 'business_type' })}
            name="business_type"
            rules={[
              {
                required: true,
                message: t('required'),
              },
            ]}
          >
            <Select
              disabled={disabled}
              placeholder={t('business_type_input')}
              options={BUSINESS_TYPE.map((item) => ({
                label: t(item.name),
                value: item.value,
                key: item.value,
              }))}
            />
          </Form.Item>

          <Form.Item
            label={formatMessage({ id: 'scale' })}
            name="scale"
            rules={[
              {
                required: true,
                message: t('required'),
              },
            ]}
          >
            <Select
              disabled={disabled}
              placeholder={t('scale_input')}
              options={BUSINESSS_SCALE.map((item) => ({
                label: t(item.name),
                value: item.value,
                key: item.value,
              }))}
            />
          </Form.Item>

          <Form.Item
            label={formatMessage({ id: 'average_invoice' })}
            name="average_invoice"
            rules={[
              {
                required: true,
                message: t('required'),
              },
            ]}
          >
            <Select
              disabled={disabled}
              placeholder={t('average_invoice_input')}
              options={AVERAGE_INVOICE.map((item) => ({
                label: t(item.name),
                value: item.value,
                key: item.value,
              }))}
            />
          </Form.Item>
          <Form.Item
            label={formatMessage({ id: 'limit_to_give_lb' })}
            name="limit_to_give_lb"
            rules={[
              {
                required: true,
                message: t('required'),
              },
            ]}
          >
            <InputNumber
              controls={false}
              disabled={!value?.verified}
              style={{
                width: '100%',
              }}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              addonAfter="VND"
              min={1}
            />
          </Form.Item>
          <div
            style={{
              margin: '20px 0',
            }}
          >
            <Form.Item>
              <Checkbox disabled={disabled} value={true} checked={true}>
                Đăng ký làm địa điểm Locabiz
              </Checkbox>
            </Form.Item>
            <Form.Item name="register_create_gift" valuePropName="checked">
              <Checkbox
                disabled={disabled}
                defaultChecked={false}
                checked={false}
              >
                Đăng ký thả quà
              </Checkbox>
            </Form.Item>
            <Form.Item
              name="register_pickup_gift_location"
              valuePropName="checked"
            >
              <Checkbox
                disabled={disabled}
                defaultChecked={false}
                checked={false}
              >
                Đăng ký địa điểm nhận quà
              </Checkbox>
            </Form.Item>
            <Form.Item name="register_release_voucher" valuePropName="checked">
              <Checkbox
                disabled={disabled}
                defaultChecked={false}
                checked={false}
              >
                Đăng ký phát hành voucher
              </Checkbox>
            </Form.Item>
          </div>
          <Form.Item
            className="uploadItem"
            name="logo"
            label="Logo"
            getValueFromEvent={(e) => normFile(e)}
          >
            <Upload
              disabled={value?.logo ? disabled : false}
              accept=".png,.jpg,.jpeg"
              action={
                ENVIRONMENTS.API_URL +
                `${
                  ENVIRONMENTS.DEV_ENV ? API_PATH.UPLOAD_DEV : API_PATH.UPLOADV2
                }`
              }
              onPreview={onPreview}
              fileList={fileList.logo}
              listType="picture-card"
              onChange={(val: any) => {
                setFileList((state: any) => ({
                  ...state,
                  logo: val.fileList,
                }));
              }}
              onRemove={() => {
                setFileList((state: any) => ({
                  ...state,
                  logo: [],
                }));
              }}
            >
              {fileList.logo.length < 1 && 'Tải lên'}
            </Upload>
          </Form.Item>
          <Form.Item
            name="description"
            label="Mô tả ngắn"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input.TextArea rows={2} disabled={disabled} />
          </Form.Item>
          <Row>
            <Col xs={24}>
              <Form.Item
                name="address"
                label={t('address')}
                rules={[
                  {
                    required: true,
                    message: t('error.require', {
                      field: t('address'),
                    }),
                  },
                ]}
              >
                {/* @ts-ignore */}
                <Input
                  placeholder={t('address')}
                  value={pickerPosition?.customName}
                  // disabled={true}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label="Khu vực" name="province_id">
            <Select
              disabled={value?.province_id ? disabled : false}
              placeholder="Chọn khu vực"
              options={requestOrgs.data?.get_all_orgs?.map((item: any) => ({
                label: item.name,
                value: item.id,
              }))}
            />
          </Form.Item>
          <Row
            style={{
              textAlign: 'center',
            }}
          >
            <Col xs={24}>
              <Form.Item name="map">
                <MapReaflat
                  disable={true}
                  setPos={() => {}}
                  listPosition={[]}
                  loading={false}
                  mapItemDetail={value}
                />
              </Form.Item>
              <Row
                gutter={16}
                style={{
                  marginTop: '10px',
                }}
              >
                <Col span={12} className={styles.dialogFormItem}>
                  <Form.Item label="Lat">
                    <Input value={position.lat} disabled={!editable} />
                  </Form.Item>
                </Col>
                <Col span={12} className={styles.dialogFormItem}>
                  <Form.Item label="Long">
                    <Input value={position.lng} disabled={!editable} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>

          <div className={styles.lisenceImage}>
            <Row>
              <Col flex="1 0 auto">
                <Form.Item
                  className="uploadItem"
                  name="enterprise_image"
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng chọn ảnh',
                    },
                  ]}
                >
                  <Upload
                    disabled={disabled}
                    accept=".png,.jpg,.jpeg"
                    action={
                      ENVIRONMENTS.API_URL +
                      `${
                        ENVIRONMENTS.DEV_ENV
                          ? API_PATH.UPLOAD_DEV
                          : API_PATH.UPLOADV2
                      }`
                    }
                    onPreview={onPreview}
                    fileList={fileList.enterprise_image}
                    listType="picture-card"
                    onChange={(val: any) => {
                      setFileList((state: any) => ({
                        ...state,
                        enterprise_image: val.fileList,
                      }));
                    }}
                    onRemove={() => {
                      setFileList((state: any) => ({
                        ...state,
                        enterprise_image: [],
                      }));
                    }}
                  >
                    {fileList.enterprise_image.length < 1 && 'Tải lên'}
                  </Upload>
                </Form.Item>
                <p>Ảnh chụp cửa hàng</p>
              </Col>
              <Col flex="1 0 auto">
                <Form.Item
                  className="uploadItem"
                  name="license_image"
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng chọn ảnh',
                    },
                  ]}
                >
                  <Upload
                    disabled={disabled}
                    accept=".png,.jpg,.jpeg"
                    action={
                      ENVIRONMENTS.API_URL +
                      `${
                        ENVIRONMENTS.DEV_ENV
                          ? API_PATH.UPLOAD_DEV
                          : API_PATH.UPLOADV2
                      }`
                    }
                    onPreview={onPreview}
                    fileList={fileList.license_image}
                    listType="picture-card"
                    onChange={(val: any) => {
                      setFileList((state: any) => ({
                        ...state,
                        license_image: val.fileList,
                      }));
                    }}
                    onRemove={() => {
                      setFileList((state: any) => ({
                        ...state,
                        license_image: [],
                      }));
                    }}
                  >
                    {fileList.license_image.length < 1 && 'Tải lên'}
                  </Upload>
                </Form.Item>
                <p>Ảnh đăng ký kinh doanh</p>
              </Col>
            </Row>

            <Row justify="center">
              <Col flex="1 0 auto">
                <Form.Item
                  className="uploadItem"
                  name="bill_template"
                  style={{
                    margin: '10px 0',
                  }}
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng chọn ảnh',
                    },
                  ]}
                >
                  <Upload
                    disabled={disabled}
                    accept=".png,.jpg,.jpeg"
                    action={
                      ENVIRONMENTS.API_URL +
                      `${
                        ENVIRONMENTS.DEV_ENV
                          ? API_PATH.UPLOAD_DEV
                          : API_PATH.UPLOADV2
                      }`
                    }
                    onPreview={onPreview}
                    fileList={fileList.bill_template}
                    listType="picture-card"
                    onChange={(val: any) => {
                      setFileList((state: any) => ({
                        ...state,
                        bill_template: val.fileList,
                      }));
                    }}
                    onRemove={() => {
                      setFileList((state: any) => ({
                        ...state,
                        bill_template: [],
                      }));
                    }}
                  >
                    {fileList.bill_template.length < 1 && 'Tải lên'}
                  </Upload>
                </Form.Item>
                <p>Ảnh hóa đơn</p>
              </Col>
              <Col flex="1 0 auto">
                <Form.Item
                  className="uploadItem"
                  name="qr_transfer"
                  style={{
                    margin: '10px 0',
                  }}
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng chọn ảnh',
                    },
                  ]}
                >
                  <Upload
                    disabled={disableQRCode}
                    accept=".png,.jpg,.jpeg"
                    action={
                      ENVIRONMENTS.API_URL +
                      `${
                        ENVIRONMENTS.DEV_ENV
                          ? API_PATH.UPLOAD_DEV
                          : API_PATH.UPLOADV2
                      }`
                    }
                    onPreview={onPreview}
                    fileList={fileList.qr_transfer}
                    listType="picture-card"
                    onChange={(val: any) => {
                      setFileList((state: any) => ({
                        ...state,
                        qr_transfer: val.fileList,
                      }));
                    }}
                    onRemove={() => {
                      setFileList((state: any) => ({
                        ...state,
                        qr_transfer: [],
                      }));
                    }}
                  >
                    {fileList.qr_transfer.length < 1 && 'Tải lên'}
                  </Upload>
                </Form.Item>
                <p>Ảnh QR chuyển khoản</p>
              </Col>
            </Row>
          </div>

          <h2 className={styles.subtitle}>Thông tin ngân hàng</h2>
          <Form.Item
            name="bank_account_name"
            label={t('bank_account_name')}
            rules={[
              {
                required: true,
                message: t('required'),
              },
            ]}
          >
            <Input disabled={disabled} />
          </Form.Item>
          <Form.Item
            name="bank"
            label={t('bank')}
            rules={[
              {
                required: true,
                message: t('required'),
              },
            ]}
          >
            <Input disabled={disabled} />
          </Form.Item>
          <Form.Item
            name="bank_account_number"
            label={t('bank_account_number')}
            rules={[
              {
                required: true,
                message: t('required'),
              },
            ]}
          >
            <Input disabled={disabled} />
          </Form.Item>
          <h2 className={styles.subtitle}>Thông tin doanh nghiệp</h2>
          <Form.Item
            name="owner_name"
            label={t('owner_name')}
            rules={[
              {
                required: true,
                message: t('required'),
              },
            ]}
          >
            <Input disabled={disabled} />
          </Form.Item>

          <div className={styles.identityUpload}>
            <Row
              align="middle"
              className={styles.identityType}
              style={{
                margin: '15px 0',
              }}
            >
              <span className={styles.identityTypeLabel}>
                Giấy tờ của chủ quán:
              </span>
              <Select
                style={{
                  marginLeft: '18px',
                }}
                disabled={disabled}
                className={styles.selectIdentityType}
                onChange={(val) => setIdentityType(val)}
                value={identityType}
                options={[
                  {
                    label: 'CCCD/CMND',
                    value: 1,
                  },
                  {
                    label: 'Hộ chiếu',
                    value: 2,
                  },
                ]}
              />
            </Row>
            {identityType === 1 ? (
              <Row
                wrap
                style={{
                  textAlign: 'center',
                }}
              >
                <Col flex="1 0 auto">
                  <Form.Item
                    className="uploadItem"
                    name="identity_front"
                    rules={[
                      {
                        required: identityType === 1 ? true : false,
                        message: 'Vui lòng chọn ảnh',
                      },
                    ]}
                  >
                    <Upload
                      disabled={disabled}
                      accept=".png,.jpg,.jpeg"
                      action={
                        ENVIRONMENTS.API_URL +
                        `${
                          ENVIRONMENTS.DEV_ENV
                            ? API_PATH.UPLOAD_DEV
                            : API_PATH.UPLOADV2
                        }`
                      }
                      onPreview={onPreview}
                      fileList={fileList.identity_front}
                      listType="picture-card"
                      onChange={(val: any) => {
                        setFileList((state: any) => ({
                          ...state,
                          identity_front: val.fileList,
                        }));
                      }}
                      onRemove={() => {
                        setFileList((state: any) => ({
                          ...state,
                          identity_front: [],
                        }));
                      }}
                    >
                      {fileList.identity_front.length < 1 && 'Tải lên'}
                    </Upload>
                  </Form.Item>
                  <p>Mặt trước</p>
                </Col>
                <Col flex="1 0 auto">
                  <Form.Item
                    className="uploadItem"
                    name="identity_backside"
                    rules={[
                      {
                        required: identityType === 1 ? true : false,
                        message: 'Vui lòng chọn ảnh',
                      },
                    ]}
                  >
                    <Upload
                      disabled={disabled}
                      accept=".png,.jpg,.jpeg"
                      action={
                        ENVIRONMENTS.API_URL +
                        `${
                          ENVIRONMENTS.DEV_ENV
                            ? API_PATH.UPLOAD_DEV
                            : API_PATH.UPLOADV2
                        }`
                      }
                      onPreview={onPreview}
                      fileList={fileList.identity_backside}
                      listType="picture-card"
                      onChange={(val: any) => {
                        setFileList((state: any) => ({
                          ...state,
                          identity_backside: val.fileList,
                        }));
                      }}
                      onRemove={() => {
                        setFileList((state: any) => ({
                          ...state,
                          identity_backside: [],
                        }));
                      }}
                    >
                      {fileList.identity_backside.length < 1 && 'Tải lên'}
                    </Upload>
                  </Form.Item>
                  <p>Mặt sau</p>
                </Col>
              </Row>
            ) : (
              <Row
                style={{
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Form.Item
                  className="uploadItem"
                  name="passport_image"
                  rules={[
                    {
                      required: identityType === 2 ? true : false,
                      message: 'Vui lòng chọn ảnh',
                    },
                  ]}
                >
                  <Upload
                    disabled={disabled}
                    accept=".png,.jpg,.jpeg"
                    action={
                      ENVIRONMENTS.API_URL +
                      `${
                        ENVIRONMENTS.DEV_ENV
                          ? API_PATH.UPLOAD_DEV
                          : API_PATH.UPLOADV2
                      }`
                    }
                    onPreview={onPreview}
                    fileList={fileList.passport_image}
                    listType="picture-card"
                    onChange={(val: any) => {
                      setFileList((state: any) => ({
                        ...state,
                        passport_image: val.fileList,
                      }));
                    }}
                    onRemove={() => {
                      setFileList((state: any) => ({
                        ...state,
                        passport_image: [],
                      }));
                    }}
                  >
                    {fileList.passport_image.length < 1 && 'Tải lên'}
                  </Upload>
                </Form.Item>
                <p
                  style={{
                    textAlign: 'center',
                  }}
                >
                  Hộ chiếu
                </p>
              </Row>
            )}
          </div>

          <Row
            align="middle"
            justify="center"
            className={styles.submitButtonGroup}
          >
            <Col>
              <Button
                danger
                onClick={handleCancel}
                className={styles.addButton}
              >
                {t('general_cancel')}
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                loading={updateRequest.loading}
                className={styles.addButton}
              >
                {t('general_save')}
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </div>
  ) : null;
};

export default PersonalInfo;
