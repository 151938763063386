import { DataNode } from 'antd/lib/tree';
import {
  AdminConfirmStatus,
  Applicable,
  AverageInvoice,
  BusinessScale,
  BusinessType,
  DiscountType,
  DisplayType,
  LocaVoucherStatus,
  PaymentType,
  PERMISSIONS,
  StatusKyc,
  TypeOfAccount,
  TypeOfTransaction,
  TypeTemplateNoti,
} from '../enum';

interface IObjectProperty {
  name: string;
  value: string | boolean | number | null;
}
export interface IPagination {
  current: number;
  pageSize: number;
}
export const STATUS_ACTIVE: IObjectProperty[] = [
  { name: 'general_status_active', value: '' },
  { name: 'general_active', value: 'ACTIVE' },
  { name: 'general_inactive', value: 'INACTIVE' },
];
export enum CUSTOMER_REQUEST_TYPE {
  UNDEFINE = 'UNDEFINE',
  CHECKIN = 'CHECKIN',
  GET_BONUS_PENDING = 'GET_BONUS_PENDING',
  GET_BONUS_SUCCESS = 'GET_BONUS_SUCCESS',
  GET_BONUS_FAILED = 'GET_BONUS_FAILED',
}

export const STATUS_POINT_GIVING: IObjectProperty[] = [
  { name: 'general_point_pending', value: 'GET_BONUS_PENDING' },
  { name: 'general_point_success', value: 'GET_BONUS_SUCCESS' },
  { name: 'general_point_fail', value: 'GET_BONUS_FAILED' },
];

export const STATUS_VOUCHER: IObjectProperty[] = [
  {
    name: 'general_voucher_enterprise_pending',
    value: 'REQUEST',
  },
  {
    name: 'general_voucher_admin_pending',
    value: 'ENTERPRISE_ACCEPT',
  },
  {
    name: 'general_voucher_active',
    value: 'ADMIN_ACCEPT',
  },
  {
    name: 'general_voucher_enterprise_refuse',
    value: 'ENTERPRISE_REFUSE',
  },
  {
    name: 'general_voucher_admin_refuse',
    value: 'ADMIN_REFUSE',
  },
];

export const RESPONSE_TYPE = {
  SUCCESS: 'SUCCESS',
};

export const OPTION_STATUS_ACTIVE: IObjectProperty[] = [
  { name: 'general_active', value: 'true' },
  { name: 'general_inactive', value: 'false' },
];

export const OPTION_GENDER: IObjectProperty[] = [
  { name: 'general_gender_male', value: 'MALE' },
  { name: 'general_gender_female', value: 'FEMALE' },
];

export const STATUS_ACCOUNT: IObjectProperty[] = [
  { name: 'general_status_account', value: '' },
  { name: 'general_waiting_verify', value: '1' },
  { name: 'general_success_verify', value: '2' },
  { name: 'general_denied_verify', value: '3' },
];

export const STATUS_KYC: IObjectProperty[] = [
  { name: 'general_verified_kyc', value: StatusKyc.ACCEPT },
  { name: 'general_kyc_not_verified', value: StatusKyc.NOT_REQUEST },
  { name: 'general_cancel_kyc', value: StatusKyc.REFUSE },
  { name: 'general_pending_kyc', value: StatusKyc.PENDING },
];

export const KYC_TYPE: IObjectProperty[] = [
  { name: 'general_kyc_type_IDcard', value: 'IDENTIFY_CARD' },
  { name: 'general_kyc_type_passport', value: 'PASSPORT' },
  { name: 'general_kyc_type_driving_license', value: 'DRIVING_LICENSE' },
];

export const PAYMENT_TYPE: IObjectProperty[] = [
  { name: 'general_payment_vtc', value: PaymentType.VTC_PAY },
  { name: 'general_payment_domestic_bank', value: PaymentType.DOMESTIC_BANK },
  {
    name: 'general_payment_international_card',
    value: PaymentType.INTERNATIONAL_CARD,
  },
];

export const KYC_PHOTO_TYPES: IObjectProperty[] = [
  { name: 'general_kyc_photo_type_front', value: 'FRONT_PHOTO' },
  { name: 'general_kyc_photo_type_back', value: 'BACK_PHOTO' },
];

export const APPLICABLE: IObjectProperty[] = [
  { name: 'general_all', value: Applicable.ALL },
  { name: 'general_user', value: Applicable.USER },
];

export const BUSINESS_TYPE: IObjectProperty[] = [
  { name: 'general_food_drink', value: BusinessType.FOOD_DRINK },
  { name: 'general_restaurant', value: BusinessType.RESTAURANT },
  { name: 'general_hotel', value: BusinessType.HOTEL },
  { name: 'general_fashion', value: BusinessType.FASHION },
  { name: 'general_grocery', value: BusinessType.GROCERY },
  { name: 'general_mart', value: BusinessType.MART },
  { name: 'general_healthcare', value: BusinessType.HEALTHCARE },
  { name: 'general_houseware', value: BusinessType.HOUSEWARE },
];

export const BUSINESSS_SCALE: IObjectProperty[] = [
  { name: 'general_small_scale', value: BusinessScale.SMALL },
  { name: 'general_medium_scale', value: BusinessScale.MEDIUM },
  { name: 'general_large_scale', value: BusinessScale.LARGE },
];

export const AVERAGE_INVOICE: IObjectProperty[] = [
  { name: 'general_from50to200', value: AverageInvoice.FROM50TO200 },
  { name: 'general_from200to500', value: AverageInvoice.FROM200TO500 },
  { name: 'general_from500to1000', value: AverageInvoice.FROM500TO1000 },
  { name: 'general_from1000to3000', value: AverageInvoice.FROM1000TO3000 },
  { name: 'general_over3000', value: AverageInvoice.OVER3000 },
];

export const DISPLAY_TYPE: IObjectProperty[] = [
  { name: 'general_on', value: DisplayType.ON },
  { name: 'general_off', value: DisplayType.OFF },
];
export const TYPE_TEMPLATE_NOTI: IObjectProperty[] = [
  { name: 'general_noti_topic', value: TypeTemplateNoti.TOPIC },
  { name: 'general_noti_user', value: TypeTemplateNoti.USER },
];

export const ADMIN_CONFIRM_STATUS: IObjectProperty[] = [
  { name: 'Chờ duyệt', value: AdminConfirmStatus.REQUESTED },
  { name: 'Đã từ chối', value: AdminConfirmStatus.CANCELED },
  { name: 'Chưa bắt đầu', value: AdminConfirmStatus.NOT_YET_STARTED },
  { name: 'Đang chạy', value: AdminConfirmStatus.STARTED },
  { name: 'Hết hạn', value: AdminConfirmStatus.FINISHED },
];
export const LOCAVOUCHER_STATUS: IObjectProperty[] = [
  { name: 'Đã chuyển', value: LocaVoucherStatus.TRANSFERED },
  { name: 'Đã nhận', value: LocaVoucherStatus.RECEIVED },
  { name: 'Đã áp dụng', value: LocaVoucherStatus.SUCCESS },
  { name: 'Không thành công', value: LocaVoucherStatus.FAILED },
];
export const DISCOUNT_TYPE: IObjectProperty[] = [
  { name: 'Trên tổng đơn hàng', value: DiscountType.BILL_DISCOUNT },
  { name: 'Trên sản phẩm', value: DiscountType.PRODUCT_DISCOUNT },
];
export const TYPE_OF_ACCOUNT: IObjectProperty[] = [
  { name: 'Doanh nghiệp', value: TypeOfAccount.ENTERPRISE },
  { name: 'Cá nhân', value: TypeOfAccount.PERSONAL },
  { name: 'CTV PTTT', value: TypeOfAccount.COLLABORATOR },
];
export const TYPE_OF_TRANSACTION: IObjectProperty[] = [
  { name: 'Áp dụng LocaVoucher', value: TypeOfTransaction.USE_LOCAVOUCHER },
  {
    name: 'Chuyển nhượng LocaVoucher',
    value: TypeOfTransaction.TRANSFER_LOCAVOUCHER,
  },
  { name: 'Nhận LocaVoucher', value: TypeOfTransaction.RECEIVE_LOCAVOUCHER },
];
export const ENVIRONMENTS = {
  API_URL: process.env.APP__API_URL,
  LOCAL_STORAGE_KEY: process.env.APP__LOCAL_STORAGE_KEY,
  DEFAULT_API_MAP: process.env.APP__GOOGLE_MAP_KEY,
  BUCKET_NAME: process.env.APP__BUCKET_NAME,
  APP_WEB_API_URL: process.env.APP__WEB_API_URL,
  DEV_ENV: process.env.APP__DEV_ENV,
  TEST_ENV: process.env.APP__TEST_ENV,
};
export const ROLE_TYPE = {
  ADMIN_MANAGEMENT_LEVEL: 'admin_management_level',
  ROLE_GROUP_LEVEL: 'role_group_level',
  USER_MANAGEMENT_LEVEL: 'user_management_level',
  KYC_MANAGEMENT_LEVEL: 'kyc_management_level',
  GIFT_MANAGEMENT_LEVEL: 'gift_management_level',
  GIFT_RECIVE_MANAGEMENT_LEVEL: 'gift_recive_management_level',
  ITEM_MANAGEMENT_LEVEL: 'item_management_level',
  REPORT_MANAGEMENT_LEVEL: 'report_management_level',
  SYSTEM_SETTING_LEVEL: 'system_setting_level',
};

export const TREE_DATA_ROLE: DataNode[] = [
  {
    title: 'Quản lý tài khoản admin',
    key: ROLE_TYPE.ADMIN_MANAGEMENT_LEVEL,
    children: [
      {
        title: 'Xem',
        key: ROLE_TYPE.ADMIN_MANAGEMENT_LEVEL + '_' + PERMISSIONS.READ,
      },
      {
        title: 'Thêm mới,sửa,xoá',
        key: ROLE_TYPE.ADMIN_MANAGEMENT_LEVEL + '_' + PERMISSIONS.FULL,
      },
    ],
  },
  {
    title: 'Quản lý nhóm phân quyền',
    key: ROLE_TYPE.ROLE_GROUP_LEVEL,
    children: [
      {
        title: 'Xem',
        key: ROLE_TYPE.ROLE_GROUP_LEVEL + '_' + PERMISSIONS.READ,
      },
      {
        title: 'Thêm mới,sửa,xoá',
        key: ROLE_TYPE.ROLE_GROUP_LEVEL + '_' + PERMISSIONS.FULL,
      },
    ],
  },
  {
    title: 'Quản lý tài khoản người dùng',
    key: ROLE_TYPE.USER_MANAGEMENT_LEVEL,
    children: [
      {
        title: 'Xem',
        key: ROLE_TYPE.USER_MANAGEMENT_LEVEL + '_' + PERMISSIONS.READ,
      },
      {
        title: 'Thêm mới,sửa,xoá',
        key: ROLE_TYPE.USER_MANAGEMENT_LEVEL + '_' + PERMISSIONS.FULL,
      },
    ],
  },
  {
    title: 'Quản lý yêu cầu xác thực',
    key: ROLE_TYPE.KYC_MANAGEMENT_LEVEL,
    children: [
      {
        title: 'Xem',
        key: ROLE_TYPE.KYC_MANAGEMENT_LEVEL + '_' + PERMISSIONS.READ,
      },
      {
        title: 'Thêm mới,sửa,xoá',
        key: ROLE_TYPE.KYC_MANAGEMENT_LEVEL + '_' + PERMISSIONS.FULL,
      },
    ],
  },
  {
    title: 'Quản lý quà tặng',
    key: ROLE_TYPE.GIFT_MANAGEMENT_LEVEL,
    children: [
      {
        title: 'Xem',
        key: ROLE_TYPE.GIFT_MANAGEMENT_LEVEL + '_' + PERMISSIONS.READ,
      },
      {
        title: 'Thêm mới,sửa,xoá',
        key: ROLE_TYPE.GIFT_MANAGEMENT_LEVEL + '_' + PERMISSIONS.FULL,
      },
    ],
  },
  {
    title: 'Quản vật phẩm',
    key: ROLE_TYPE.ITEM_MANAGEMENT_LEVEL,
    children: [
      {
        title: 'Xem',
        key: ROLE_TYPE.ITEM_MANAGEMENT_LEVEL + '_' + PERMISSIONS.READ,
      },
      {
        title: 'Thêm mới,sửa,xoá',
        key: ROLE_TYPE.ITEM_MANAGEMENT_LEVEL + '_' + PERMISSIONS.FULL,
      },
    ],
  },
  {
    title: 'Danh sách nhận quà',
    key: ROLE_TYPE.GIFT_RECIVE_MANAGEMENT_LEVEL,
    children: [
      {
        title: 'Xem',
        key: ROLE_TYPE.GIFT_RECIVE_MANAGEMENT_LEVEL + '_' + PERMISSIONS.READ,
      },
      {
        title: 'Thêm mới,sửa,xoá',
        key: ROLE_TYPE.GIFT_RECIVE_MANAGEMENT_LEVEL + '_' + PERMISSIONS.FULL,
      },
    ],
  },
  {
    title: 'Báo cáo thống kê',
    key: ROLE_TYPE.REPORT_MANAGEMENT_LEVEL,
    children: [
      {
        title: 'Xem',
        key: ROLE_TYPE.REPORT_MANAGEMENT_LEVEL + '_' + PERMISSIONS.READ,
      },
      {
        title: 'Thêm mới,sửa,xoá',
        key: ROLE_TYPE.REPORT_MANAGEMENT_LEVEL + '_' + PERMISSIONS.FULL,
      },
    ],
  },
  {
    title: 'Cài đặt hệ thống',
    key: ROLE_TYPE.SYSTEM_SETTING_LEVEL,
    children: [
      {
        title: 'Xem',
        key: ROLE_TYPE.SYSTEM_SETTING_LEVEL + '_' + PERMISSIONS.READ,
      },
      {
        title: 'Thêm mới,sửa,xoá',
        key: ROLE_TYPE.SYSTEM_SETTING_LEVEL + '_' + PERMISSIONS.FULL,
      },
    ],
  },
];

export const GIFT_POLICY = {
  receive_item: 'Nhận 1 vật phẩm tương ứng',
  conditions_receiving: `Sưu tập đủ 4 mảnh ghép của vật phẩm trong thời gian phát hành, thể hiện bằng dấu tích xanh trên giao diện App.\nTiến hành đúng và đủ các bước đổi vật phẩm theo hướng dẫn.`,
  steps_exchange: `Bước 1: Điền đầy đủ thông tin nhận quà vào form đăng ký ngay sau khi đã thu thập đủ các mảnh ghép của vật phẩm.\nBước 2: Cung cấp ID tài khoản của bạn cho nhân viên tại vị trí diễn ra chương trình.\nBước 3: Chờ xác nhận và nhận quà.`,
};
export const BlankImage =
  'https://upload.wikimedia.org/wikipedia/commons/1/1f/Blank_square.svg';
