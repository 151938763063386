import { EditOutlined, LeftOutlined } from '@ant-design/icons';
import {
  Breadcrumb,
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Skeleton,
  Spin,
  Switch,
  Tag,
  message,
} from 'antd';
import React, { useState } from 'react';
import { Link, useHistory, useIntl, useParams } from 'umi';
import styles from '../index.less';
// import UploadImage from '@/components/UploadImage';
import { DISCOUNT_TYPE, ENVIRONMENTS } from '@/utils/constant';
import { useRequest } from 'ahooks';
import { AdminConfirmStatus, DiscountType } from '@/utils/enum';
import {
  CreateDiscountEventInput,
  detailDiscountEvent,
  getCoreVoucher,
  getListApplyEvent,
  updateDiscountEvent,
} from '../service';
import moment from 'moment';
import dayjs from 'dayjs';
import { validatorPositiveNumber } from '@/utils/apis/common';
import ListProduct from './ListProduct';
const DetailDiscountEvent = () => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const params = useParams<{ id: string }>();
  // const [fileList, setFileList] = useState<any[]>([]);
  const [atOptions, setAtOptions] = useState<any[]>([]);
  const [optionDiscountEvent, setOptionDiscountEvent] = useState<any[]>([]);
  const [selectedProductId, setSelectedProductId] = useState<number[]>([]);
  const [discountType, setDiscountType] = useState<DiscountType>(
    DiscountType.BILL_DISCOUNT,
  );
  const [form] = Form.useForm();
  const detailEvent = useRequest(detailDiscountEvent, {
    defaultParams: [Number(params.id)],
    onSuccess: (res) => {
      const data = res?.data?.detail;
      form.setFieldsValue({
        ...data,
        start_time: moment(data.start_time),
        end_time: moment(data.end_time),
        limit_discount_percent: data?.limit_discount_percent * 100,
        display: !data.disable,
      });
      setDiscountType(data.type);
      if (data?.apply_at_same_time) {
        searchDiscountEvent.run(+params.id);
        setApplySameTime(data?.apply_at_same_time);
      }
      if (data?.discount_product_ids) {
        setSelectedProductId(data?.discount_product_ids ?? []);
      }
      // if (!!data?.image) {
      //   setFileList([
      //     {
      //       uid: '-1',
      //       name: 'image.png',
      //       status: 'done',
      //       url: data?.image,
      //     },
      //   ]);
      // }
    },
  });
  const [applySameTime, setApplySameTime] = useState(false);
  const requestSearchVoucher = useRequest(getCoreVoucher, {
    debounceWait: 1000,
    onSuccess: (res) => {
      setAtOptions(() =>
        res.data?.map((item: any) => ({
          ...item,
          label: item?.name,
          value: item?.id,
        })),
      );
    },
    onError: (err) => {},
  });

  const searchDiscountEvent = useRequest(getListApplyEvent, {
    manual: true,
    debounceWait: 500,
    onSuccess: (res) => {
      console.log(res);
      setOptionDiscountEvent(res.data);
    },
  });

  const updateEvent = useRequest(updateDiscountEvent, {
    manual: true,

    onSuccess: (res) => {
      message.success('Tạo chuơng trình KM thành công');
      history.push('/discount_event');
    },
    onError: (err) => {
      console.log(err);
      message.error('Có lỗi xảy ra');
    },
  });
  const onFinish = (val: any) => {
    const payload: CreateDiscountEventInput = {
      ...val,
      start_time: moment(val.start_time).format('YYYY-MM-DD'),
      end_time: moment(val.end_time).format('YYYY-MM-DD'),
      image: val.image?.[0]?.response?.path,
      apply_at_same_time: val.apply_at_same_time ? true : false,
      disable: !val.display,
    };
    if (discountType === DiscountType.BILL_DISCOUNT) {
      // payload.limit_discount_percent = Number(val.limit_discount_percent) / 100;
      payload.limit_discount_percent = 1;
    }
    if (discountType === DiscountType.PRODUCT_DISCOUNT) {
      payload.discount_product_ids = selectedProductId;
    }
    if (!!payload.apply_at_same_time) {
      payload.apply_same_time_list = val.apply_same_time_list ?? [];
    }
    //@ts-ignore
    delete payload['display'];
    updateEvent.run(payload, Number(params.id));
  };
  const [isEdit, setIsEdit] = useState(false);
  const renderStatus = (status: AdminConfirmStatus) => {
    switch (status) {
      case AdminConfirmStatus.REQUESTED:
        return <Tag color="warning">Chờ duyệt </Tag>;
      case AdminConfirmStatus.STARTED:
        return <Tag color="success">Đang chạy</Tag>;
      case AdminConfirmStatus.FINISHED:
        return <Tag color="gray">Hết hạn</Tag>;
      case AdminConfirmStatus.NOT_YET_STARTED:
        return <Tag color="gray">Chưa bắt đầu</Tag>;
      case AdminConfirmStatus.CANCELED:
        return <Tag color="red">Đã từ chối</Tag>;
      default:
        break;
    }
  };
  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item className={styles.breacrumbItem}>
          <Link to="/discount_event" className={styles.previousEditLink}>
            <LeftOutlined />
            <div>{formatMessage({ id: 'discount_event' })}</div>
          </Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className={styles.wrapper}>
        <Card>
          {detailEvent.loading ? (
            <Skeleton active />
          ) : (
            <>
              <Row
                justify="space-between"
                style={{
                  marginBottom: '20px',
                }}
              >
                <Col span={11} xxl={11} xl={11} lg={11} xs={24}>
                  <div>
                    <div>ID: {params.id}</div>
                    <div>
                      Trạng thái:{' '}
                      {renderStatus(detailEvent.data?.data?.detail?.status)}
                    </div>
                    {detailEvent.data?.data?.detail?.status ===
                      AdminConfirmStatus.CANCELED && (
                      <div>
                        Lý do:{' '}
                        <span>{detailEvent.data?.data?.detail?.note}</span>
                      </div>
                    )}
                  </div>
                </Col>
                <Col span={9} xxl={9} xl={9} lg={9} xs={24}>
                  <Row justify="end" gutter={[10, 10]}>
                    {detailEvent.data?.data?.detail?.status ===
                      AdminConfirmStatus.REQUESTED && (
                      <>
                        <Button
                          icon={<EditOutlined />}
                          onClick={() => setIsEdit(true)}
                        ></Button>
                      </>
                    )}
                  </Row>
                </Col>
              </Row>
              <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                disabled={!isEdit}
              >
                <Row justify={'space-between'}>
                  <Col span={11} xxl={11} xl={11} lg={11} xs={24}>
                    <Form.Item
                      label="Tên chương trình"
                      name="event_name"
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng nhập',
                        },
                      ]}
                    >
                      <Input placeholder="Nhập tên chương trình" />
                    </Form.Item>
                    <Form.Item
                      label="Thời gian bắt đầu"
                      name="start_time"
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng nhập',
                        },
                      ]}
                    >
                      <DatePicker
                        format={'DD/MM/YYYY'}
                        style={{
                          width: '100%',
                        }}
                        disabledDate={(currentDate) => {
                          if (!!form.getFieldValue('end_time')) {
                            const end_time = moment(
                              form.getFieldValue('end_time'),
                            ).add(17, 'hours');

                            return (
                              currentDate <= moment().add(-7, 'hours') ||
                              currentDate >= end_time
                            );
                          }

                          return currentDate <= moment().add(-7, 'hours');
                        }}
                      />
                    </Form.Item>

                    {/* {discountType === DiscountType.BILL_DISCOUNT && (
                  <Form.Item
                    label="Hạn mức áp dụng voucher / giá trị hoá đơn"
                    name="limit_discount_percent"
                    rules={[
                      {
                        required: true,
                        message: 'Vui lòng nhập',
                      },
                      {
                        validator: validatorPositiveNumber,
                      },
                    ]}
                  >
                    <InputNumber
                      placeholder="Nhập hạn mức"
                      style={{
                        width: '100%',
                      }}
                      addonAfter="%"
                    />
                  </Form.Item>
                )} */}
                  </Col>
                  <Col span={11} xxl={11} xl={11} lg={11} xs={24}>
                    <Form.Item
                      label="Loại khuyến mãi"
                      name="type"
                      initialValue={discountType}
                    >
                      <Select
                        onChange={(val) => setDiscountType(val)}
                        placeholder="Chọn loại"
                        options={DISCOUNT_TYPE.map((item) => ({
                          label: item.name,
                          value: item.value,
                        }))}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Thời gian kết thúc"
                      name="end_time"
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng nhập',
                        },
                      ]}
                    >
                      <DatePicker
                        format={'DD/MM/YYYY'}
                        style={{
                          width: '100%',
                        }}
                        disabledDate={(currentDate) => {
                          if (!!form.getFieldValue('start_time')) {
                            const start_time = moment(
                              form.getFieldValue('start_time'),
                            ).add(-7, 'hours');
                            return currentDate <= start_time;
                          }
                          return currentDate <= moment().add(-7, 'hours');
                        }}
                      />
                    </Form.Item>

                    {/* <UploadImage
                  name="image"
                  label="Ảnh chương trình"
                  setFileList={setFileList}
                  fileList={fileList}
                  required={true}
                /> */}
                  </Col>
                </Row>
                {discountType === DiscountType.PRODUCT_DISCOUNT && (
                  <ListProduct
                    selectedProductId={selectedProductId}
                    setSelectedProductId={setSelectedProductId}
                  />
                )}
                <Row
                  justify="space-between"
                  style={{
                    marginTop: 20,
                  }}
                >
                  <Col span={11} xxl={11} xl={11} lg={11} xs={24}>
                    <Form.Item
                      label="Loại voucher áp dụng"
                      name="voucher_type_id"
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng chọn loại voucher',
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        allowClear
                        placeholder="Chọn voucher"
                        options={atOptions.map((item: any, i: number) => ({
                          ...item,
                          key: `${item?.value}_${i}`,
                        }))}
                        notFoundContent={
                          requestSearchVoucher.loading ? <Spin /> : <>Trống</>
                        }
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.label ?? '').includes(input)
                        }
                        // filterOption={false}
                        // onSearch={(searchValue) => handleSearchVoucher(searchValue)}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Số voucher tối đa được sử dụng trong chương trình"
                      name="limit_voucher_event"
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng nhập',
                        },
                        {
                          validator: validatorPositiveNumber,
                        },
                      ]}
                    >
                      <InputNumber
                        placeholder="Nhập số voucher"
                        style={{
                          width: '100%',
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Mô tả chương trình"
                      name="description"
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng nhập',
                        },
                      ]}
                    >
                      <Input.TextArea placeholder="Nhập mô tả" rows={4} />
                    </Form.Item>
                    {discountType === DiscountType.PRODUCT_DISCOUNT && (
                      <>
                        <Form.Item
                          name="apply_at_same_time"
                          valuePropName="checked"
                        >
                          <Checkbox
                            checked={false}
                            onChange={(e) => {
                              setApplySameTime(e.target.checked);
                              if (!!e.target.checked) {
                                searchDiscountEvent.run();
                              }
                            }}
                          >
                            Áp dụng đồng thời với các chương trình khác
                          </Checkbox>
                        </Form.Item>
                        {applySameTime && (
                          <Form.Item name="apply_same_time_list">
                            <Select
                              mode="multiple"
                              placeholder="Chọn chương trình"
                              options={optionDiscountEvent.map((item) => ({
                                label: item.event_name,
                                value: item.id,
                              }))}
                            />
                          </Form.Item>
                        )}
                      </>
                    )}
                  </Col>
                  <Col span={11} xxl={11} xl={11} lg={11} xs={24}>
                    {discountType === DiscountType.BILL_DISCOUNT && (
                      <Form.Item
                        label="Giá trị hóa đơn tối thiểu để được áp dụng voucher"
                        name="minimum_amount_require"
                        rules={[
                          {
                            required: true,
                            message: 'Vui lòng nhập',
                          },
                          {
                            validator(_, value) {
                              if (!value) return Promise.reject();
                              if (!!value && value < 0) {
                                return Promise.reject(
                                  'Vui lòng nhập giá trị dương',
                                );
                              }
                              return Promise.resolve();
                            },
                          },
                        ]}
                      >
                        <InputNumber
                          formatter={(value) => `${value}`.replace(/,/g, '.')}
                          placeholder="Nhập giá trị"
                          style={{
                            width: '100%',
                          }}
                        />
                      </Form.Item>
                    )}
                    <Form.Item
                      label={`Số voucher tối đa được áp dụng trên ${
                        discountType === DiscountType.PRODUCT_DISCOUNT
                          ? 'sản phẩm'
                          : 'hoá đơn'
                      }`}
                      name={
                        discountType === DiscountType.PRODUCT_DISCOUNT
                          ? 'limit_voucher_per_product'
                          : 'limit_voucher_per_bill'
                      }
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng nhập',
                        },
                        {
                          validator: validatorPositiveNumber,
                        },
                        ({ getFieldValue }) => ({
                          validator: (_, value) => {
                            const limitInEvent = getFieldValue(
                              'limit_voucher_event',
                            );
                            if (!!limitInEvent && value > limitInEvent) {
                              return Promise.reject(
                                `Số voucher không được lớn hơn ${limitInEvent}`,
                              );
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <InputNumber
                        placeholder="Nhập số voucher"
                        style={{
                          width: '100%',
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Trạng thái hiển thị"
                      name="display"
                      valuePropName="checked"
                    >
                      <Switch checked={true} />
                    </Form.Item>
                  </Col>
                </Row>

                <Row justify="center" className={styles.addGroupButton}>
                  <Button
                    danger
                    ghost
                    className="btn-submit"
                    onClick={() => {
                      setIsEdit(false);
                      detailEvent.run(Number(params.id));
                    }}
                  >
                    Huỷ
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="btn-submit"
                  >
                    Lưu
                  </Button>
                </Row>
              </Form>
            </>
          )}
        </Card>
      </div>
    </div>
  );
};

export default DetailDiscountEvent;
