import { API_PATH } from '@/utils/apis';
import { ENVIRONMENTS } from '@/utils/constant';
import { UploadOutlined } from '@ant-design/icons';
import { Avatar, Form, Modal, UploadFile } from 'antd';
import Upload, { RcFile, UploadProps } from 'antd/lib/upload';
import React, { useState } from 'react';

const UploadImage = ({
  fileList,
  setFileList,
  label,
  name,
  compressor,
  propsUpload = {},
  required,
  resize,
  isAvatar,
  groupUpload = false,
  amountUpload = 1,
}: {
  fileList: any[];
  setFileList: any;
  label?: string;
  name?: string | any[];
  compressor?: boolean;
  propsUpload?: UploadProps;
  required?: boolean;
  resize?: boolean;
  isAvatar?: boolean;
  groupUpload?: boolean;
  amountUpload?: number;
}) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    setPreviewImage(src);
    setPreviewOpen(true);
    // const image = new Image();
    // image.src = src;
    // const imgWindow = window.open(src);
    // imgWindow?.document.write(image.outerHTML);
  };
  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  const handleCancel = () => setPreviewOpen(false);

  return (
    <>
      <Form.Item
        className="uploadItem"
        style={{
          textAlign: 'center',
        }}
        label={label}
        name={name}
        getValueFromEvent={(e) => normFile(e)}
        rules={[
          {
            required: required ? required : false,
            message: 'Vui lòng chọn ảnh',
          },
          ({ getFieldValue }) => ({
            // eslint-disable-next-line @typescript-eslint/space-before-function-paren
            async validator(_, value) {
              if (resize) {
                const quantity = getFieldValue('quantity');
                if (!quantity) {
                  return Promise.reject('Vui lòng chọn số lượng');
                }
              }
              if (value?.[0]?.error) {
                return Promise.reject('Tải ảnh thất bại');
              }
              return Promise.resolve();
            },
          }),
        ]}
      >
        <Upload
          accept=".png,.jpg,.jpeg,.gif"
          action={
            ENVIRONMENTS.API_URL +
            `${compressor ? API_PATH.UPLOAD_COMPRESSOR : API_PATH.UPLOAD}`
          }
          onPreview={onPreview}
          fileList={fileList}
          listType="picture-card"
          className={
            isAvatar ? 'avatar-upload' : groupUpload ? 'group-upload' : ''
          }
          onChange={(val: any) => {
            setFileList(val.fileList);
          }}
          onRemove={() => {
            setFileList([]);
          }}
          {...propsUpload}
        >
          {fileList.length < amountUpload && 'Tải lên'}
        </Upload>
      </Form.Item>

      {previewOpen && (
        <Modal open={previewOpen} footer={null} onCancel={handleCancel}>
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      )}
    </>
  );
};

export default UploadImage;
