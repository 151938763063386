import { extend } from 'umi-request';
import axios from 'axios';
import { ENVIRONMENTS } from '../constant';
import TokenManagement, { parseJwt } from './TokenManagement';

const REQ_TIMEOUT = 25 * 1000;
const localeInfo = localStorage.getItem('umi_locale') || 'vi-VN';

const getAccessToken = () => {
  const localStorageInfo: any = window.localStorage.getItem(
    ENVIRONMENTS.LOCAL_STORAGE_KEY as string,
  );
  const token = JSON.parse(localStorageInfo)?.token;
  return token;
};

const request = extend({
  prefix: ENVIRONMENTS.API_URL,
  timeout: REQ_TIMEOUT,
});
const injectBearer = (token: string, configs: any) => {
  if (!configs) {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        'Accept-Language': localeInfo,
      },
    };
  }

  if (configs.headers) {
    return {
      ...configs,
      headers: {
        ...configs.headers,
        Authorization: `Bearer ${token}`,
        'Accept-Language': localeInfo,
      },
    };
  }

  return {
    ...configs,
    headers: {
      Authorization: `Bearer ${token}`,
      'Accept-Language': localeInfo,
    },
  };
};

const TokenManager = new TokenManagement({
  isTokenValid: () => {
    try {
      const token = getAccessToken();

      const decode = parseJwt(token);
      const { exp } = decode;

      const currentTime = Date.now() / 1000;

      if (exp - 5 > currentTime) {
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  },
  getAccessToken: () => {
    const localStorageInfo: any = window.localStorage.getItem(
      ENVIRONMENTS.LOCAL_STORAGE_KEY as string,
    );
    const token = JSON.parse(localStorageInfo)?.token;
    return `${token}`;
  },
  onRefreshToken(done) {
    const localStorageInfo: any = window.localStorage.getItem(
      ENVIRONMENTS.LOCAL_STORAGE_KEY as string,
    );
    const refreshToken = JSON.parse(localStorageInfo)?.refreshToken;

    if (!refreshToken) {
      return done(null);
    }

    request
      .post('/graphql', {
        data: {
          query: `
            mutation {
              refresh_token(input: "${refreshToken}", type: 3) {
                token
                refresh_token
              }
            }
          `,
        },
      })
      .then((result) => {
        if (
          result.data?.refresh_token.refresh_token &&
          result.data?.refresh_token.token
        ) {
          const localeInfo = JSON.parse(
            window.localStorage.getItem(
              ENVIRONMENTS.LOCAL_STORAGE_KEY as string,
            ) as string,
          );
          const newLocalInfo = {
            ...localeInfo,
            token: result.data?.refresh_token.token,
            refreshToken: result.data?.refresh_token.refresh_token,
          };

          window.localStorage.setItem(
            ENVIRONMENTS.LOCAL_STORAGE_KEY as string,
            JSON.stringify(newLocalInfo),
          );
          done(result.data?.refresh_token?.token);
          return;
        }
        if (result?.errors?.[0]?.message) {
          window.localStorage.clear();
          window.location.href = '/';
          done(null);
          return;
        }
        done(null);
      })
      .catch((err) => {
        console.error(err);
        //logout
        done(null);
      });
  },
});

const privateRequest = async (
  request: any,
  suffixUrl: string,
  configs?: any,
) => {
  const token: string = configs?.token
    ? configs.token
    : ((await TokenManager.getToken()) as string);
  return request(suffixUrl, injectBearer(token, configs)).then((res: any) => {
    if (res?.errors?.[0]?.extensions?.response?.statusCode === 401) {
      window.localStorage.clear();
      window.location.href = '/';
    }
    return res;
  });
};

const API_PATH = {
  default: '/graphql',
  // Auth
  EXPORT_STATISTIC: '/enterprise/export-data',
  UPLOAD: '/api/v2/upload-service/file',
  UPLOADV2: '/api/v2/upload-service/file',
  UPLOAD_V2: '/v2/upload-service/file',
  UPLOAD_COMPRESSOR: '/v2/image-compressor/file',
  UPLOAD_DEV: '/v2/upload-service/file',
  LOGIN: '/auth/login',
  REGISTER: '/auth/register',
  REFRESH_TOKEN: '/auth/refreshToken',
  FORGOT_PASSWORD: '/auth/forgotPassword',
  RESET_PASSWORD: '/auth/resetPassword',
  LOGOUT: '/auth/logout',
  USER: '/user',
  ADMIN_USER: 'admin/user',
  PHONE_OTP_VOICE: '/enterprise/enterprise-get-phone-otp',
  RESIZE_IMAGE: '/upload-service/resize-image',
  //VOUCHER
  VOUCHER_RELEASE: '/enterprise-voucher/enterprise-release-history',
  VOUCHER_STATISTIC: '/enterprise-voucher/voucher-statistic',
  VOUCHER_CONFIG: '/enterprise-voucher/voucher-config',
  UPDATE_CONFIG: '/enterprise-voucher/config',
  ALIAS_STATISTIC: '/enterprise-voucher/check-use-alias',
  //PACKAGE
  BUY_PACKAGE_HISTORY: '/enterprise/buy-package-history',
  BUY_PACKAGE_REQUEST: '/enterprise/buy-package-request',
  PACKAGE_AMOUNT: '/enterprise/package-amount',
  CAN_PURCHASE: '/enterprise/can-purchase-package',
  //event gift
  EVENT_GIFT: '/gift-event',
  DETAIL_EVENT_GIFT: (id: number) => `/gift-event/${id}`,
  //discount event
  DISCOUNT_EVENT: '/discount-events',
  CONFIRM_DISCOUNT_EVENT: '/discount-events/confirm',
  ENTERPRISE_EVENT: '/discount-events/register-voucher-enterprise',
  APPLY_EVENT: '/discount-events/apply-events',
  CORE_VOUCHER_EVENT: '/discount-events/core-voucher',
  STATISTIC_USE_EVENTS: '/discount-events/statistic',
  INVITE_CODE: '/enterprise/get-user-by-invite-code/',
  LINK_USER: '/enterprise/link-user-to-eneterprise/',
  DETAIL_LINK: '/enterprise/link-user-to-eneterprise',
  //store
  REGISTER_STORE: '/auth/register-store',
  UPDATE_STORE: '/store/detail',
  DETAIL_STORE: '/store/detail',
  DETAIL_BRAND: '/store/brand',
  CHANGE_PASSWORD: '/auth/store-password',

  //transaction voucher
  TRANSACTION_VOUCHER: '/transaction-enterprise-voucher',
  //send OTP
  SEND_OTP: '/user-otp',
  VERIFY_OTP: '/user-otp-verify',
  CREATE_PIN: '/enterprise/create-pin-code',
  CHECK_PIN: '/enterprise/check-pin-code',
  CHANGE_PIN: '/enterprise/change-pin-code',

  //product
  LIST_PRODUCT: '/product',
  PRODUCT_CATEGORY: '/product/category',
  PRODUCT_PROPERTY: '/product/property',
  CREATE_BUNDLE_PRODUCT: '/product/bundle-product-default',
  DETAIL_PRODUCT: (id: number) => '/product/' + id,
  CREATE_BUNDLE_PRODUCT_UPDATE: (id: number) => `/product/bundle-product/${id}`,
  UPDATE_BUNDLE_PRODUCT: (id: number) =>
    `/product/bundle-product?product_id=${id}`,
  UPDATE_CATEGORY_PRODUCT: (id: number) => `/product/category/${id}`,

  //order
  ORDER: '/order/by-enterprise',
  DETAIL_ORDER: (id: string) => `/order/by-enterprise/${id}`,
};

export { API_PATH, request, privateRequest };
