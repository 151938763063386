import React, { useState } from 'react';
import styles from '../index.less';
import {
  Avatar,
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Select,
  Skeleton,
  Tabs,
  Tag,
  Typography,
  UploadFile,
  message,
} from 'antd';
import {
  CheckOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { BUSINESS_TYPE, ENVIRONMENTS } from '@/utils/constant';
import { useIntl, useRequest } from 'umi';
import { useTranslate } from '@/utils/hooks/useTranslate';
import MapReaflat from '@/components/MapReaflat';
import { paternPhone } from '@/utils/patern';
import { getListOrgs } from '@/pages/UpdateBusiness/service';
import { getDetailStore, updateStoreService } from '../service';
import UploadImage from '@/components/UploadImage';
import QRCode from 'react-qr-code';
const StoreInfo = () => {
  const { formatMessage } = useIntl();
  const { t } = useTranslate();
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [isEdit, setIsEdit] = useState(false);

  const requestOrgs = useRequest(getListOrgs);
  const updateStore = useRequest(updateStoreService, {
    manual: true,
    onSuccess: (res) => {
      setIsEdit(false);
      detailStore.run();
      message.success('Cập nhật thông tin thành công');
      if (res.name !== detailStore.data?.name) {
        const local = JSON.parse(
          localStorage.getItem(
            ENVIRONMENTS.LOCAL_STORAGE_KEY as string,
          ) as string,
        );
        window?.localStorage.setItem(
          ENVIRONMENTS.LOCAL_STORAGE_KEY as string,
          JSON.stringify({
            ...local,
            display_name: res?.name,
          }),
        );
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }
    },
    onError: (err: any) => {
      console.log(err.data);
      message.error(err.data?.message || 'Có lỗi xảy ra');
    },
  });
  const detailStore = useRequest(getDetailStore, {
    onSuccess: (res) => {
      if (!!res?.logo) {
        setFileList([
          {
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: res?.logo,
          },
        ]);
      }
      form.setFieldsValue({
        ...res,
        display_email: res?.display_email ?? res?.email,
        display_phone: res?.display_phone ?? res?.phone,
      });
    },
  });
  const onSelectPos = (pos: any) => {
    const provinceSelected = pos?.name?.split(',').reverse()[1]?.trim();
    const province = requestOrgs.data?.get_all_orgs?.find(
      (item: any) => item.name === provinceSelected,
    );
    form.setFieldValue('province_id', province?.id);

    // setPickerPosition({
    //   lat: pos?.lat,
    //   lng: pos?.long,
    //   customName: pos?.name,
    // });

    form.setFieldsValue({
      address: pos?.name,
      lat: pos?.lat,
      long: pos?.long,
    });
  };
  const onSubmit = (val: any) => {
    const payload = {
      ...val,
      display_email: val?.display_email?.trim()?.replace('\b', ''),
      display_phone: val?.display_phone?.trim()?.replace('\b', ''),
      logo:
        Array.isArray(val.logo) && val.logo?.length > 0
          ? val.logo?.[0]?.response.path
          : !!val.logo?.length
          ? val.logo
          : null,
    };

    const oldValue = detailStore.data;
    if (oldValue?.verified) {
      if (
        oldValue?.display_email !== payload.display_email ||
        oldValue?.display_phone !== payload.display_phone
      ) {
        updateStore.run(payload);
      } else {
        setIsEdit(false);
        detailStore.run();
      }
    } else {
      updateStore.run(payload);
    }

    updateStore.run(payload);
  };
  const renderStatus = (status: string) => {
    switch (status) {
      case 'ACCEPT':
        return (
          <Tag color="green" icon={<CheckOutlined />}>
            Đã xác thực
          </Tag>
        );
      case 'PENDING':
        return (
          <Tag color="warning" icon={<ClockCircleOutlined />}>
            Đang chờ xác thực
          </Tag>
        );
      case 'REFUSE':
        return (
          <>
            <Tag color="red" icon={<CloseOutlined />}>
              Từ chối xác thực
            </Tag>{' '}
            Lý do: {detailStore.data?.refuse_reson}
          </>
        );
      case 'NOT_REQUEST':
        return (
          <Tag color="gray" icon={<ClockCircleOutlined />}>
            Chưa xác thực
          </Tag>
        );
      default:
        return (
          <Tag color="gray" icon={<ClockCircleOutlined />}>
            Chưa xác thực
          </Tag>
        );
    }
  };
  return (
    <div className={styles.wrapper}>
      {detailStore.loading ? (
        <Skeleton active />
      ) : (
        <Form
          form={form}
          disabled={!isEdit}
          labelCol={{
            span: 5,
          }}
          labelAlign="left"
          labelWrap
          onFinish={onSubmit}
        >
          <Row
            gutter={20}
            justify="space-between"
            className={styles.wrapperFlex}
          >
            <Col span={18} xxl={18} xl={18} lg={24} sm={24} xs={24}>
              <Row>
                <Typography.Title className={styles.titleTab} level={4}>
                  Thông tin cửa hàng
                </Typography.Title>
                <Button
                  disabled={false}
                  icon={<EditOutlined />}
                  ghost
                  type="primary"
                  onClick={() => setIsEdit(true)}
                ></Button>
              </Row>
              <Form.Item label="Trạng thái">
                {renderStatus(detailStore.data?.verify_status)}
              </Form.Item>
              <Form.Item
                label="Tên thương hiệu/cửa hàng"
                name="name"
                rules={[
                  {
                    required: detailStore.data?.verified ? false : true,
                    message: t('required'),
                  },
                  {
                    validator(_, value) {
                      if (value?.length > 100) {
                        return Promise.reject('Tối đa 100 kí tự');
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input
                  placeholder={formatMessage({ id: 'brand_name' })}
                ></Input>
              </Form.Item>
              <Form.Item
                label={formatMessage({ id: 'email' })}
                name="display_email"
                rules={[
                  {
                    type: 'email',
                    message: t('error.email', {
                      field: t('email'),
                    }),
                  },
                  {
                    required: true,
                    message: t('error.require', {
                      field: t('email'),
                    }),
                  },
                ]}
              >
                <Input
                  placeholder={formatMessage({ id: 'email' })}
                  disabled={!isEdit}
                  autoComplete="off"
                />
              </Form.Item>
              <Form.Item
                label={formatMessage({ id: 'phone_number' })}
                name="display_phone"
                rules={[
                  {
                    required: true,
                    message: t('error.require', {
                      field: 'Số điện thoại',
                    }),
                  },
                  {
                    pattern: paternPhone,
                    message: t('error.patern', {
                      field: t('phone_number'),
                    }),
                  },
                ]}
              >
                <Input
                  placeholder={formatMessage({ id: 'phone_number' })}
                  disabled={!isEdit}
                  autoComplete="off"
                ></Input>
              </Form.Item>
              <Form.Item
                label="Địa chỉ"
                name="address"
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng chọn địa điểm trên bản đồ',
                  },
                ]}
              >
                <Input
                  placeholder="Nhập địa chỉ cửa hàng"
                  disabled={detailStore.data?.verified ? true : !isEdit}
                />
              </Form.Item>
              <Form.Item label="Khu vực" name="province_id">
                <Select
                  placeholder="Chọn khu vực"
                  options={requestOrgs.data?.get_all_orgs?.map((item: any) => ({
                    label: item.name,
                    value: item.id,
                  }))}
                  disabled={detailStore.data?.verified ? true : !isEdit}
                />
              </Form.Item>
              <Form.Item
                style={{
                  textAlign: 'center',
                }}
              >
                <MapReaflat
                  setPos={onSelectPos}
                  listPosition={[]}
                  loading={false}
                  disable={detailStore.data?.verified ? true : !isEdit}
                  mapItemDetail={detailStore.data}
                />

                <Row
                  gutter={16}
                  style={{
                    marginTop: '10px',
                  }}
                >
                  <Col span={12} className={styles.dialogFormItem}>
                    <Form.Item
                      label="Lat"
                      name="lat"
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng chọn',
                        },
                      ]}
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col span={12} className={styles.dialogFormItem}>
                    <Form.Item
                      label="Lng"
                      name="long"
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng chọn',
                        },
                      ]}
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item
                label={formatMessage({ id: 'business_type' })}
                name="business_type"
                rules={[
                  {
                    required: true,
                    message: t('required'),
                  },
                ]}
              >
                <Select
                  placeholder={t('business_type_input')}
                  options={BUSINESS_TYPE.map((item) => ({
                    label: t(item.name),
                    value: item.value,
                  }))}
                />
              </Form.Item>
              <Form.Item
                name="description"
                label="Giới thiệu cửa hàng/thương hiệu"
                rules={[
                  {
                    required: detailStore.data?.verified ? false : true,
                    message: 'Vui lòng nhập',
                  },
                  {
                    validator(_, value) {
                      if (value?.length > 500) {
                        return Promise.reject('Tối đa 500 kí tự');
                      }
                      if (!!value && !value?.trim()) {
                        return Promise.reject('Vui lòng nhập giới thiệu');
                      }

                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input.TextArea rows={2} placeholder="Nhập giới thiệu" />
              </Form.Item>
            </Col>
            <Col span={4} xxl={4} xl={4} lg={24} sm={24} xs={24}>
              <div className={styles.wrapperCol2}>
                <div className={styles.avatar}>
                  {!!detailStore.data?.logo && !isEdit ? (
                    <Avatar src={detailStore.data?.logo} size={200} />
                  ) : (
                    <UploadImage
                      name="logo"
                      propsUpload={{
                        disabled: !isEdit,
                      }}
                      isAvatar={true}
                      fileList={fileList}
                      setFileList={setFileList}
                    />
                  )}
                </div>
                {detailStore.data?.verified && (
                  <Card className={styles.qrCode}>
                    <QRCode value={detailStore.data?.qr_data} size={150} />
                    <p
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      QR Doanh nghiệp
                    </p>
                  </Card>
                )}
              </div>
            </Col>
          </Row>
          <Row
            justify="center"
            style={{
              margin: '20px 0',
            }}
          >
            <Button
              type="primary"
              ghost
              danger
              className="btn-submit"
              onClick={() => {
                setFileList([]);
                setIsEdit(false);
                detailStore.run();
              }}
              disabled={!isEdit}
            >
              Huỷ
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className="btn-submit"
              loading={updateStore.loading}
              disabled={!isEdit}
            >
              Xác nhận
            </Button>
          </Row>
        </Form>
      )}
    </div>
  );
};

export default StoreInfo;
