import { LockOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Skeleton,
  Typography,
  message,
} from 'antd';
import React, { useState } from 'react';
import styles from '../index.less';
import CreatePinCode from '../component/CreatePinCode';
import { useRequest } from 'umi';
import { changePinCodeService, checkPinCodeService } from '../service';
import { paternPinCode } from '@/utils/patern';
const PinCode = () => {
  const [form] = Form.useForm();
  const [isOpenCreatePin, setIsOpenCreatePin] = useState<boolean>(false);
  const [disableForm, setDisableForm] = useState<boolean>(true);
  const checkPinCode = useRequest(checkPinCodeService);
  const changePinCode = useRequest(changePinCodeService, {
    manual: true,
    onSuccess: (res) => {
      form.resetFields();
      message.success('Thay đổi mã PIN thành công');
    },
    onError: (err: any) => {
      message.error(err.data.message ?? 'Có lỗi xảy ra');
    },
  });
  const onOpenCreatePin = () => {
    setIsOpenCreatePin(true);
  };
  const onSubmit = (val: any) => {
    changePinCode.run({
      ...val,
    });
  };
  return (
    <>
      {checkPinCode.loading ? (
        <Skeleton active />
      ) : (
        <div className={styles.wrapperPassword}>
          <Row justify="space-between">
            <Typography.Title className={styles.titleTab} level={4}>
              Mã PIN
            </Typography.Title>
            {!checkPinCode.data ? (
              <Button
                icon={<LockOutlined />}
                type="primary"
                onClick={onOpenCreatePin}
              >
                Tạo mã PIN
              </Button>
            ) : (
              <Button
                type="primary"
                ghost
                onClick={() => setDisableForm(false)}
              >
                Đổi mã PIN
              </Button>
            )}
          </Row>
          <Form
            form={form}
            labelCol={{
              span: 6,
            }}
            disabled={disableForm}
            labelAlign="left"
            labelWrap
            onFinish={onSubmit}
          >
            <Row>
              <Col span={18} xxl={18} xl={18} lg={24} sm={24} xs={24}>
                <Form.Item
                  label="Mã PIN hiện tại"
                  name="current_pin_code"
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng nhập mã PIN hiện tại',
                    },
                    {
                      pattern: paternPinCode,
                      message: 'Vui lòng nhập mã PIN 4 số',
                    },
                  ]}
                >
                  <Input placeholder="Nhập mã PIN hiện tại" />
                </Form.Item>
                <Form.Item
                  label="Mã PIN mới"
                  name="pin_code"
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng nhập mã PIN mới',
                    },
                    {
                      pattern: paternPinCode,
                      message: 'Vui lòng nhập mã PIN 4 số',
                    },
                  ]}
                >
                  <Input placeholder="Nhập mã PIN mới" />
                </Form.Item>
                <Form.Item
                  name="pin_code_confirmation"
                  dependencies={['pin_code']}
                  label="Nhập lại mã PIN mới"
                  hasFeedback
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (getFieldValue('pin_code') === value) {
                          return Promise.resolve();
                        }
                        if (!value && !!getFieldValue('pin_code')) {
                          return Promise.reject(
                            new Error('Vui lòng lại nhập mã PIN mới'),
                          );
                        }
                        return Promise.reject(new Error('Mã PIN không khớp'));
                      },
                    }),
                  ]}
                >
                  <Input placeholder="Nhập lại mã PIN" />
                </Form.Item>
              </Col>
            </Row>
            <Row
              justify="center"
              style={{
                margin: '20px 0',
              }}
            >
              <Button
                type="primary"
                ghost
                danger
                className="btn-submit"
                onClick={() => {
                  form.resetFields();
                }}
              >
                Huỷ
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                className="btn-submit"
                loading={changePinCode.loading}
              >
                Lưu
              </Button>
            </Row>
          </Form>

          {isOpenCreatePin && (
            <CreatePinCode
              onSuccessCreatePinCode={() => checkPinCode.run()}
              isOpen={isOpenCreatePin}
              setIsOpen={setIsOpenCreatePin}
            />
          )}
        </div>
      )}
    </>
  );
};

export default PinCode;
