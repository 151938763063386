import React from 'react';

import { Button, Form, Radio, Row, Space, Typography } from 'antd';
import styles from '../../index.less';
import { MailOutlined, PhoneOutlined } from '@ant-design/icons';
interface IStepProps {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  dataStore?: any;
  onFinish: (option: string) => void;
  loading: boolean;
}
const Step1 = ({ dataStore, onFinish, loading }: IStepProps) => {
  const onSubmit = (val: any) => {
    onFinish(val?.option);
  };

  return (
    <div>
      <div className={styles.titleModal}>
        <Typography.Title level={5}>
          Lựa chọn hình thức xác minh
        </Typography.Title>
      </div>
      <Form onFinish={onSubmit}>
        <Form.Item
          name="option"
          rules={[
            {
              required: true,
              message: 'Vui lòng chọn hình thức xác minh',
            },
          ]}
        >
          <Radio.Group
            size="large"
            style={{ flexDirection: 'column', display: 'flex' }}
          >
            <Radio.Button
              value={'phone'}
              style={{
                margin: '10px 0',
              }}
              disabled={!dataStore?.phone}
            >
              <Space align="center">
                <PhoneOutlined />
                Qua SĐT
              </Space>
            </Radio.Button>
            <Radio.Button
              value={'email'}
              style={{
                margin: '10px 0',
              }}
              disabled={!dataStore?.email}
            >
              <Space align="center">
                <MailOutlined />
                Qua địa chỉ email
              </Space>
            </Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Row justify="center">
          <Button
            className="btn-submit"
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            Gửi yêu cầu
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default Step1;
