import { LeftOutlined } from '@ant-design/icons';
import { Breadcrumb, DatePicker, Form, Input, Table } from 'antd';
import React from 'react';
import { Link, useIntl, useParams } from 'umi';
import styles from '../index.less';
import { useAntdTable } from 'ahooks';
import { statisticUseEvents } from '../service';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { formatNumber } from '@/utils/apis/common';
const StatisticDiscountEvent = () => {
  const { formatMessage } = useIntl();
  const params = useParams<{ id: string }>();
  const [form] = Form.useForm();
  const { tableProps, search } = useAntdTable(statisticUseEvents, {
    defaultParams: [
      {
        current: 1,
        pageSize: 10,
        id: +params?.id,
      },
      {},
    ],
    form,
  });
  const columns: ColumnsType<any> = [
    {
      title: 'STT',
      dataIndex: 'stt',
    },
    {
      title: 'Thời gian',
      dataIndex: 'created_at',
      render: (value) => <>{moment(value).format('HH:mm:ss DD/MM/YYYY')}</>,
    },
    {
      title: 'Mã giao dịch',
      dataIndex: 'trading_code',
    },
    {
      title: 'ID',
      dataIndex: 'user_id',
    },
    {
      title: 'Khách hàng',
      dataIndex: ['profile', 'full_name'],
    },
    {
      title: 'Loại Voucher',
      dataIndex: 'voucher',
    },
    {
      title: 'Số lượng Voucher',
      dataIndex: 'quantity',
    },
    {
      title: 'Giá trị hoá đơn',
      dataIndex: 'value',
      render: (value) => <>{formatNumber(value)}đ</>,
    },
  ];

  const { submit } = search;
  const searchForm = (
    <div className={styles.searchContainer}>
      <Form form={form} className={styles.searchForm} layout="inline">
        <Form.Item name="search" className={styles.searchItem}>
          <Input.Search
            placeholder="Tìm kiếm theo tên khách hàng"
            allowClear
            onSearch={submit}
          />
        </Form.Item>

        <Form.Item name="toDate" className={styles.searchItem}>
          <DatePicker.RangePicker
            allowClear
            format="DD/MM/YYYY"
            onChange={submit}
          />
        </Form.Item>
      </Form>
    </div>
  );
  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item className={styles.breacrumbItem}>
          <Link to="/discount_event" className={styles.previousEditLink}>
            <LeftOutlined />
            <div>{formatMessage({ id: 'discount_event' })}</div>
          </Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      {searchForm}
      <div className={styles.tableComponent}>
        <Table
          columns={columns}
          {...tableProps}
          rowKey={(item) => item.id}
          scroll={{ x: 1000 }}
        />
      </div>
    </div>
  );
};

export default StatisticDiscountEvent;
