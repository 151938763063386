export default {
  // page.component.translation
  'index.hello': 'ZH - {name}',
  'index.title': 'ZH Title',
  'about.title': 'ZH - About - Title',
  // error
  error: 'Có lỗi xảy ra',
  'error.require': '{field} không được để trống.',
  'error.email': 'Email không đúng định dạng.',
  'error.patern': '{field} không đúng định dạng.',
  image_empty: 'Bắt buộc đính kèm ảnh',
  ok_text: 'Có',
  cancel_text: 'Không',
  should_delete: 'Bạn có muốn xoá không ?',
  //mesages

  STT: 'Stt',
  message_success: 'Thành công',
  message_send_success: 'Gửi thành công',
  message_kyc_success: 'Cập nhật trạng thái xác thực thành công',
  message_user_success: 'Cập nhật tài khoản thành công',
  message_add_user_success: 'Thêm tài khoản thành công',
  message_coupon_success: 'Cập nhật mã khuyến mãi thành công',
  message_add_coupon_success: 'Thêm mã khuyến mãi thành công',
  message_failure: 'Thất bại',
  message_kyc_failure: 'Cập nhật trạng thái xác thực thất bại',
  message_user_failure: 'Cập nhật tài khoản thất bại',
  message_add_user_failure: 'Cập nhật tài khoản thất bại',
  message_coupon_failure: 'Cập nhật mã khuyến mãi thất bại',
  message_add_coupon_failure: 'Thêm mã khuyến mãi thất bại',

  // Form

  password: 'Mật khẩu',
  user_or_phone_number: 'Tài khoản hoặc số điện thoại',
  phone_number: 'Số điện thoại',
  login: 'Đăng nhập',
  register: 'Đăng ký',
  fullname: 'Họ và tên',
  status: 'Trạng thái',
  status_verify: 'Trạng thái xác thực',
  address: 'Địa chỉ',
  email: 'Email',
  name: 'Tên',
  gift: 'Quà tặng',
  sale_time: 'Thời gian bán',
  time_sending: 'Thời gian gửi',
  identification_code: 'Mã định danh',
  date_of_birth: 'Ngày sinh',
  kyc_type: 'Loại giấy tờ',
  content: 'Nội dung',
  title: 'Tiêu đề',
  thumbnail: 'Ảnh thumbnail',
  image: 'Hình ảnh',
  promotion_code: 'Mã khuyến mãi',
  promotion_name: 'Tên chương trình',
  promotion_time: 'Thời gian chương trình',
  promotion_range: 'Phạm vi áp dụng',
  promotion_type: 'Hình thức khuyến mãi',
  promotion_send: 'Hình thức gửi',
  discount: 'Giảm giá',
  from_date: 'Từ ngày',
  to_date: 'Đến ngày',
  method_name: 'Tên phương thức',
  payment_type: 'Loại phương thức',
  description: 'Mô tả',
  display: 'Hiển thị',
  receiverAccount: 'Tài khoản nhận tiền của đối tác',
  referenceNumber: 'Mã tham chiếu của đối tác',
  user: 'Người dùng',
  receiver: 'Người nhận',
  topic: 'Chủ đề',

  vietnamese: 'Tiếng Việt',
  english: 'Tiếng Anh',
  account: 'Tài khoản',
  logout: 'Đăng xuất',
  auth_login: 'Locamos CMS',
  auth_phone: 'Số điện thoại',
  auth_password: 'Mật khẩu',
  status_active: 'Hoạt động',
  status_inactive: 'Không hoạt động',

  //navigation
  // navitation_home: 'Trang chủ',
  navigation_profile: 'Tài khoản của tôi',
  navigation_user: 'Người dùng',
  navigation_user_kyc: 'Xác thực người dùng',
  navigation_admin: 'Quản lý tài khoản admin',
  navigation_vihicle: 'Xe',
  navigation_lock: 'Khoá xe',
  navigation_travel: 'Chuyến đi',
  navigation_user_wallet: 'Ví người dùng',
  navigation_ticket: 'Vé thuê xe',
  navigation_method: 'Phương thức thanh toán',
  navigation_promotion: 'Mã khuyến mại',
  navigation_tutorial: 'Thông tin hưỡng dẫn sử dụng',
  navigation_news: 'Tin tức',
  navigation_rate: 'Đánh giá',
  navigation_notification_template: 'Danh mục thông báo',
  navigation_notification: 'Quản lý thông báo',
  navigation_list_gift: 'Danh sách nhận quà',
  navigation_gift: 'Quản lý quà',
  navigation_account: 'Tài khoản',
  //breadcrumb
  user_management: 'Quản lý người dùng',
  user_management_list_user: 'Quản lý người dùng',
  user_management_list_kyc: 'Xác thực người dùng',
  admin_management_detail_user: 'Chi tiết tài khoản admin',
  admin_new_account: 'Tạo tài khoản admin',
  admin_edit_account: 'Sửa tài khoản admin',
  notification_create_new: 'Tạo thông báo mới',
  notification_detail: 'Chi tiết thông báo',

  //general

  general_view_infomation: 'Xem thông tin',
  general_edit_infomation: 'Sửa thông tin',

  general_preview_image: 'Phóng to',
  general_tooltip_show_infomation: 'Xem thông tin',
  general_tooltip_delete: 'Xoá',
  general_tooltip_send_noti: 'Gửi thông báo',
  general_add_admin: 'Thêm tài khoản',
  general_export_excel: 'Xuất excel',

  general_add: 'Thêm mới',
  general_view: 'Xem',
  general_edit: 'Chỉnh sửa',
  general_save: 'Lưu',
  general_cancel: 'Huỷ',
  general_verify: 'Xác thực',
  general_gender: 'Giới tính',
  general_gender_male: 'Nam',
  general_gender_female: 'Nữ',
  general_gender_other: 'Khác',
  general_all: 'Tất cả',
  general_user: 'Người dùng',
  general_on: 'Bật',
  general_off: 'Tắt',
  general_noti_user: 'Theo người dùng',
  general_noti_topic: 'Theo chủ đề',

  general_payment_vtc: 'VTC_PAY',
  general_payment_domestic_bank: 'Ngân hàng nội địa',
  general_payment_international_card: 'Thẻ thanh toán quốc tế',

  general_status_active: 'Trạng thái hoạt động',
  general_inactive: 'Không hoạt động',
  general_active: 'Hoạt động',

  general_point_active: 'Trạng thái tặng điểm',
  general_point_success: 'Tặng điểm thành công',
  general_point_pending: 'Chờ tặng điểm',
  general_point_fail: 'Tặng điểm thất bại',

  general_status_account: 'Trạng thái xác thực',
  general_denied_verify: 'Từ chối xác thực',
  general_denied_kyc: 'Từ chối',
  general_accept_kyc: 'Phê duyệt',
  general_success_verify: 'Xác thực thành công',
  general_waiting_verify: 'Chờ xác thực',

  general_kyc_not_verified: 'Chưa xác thực',
  general_pending_kyc: 'Chờ xác thực',
  general_verified_kyc: 'Đã xác thực',
  general_cancel_kyc: 'Từ chối xác thực',

  general_kyc_type: 'Loại giấy tờ',
  general_kyc_type_IDcard: 'CCCD/CMND',
  general_kyc_type_passport: 'Hộ chiếu',
  general_kyc_type_driving_license: 'Bằng lái xe',

  general_kyc_photo_type: 'Loại hình ảnh',
  general_kyc_photo_type_front: 'Ảnh giấy tờ mặt trước',
  general_kyc_photo_type_back: 'Ảnh giấy tờ mặt sau',

  general_type_notification: 'Loại thông báo',

  notification_to_push: 'Bản thông báo đẩy',
  notification_via_sms: 'Thông báo qua SMS',
  notification_via_email: 'Thông báo qua Email',

  //form
  form_search_text: 'Nhập từ khoá',
  form_search_text_admin: 'Tìm kiếm theo tên,sđt,email',
  form_search_title: 'Tìm kiếm theo tiêu đề',

  //column table
  const_column_empty: 'Không có dữ liệu',
  const_column_full_name: 'Họ và tên',
  const_column_phone_number: 'Số điện thoại',
  const_column_email: 'Email',
  const_column_create_date: 'Ngày tạo',
  const_column_email_or_phone: 'Email/SĐT',
  const_column_date_of_birth: 'Ngày sinh',
  const_column_status: 'Trạng thái',
  const_column_action: 'Hành động',
  const_column_title: 'Tiêu đề',
  const_column_content: 'Nội dung',
  const_column_content_image: 'Nội dung và hình ảnh (nếu có)',
  const_column_description_image: 'Mô tả và hình ảnh (nếu có)',
  const_column_star: 'Sao',
  const_column_message: 'Phản hồi',
  const_column_item_name: 'Tên mảnh ghép',
};
