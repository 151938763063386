import { API_PATH, privateRequest, request } from '@/utils/apis';
import { IPagination } from '@/utils/constant';
import moment from 'moment';
export interface UpdateConfigInput {
  type: COREVOUCHER_TYPE;
  maxApplyPercent?: number;
  maxApplyValue?: number;
  maxUseCount?: number;
  minValueToApply?: number;
  limitUseDvoucher?: number;
}
export type COREVOUCHER_TYPE = 'DVOUCHER' | 'CVOUCHER';
export const ReleaseVoucherService = (
  { current, pageSize }: IPagination,
  formData: {
    searchText: string;
    toDate: string;
  },
) => {
  if (!formData.searchText) {
    formData.searchText = '';
  }
  const fromDate = Array.isArray(formData.toDate)
    ? moment(formData.toDate[0]).format('YYYY-MM-DD')
    : '';
  const toDate = Array.isArray(formData.toDate)
    ? moment(formData.toDate[1]).format('YYYY-MM-DD')
    : '';
  return privateRequest(request.get, API_PATH.VOUCHER_RELEASE, {
    params: {
      pageIndex: current,
      pageSize,
      startTime: fromDate,
      endTime: toDate,
      searchValue: formData.searchText,
    },
  }).then((res) => {
    return {
      list: res?.data?.map((item: any, i: number) => ({
        ...item,
        stt: i + 1 + (current - 1) * pageSize,
      })),
      total: res?.count,
    };
  });
};

export const StatisticVoucherService = (
  { current, pageSize }: { current: number; pageSize: number },
  formData: {
    searchText: string;
    toDate: string;
  },
) => {
  if (!formData.searchText) {
    formData.searchText = '';
  }
  const fromDate = Array.isArray(formData.toDate)
    ? moment(formData.toDate[0]).format('YYYY-MM-DD HH:00')
    : '';
  const toDate = Array.isArray(formData.toDate)
    ? moment(formData.toDate[1]).format('YYYY-MM-DD HH:00')
    : '';
  return privateRequest(request.get, API_PATH.VOUCHER_STATISTIC, {
    params: {
      pageIndex: current,
      pageSize,
      startTime: fromDate,
      endTime: toDate,
      searchValue: formData.searchText,
    },
  }).then((res) => {
    return {
      list: res?.data?.map((item: any, i: number) => ({
        ...item,
        stt: i + 1 + (current - 1) * pageSize,
      })),
      total: res?.count,
      data: {
        ...res,
      },
    };
  });
};

export const ConfigVoucher = () => {
  return privateRequest(request.get, API_PATH.VOUCHER_CONFIG);
};

export const UpdateConfigVoucher = (input: UpdateConfigInput) => {
  return privateRequest(request.patch, API_PATH.UPDATE_CONFIG, {
    data: input,
  });
};

export const StatisticAliasService = (
  { current, pageSize }: IPagination,
  formData: {
    search?: string;
    date?: string[];
  },
) => {
  const params: {
    page_index: number;
    page_size: number;
    start_time?: string;
    end_time?: string;
    search_value?: string;
  } = {
    page_index: current,
    page_size: pageSize,
  };
  if (!!formData.search) {
    params.search_value = formData.search;
  }
  if (Array.isArray(formData.date)) {
    params.start_time = moment(formData.date[0]).format('YYYY-MM-DD');
    params.end_time = moment(formData.date[1]).format('YYYY-MM-DD');
  }
  return privateRequest(request.get, API_PATH.ALIAS_STATISTIC, { params }).then(
    (response) => {
      return {
        list: response.data?.map((item: any, index: number) => ({
          ...item,
          stt: (current - 1) * pageSize + index + 1,
        })),
        total: response.total,
      };
    },
  );
};
