import React, { useEffect, useState } from 'react';
import {
  Button,
  Drawer,
  Form,
  Input,
  Row,
  message,
  Table,
  Tooltip,
  Checkbox,
  UploadFile,
} from 'antd';
import styles from '../index.less';
import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import { useRequest } from 'umi';
import {
  addProductProperty,
  deleteProductProperty,
  editProductProperty,
} from '../service';
const DrawerPropertyProduct = ({
  open,
  setOpen,
  property,
  checkedKeys,
  setCheckedKeys,
  dataSource,
  setDataSource,
}: {
  dataSource: any[];
  setDataSource: React.Dispatch<
    React.SetStateAction<
      {
        key: number;
        main_prop_name: string;
        image_link: UploadFile[];
        status: boolean;
        sub_prop: {
          sub_prop_name: string;
          price: number;
          sku_code: string;
        }[];
      }[]
    >
  >;
  checkedKeys: number[];
  setCheckedKeys: any;
  open: boolean;
  setOpen: any;
  property: any;
}) => {
  const [isEditProperty, setIsEditProperty] = useState(false);
  const [isAddProperty, setIsAddProperty] = useState(false);
  const [idEditProperty, setIdEditProperty] = useState(0);
  const [checkedKeysProps, setCheckedKeysProps] = useState<any[]>([]);
  const [form] = Form.useForm();
  useEffect(() => {
    setCheckedKeysProps(checkedKeys);
  }, [checkedKeys]);
  const handleCheckboxChange = (key: any) => {
    setCheckedKeysProps((prev: any) => {
      if (prev.includes(key)) {
        return prev.filter((item: any) => item !== key);
      } else if (prev.length < 2) {
        return [...prev, key];
      }
      return prev;
    });
  };
  const columns: ColumnsType<any> = [
    {
      width: '5%',
    },
    {
      dataIndex: 'name',
    },
    {
      width: '200px',
      dataIndex: 'value',
      render: (value, record) => (
        <>
          <Row justify="space-around">
            <Tooltip title="Chỉnh sửa">
              <EditOutlined
                className="primary-color cursor-pointer"
                onClick={() => {
                  setIsEditProperty(true);
                  setIsAddProperty(false);
                  form.setFieldValue('name', record?.name);
                  setIdEditProperty(record?.id);
                }}
              />
            </Tooltip>
            <Tooltip title="Xoá">
              <DeleteOutlined
                className="red-color cursor-pointer"
                onClick={() => deleteProperty.run(record?.id)}
              />
            </Tooltip>
            <Tooltip title="Chọn">
              <Checkbox
                checked={checkedKeysProps.includes(record.id)}
                onChange={() => handleCheckboxChange(record.id)}
                disabled={
                  !checkedKeysProps.includes(record.id) &&
                  checkedKeysProps.length >= 2
                }
              />
            </Tooltip>
          </Row>
        </>
      ),
    },
  ];
  const closeAddProperty = () => {
    form.resetFields();
    setIsAddProperty(false);
  };
  const closeEditProperty = () => {
    form.resetFields();
    setIsEditProperty(false);
  };
  const onClose = () => {
    setCheckedKeysProps(checkedKeys);
    setOpen(false);
  };
  const addProperty = useRequest(addProductProperty, {
    manual: true,
    onError: (err) => {
      console.log(err);
      closeAddProperty();
    },
    onSuccess: (res) => {
      message.success('Thêm nhóm thành công');
      closeAddProperty();
      property.refresh();
    },
  });
  const editProperty = useRequest(editProductProperty, {
    manual: true,
    onError: (err) => {
      console.log(err);
      closeEditProperty();
    },
    onSuccess: (res) => {
      message.success('Sửa nhóm thành công');
      closeEditProperty();
      property.refresh();
    },
  });
  const deleteProperty = useRequest(deleteProductProperty, {
    manual: true,
    onError: (err) => {
      console.log(err);
    },
    onSuccess: (res) => {
      message.success('Xoá nhóm thành công');
      property.refresh();
    },
  });
  const onEditCategory = (val: any) => {
    console.log(val);
    editProperty.run(idEditProperty, val?.name);
  };
  const onAddCategory = (val: any) => {
    console.log(val);
    addProperty.run(val?.name);
  };
  const onSubmitProperty = () => {
    setCheckedKeys(checkedKeysProps);
    setDataSource([
      {
        key: 1,
        main_prop_name: '',
        image_link: [],
        status: true,
        sub_prop: [
          {
            sub_prop_name: '',
            price: 0,
            sku_code: '',
          },
        ],
      },
    ]);
    onClose();
  };
  return (
    <Drawer
      className="drawer-product"
      title={
        <div className={styles.drawerHeader}>
          <div className={styles.drawerTitle}>Nhóm lựa chọn</div>
          <div className={styles.drawerAction}>
            <Row justify="space-between">
              <Button type="primary" ghost onClick={onSubmitProperty}>
                Lưu
              </Button>
              <Button
                type="primary"
                ghost
                icon={<PlusOutlined />}
                onClick={() => {
                  setIsEditProperty(false);
                  setIsAddProperty(true);
                  form.resetFields();
                }}
              >
                Thêm mới
              </Button>
            </Row>
          </div>
          <CloseOutlined onClick={onClose} />
        </div>
      }
      placement={'bottom'}
      closable={false}
      onClose={onClose}
      open={open}
      key={'drawer'}
    >
      {isEditProperty && !isAddProperty && (
        <Form
          form={form}
          onFinish={onEditCategory}
          style={{
            marginTop: 50,
            padding: 30,
          }}
        >
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập',
              },
            ]}
          >
            <Input placeholder="Nhập tên nhóm lựa chọn" />
          </Form.Item>
          <Row
            justify="end"
            style={{
              marginTop: 30,
            }}
          >
            <Button
              type="primary"
              className="btn-submit"
              onClick={() => {
                form.submit();
              }}
            >
              Lưu
            </Button>
            <Button
              type="primary"
              danger
              className="btn-submit"
              ghost
              onClick={() => {
                setIsEditProperty(false);
                form.resetFields();
              }}
            >
              Hủy
            </Button>
          </Row>
        </Form>
      )}
      {isAddProperty && !isEditProperty && (
        <Form
          form={form}
          onFinish={onAddCategory}
          style={{
            marginTop: 50,
            padding: 30,
          }}
        >
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập',
              },
            ]}
          >
            <Input placeholder="Nhập tên nhóm lựa chọn" />
          </Form.Item>
          <Row
            justify="end"
            style={{
              marginTop: 30,
            }}
          >
            <Button
              type="primary"
              className="btn-submit"
              onClick={() => {
                form.submit();
              }}
            >
              Thêm
            </Button>
            <Button
              type="primary"
              danger
              className="btn-submit"
              ghost
              onClick={() => {
                setIsAddProperty(false);
                form.resetFields();
              }}
            >
              Hủy
            </Button>
          </Row>
        </Form>
      )}
      {!isAddProperty && !isEditProperty && (
        <Table
          showHeader={false}
          columns={columns}
          rowKey={(item) => item.id}
          dataSource={property?.data}
          loading={property.loading}
          pagination={false}
        />
      )}
    </Drawer>
  );
};

export default DrawerPropertyProduct;
