import { useIntl, useLocation } from 'umi';

const AuthHeader = () => {
  const location = useLocation();
  const { formatMessage } = useIntl();

  return <></>;
};

export default AuthHeader;
