import React, { useState } from 'react';
import { Breadcrumb, Button, Form, Select, Table, DatePicker, Tag } from 'antd';
import styles from './index.less';
import { useHistory, useIntl } from 'umi';
import { ColumnsType } from 'antd/lib/table';
import { PACKAGE_BUY_TYPE, getBuyPackageHistory } from './service';
import { useAntdTable } from 'ahooks';
import { formatNumber, numberWithDots } from '@/utils/apis/common';
const { RangePicker } = DatePicker;
const formatDate = 'DD/MM/YYYY';
type DataType = {
  stt: number;
  purchase_at: string;
  purchase_type: string;
  package: string;
  total: number;
};
const Package = () => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const [turn, setTurn] = useState(0);
  const [totalLcb, setTotalLcb] = useState(0);
  const columns: ColumnsType<DataType> = [
    {
      title: 'STT',
      dataIndex: 'stt',
    },
    {
      title: 'const_column_pickup_time',
      dataIndex: 'purchase_at',
      align: 'right',
    },
    {
      title: 'const_column_package_type',
      dataIndex: 'package',
      render: (_: any, rc: DataType) => <>{rc?.package + ' / lượt'}</>,
    },
    {
      title: 'const_column_count',
      align: 'right',
      dataIndex: 'turn',
    },
    {
      title: 'const_column_payment_method',
      dataIndex: 'purchase_type',
    },
    {
      title: 'const_column_total_price',
      align: 'right',
      dataIndex: 'total',
      render: (_: any, record: DataType) => (
        <>
          {numberWithDots(record?.total) +
            `${record.purchase_type === 'LOCABONUS' ? 'LCB' : 'đ'}`}
        </>
      ),
    },
    {
      title: 'const_column_status',
      render: () => <Tag color="green">Thành công</Tag>,
    },
  ].map((item) => ({
    ...item,
    title: formatMessage({ id: item.title }),
  }));

  const [form] = Form.useForm();

  const { tableProps, search, data } = useAntdTable(getBuyPackageHistory, {
    form,
    onSuccess: (res) => {
      console.log(res);
      setTurn(res?.turn_remaining);
      setTotalLcb(res?.balance);
    },
  });

  console.log(data);

  const { submit } = search;

  const searchFrom = (
    <div className={styles.searchContainer}>
      <Button onClick={() => history.push('/package/buy_package')}>
        Mua lượt tặng
      </Button>
      <Form form={form} className={styles.searchForm} layout="inline">
        <Form.Item
          name="toDate"
          // initialValue={[null, null]}
          className={styles.searchItem}
        >
          {/* @ts-ignore */}
          <RangePicker
            onChange={submit}
            format={formatDate}
            picker="date"
            className="w-100"
            placeholder={['Từ ngày', 'Đến ngày']}
          />
        </Form.Item>
        <Form.Item name="buy_type" className={styles.searchItem}>
          <Select
            onChange={submit}
            allowClear
            placeholder={formatMessage({ id: 'navigation_method' })}
            options={[
              {
                label: 'Locabonus',
                value: PACKAGE_BUY_TYPE.LOCABONUS,
              },
              {
                label: 'VNPay',
                value: PACKAGE_BUY_TYPE.VNPAY,
              },
            ]}
          ></Select>
        </Form.Item>
      </Form>
      <div>
        Locabonus của DN:{' '}
        <b className="primary-color">{formatNumber(Number(totalLcb))}</b>
      </div>
      <div>
        {`Gói ${data?.main_package}:`}{' '}
        <b className="primary-color">{`${formatNumber(turn) || 0} `}</b> lượt
        tặng còn lại
      </div>
    </div>
  );
  return (
    <div>
      <Breadcrumb className={styles.breadcrumb}>
        <Breadcrumb.Item>
          {formatMessage({ id: 'navigation_package' })}
        </Breadcrumb.Item>
      </Breadcrumb>
      {searchFrom}
      <div className={styles.tableComponent}>
        <Table columns={columns} {...tableProps} scroll={{ x: 1000 }} />
      </div>
    </div>
  );
};

export default Package;
