import {
  Button,
  Col,
  Form,
  Input,
  message,
  Row,
  Statistic,
  Typography,
} from 'antd';
import React, { useEffect, useState } from 'react';

import { auth } from '@/utils/firebaseConfig';
import { useTranslate } from '@/utils/hooks/useTranslate';
import { useRequest } from 'ahooks';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import styles from '../index.less';
import { checkLogin } from '../service';
import { useHistory } from 'umi';
import OtpInput from 'react18-input-otp';

const { Countdown } = Statistic;
type Props = {
  onFinishStep2: (value: any) => void;
  resendOTP: () => void;
  currentIdent: any;
  option: 'email' | 'phone';
  loading: boolean;
  isVNDialcode: boolean;
};

const Step2 = (props: Props) => {
  const history = useHistory();
  const { t } = useTranslate();
  const [otp, setOtp] = useState('');
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(30);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const resendOTP = () => {
    setSeconds(30);
    setMinutes(1);
    props.resendOTP();
  };

  return (
    <div className={styles.loginWrap}>
      <div id="recaptcha-container"></div>
      <h1>Xác thực OTP</h1>
      <p style={{ marginBottom: 24 }}>
        {`Nhập mã xác thực được gửi tới ${
          props.option === 'phone' ? 'số điện thoại' : 'email'
        } ${props.currentIdent}`}
      </p>
      <Form onFinish={props.onFinishStep2} layout="vertical">
        <Form.Item
          name="otp"
          className={styles.otpInput}
          rules={[
            {
              required: true,
              message: t('error.require', {
                field: t('OTP'),
              }),
            },
            {
              pattern: /[0-9]/,
              message: t('error', {
                field: 'OTP phải là số',
              }),
            },
          ]}
        >
          {/* @ts-ignore */}
          <OtpInput
            value={otp}
            onChange={(val: any) => setOtp(val)}
            numInputs={props.isVNDialcode ? 4 : 6}
            separator={<span>-</span>}
            className={styles.otp}
            isInputNum={true}
          />
        </Form.Item>
        <div className={styles.otpContainer}>
          {seconds > 0 || minutes > 0 ? (
            <p>
              Gửi lại OTP: {minutes < 10 ? `0${minutes}` : minutes}:
              {seconds < 10 ? `0${seconds}` : seconds}
            </p>
          ) : (
            <p>Bạn không nhận được OTP?</p>
          )}
          {!(seconds > 0 || minutes > 0) && (
            <div className={styles.resendOTP} onClick={resendOTP}>
              Gửi lại OTP
            </div>
          )}
        </div>
        <Row justify="center">
          <Button
            type="primary"
            disabled={props.isVNDialcode ? otp.length !== 4 : otp.length !== 6}
            htmlType="submit"
            loading={props.loading}
            className={styles.btnSubmit}
          >
            Đăng ký
          </Button>
        </Row>
      </Form>
      <div className={styles.register}>
        <h2>Đã có tài khoản?</h2>
        <div
          className={styles.links}
          onClick={() => {
            history.push('/login');
          }}
        >
          <span className="primary-color">Đăng Nhập</span>
        </div>
      </div>
    </div>
  );
};

export default Step2;
