import { formatTime } from './../../utils/formatTime';
import { privateRequest, request, API_PATH } from '@/utils/apis';
import { ENVIRONMENTS } from '@/utils/constant';
import moment from 'moment';

interface Result {
  total: number;
  list: any[];
  totalLB: number;
}

export const getTableData = (
  { current, pageSize }: { current: number; pageSize: number },
  formData: {
    toDate: string[];
    fullName: string;
  },
): Promise<Result> => {
  if (formData.fullName === undefined) {
    formData.fullName = '';
  }
  const fromDate = Array.isArray(formData.toDate)
    ? moment(formData.toDate[0]).toISOString()
    : '';
  console.log('🚀 ~ file: service.ts:22 ~ fromDate', fromDate);

  const toDate = Array.isArray(formData.toDate)
    ? moment(formData.toDate[1]).toISOString()
    : '';

  // `page=${current}&pageSize=${pageSize}`

  const data = {
    query: `
    mutation {
      get_locabonus_receive_groups_by_day(input: { page_size: ${
        pageSize || 10
      }, page_index: ${
      current ? current - 1 : 0
    },end_time:"${toDate}",start_time:"${fromDate}"  }) {
        total
        totalLB
        data {
          created_at_string
          nums_of_given
          locabonus_receive
        }
      }
    }
    `,
  };

  return privateRequest(request.post, API_PATH.default, { data }).then(
    (res: any) => {
      console.log(res);

      const result = res?.data?.get_locabonus_receive_groups_by_day.data.map(
        (e: any, index: any) => ({
          ...e,
          stt: (current ? current - 1 : 0) * pageSize + (index + 1),
        }),
      );
      return {
        total: res?.data?.get_locabonus_receive_groups_by_day.total,
        list: result,
        totalLB: res?.data?.get_locabonus_receive_groups_by_day?.totalLB,
      };
    },
  );
};

// export const exportFileStatistic = (formData: { toDate: string[] }) => {
//   const fromDate = Array.isArray(formData.toDate)
//     ? moment(formData.toDate[0]).toISOString()
//     : '';

//   const toDate = Array.isArray(formData.toDate)
//     ? moment(formData.toDate[1]).toISOString()
//     : '';

//   const params = {
//     start_time: fromDate,
//     end_time: toDate,
//   };
//   const localStorageInfo: any = window.localStorage.getItem(
//     ENVIRONMENTS.LOCAL_STORAGE_KEY as string,
//   );
//   const token = JSON.parse(localStorageInfo)?.token;
//   return privateRequest(request.get, API_PATH.EXPORT_STATISTIC, {
//     params: {
//       ...params,
//       token,
//     },
//   });
// };
